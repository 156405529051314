import React, { useContext, useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { ModalBody } from 'react-bootstrap';
import { LoginContext } from '../../context/loginContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { getImage } from '../personalDetails/personalDetailsService';
import UserIcon from '../../assets/images/user-icon01.png';
import heic2any from 'heic2any';
import { shareGallery, sharedSuggestions } from './suggestionService';
import Spinner from '../../components/spinner';

function ShareMyGallery(props) {
    const { close, show, base64Image, relationshipId } = props;
    const [galleryData, setGalleryData] = useState([]);
    const [isGalleryOpen, setIsGalleryOpen] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [isGalleryLoading, setIsGalleryLoading] = useState(false);
    const [selectedGalleries, setSelectedGalleries] = useState({});

    const { t, i18n } = useTranslation();
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    const token_lang = { language: i18n.language, accessToken };


    useEffect(() => {
        const data = {
            accessToken: accessToken,
            language: i18n.language
        }
        shareGallery(data, relationshipId).then((response) => {
            if (response?.status === 200) {
                setGalleryData(response?.data.galleries);
                const initialSelected = {};
                response?.data.galleries.forEach(gallery => {
                    initialSelected[gallery.id] = gallery.sharedWithThisUser;
                });
                setSelectedGalleries(initialSelected);
            }
        })
    }, [])

    const onGalleryClick = (gallery) => {
        setGalleryImages([])
        setIsGalleryOpen(true);
        const images = gallery?.mediaDTOS;
        if (images.length) {
            images.forEach(async (image) => {
                try {
                    const imageUrl = await onGetImage(image.id);
                    setGalleryImages((prevImages) => [
                        ...prevImages,
                        { image, imageUrl },
                    ]);
                } catch (error) {
                    console.error(`Error fetching image for ID ${image.id}:`, error);
                }
            });
        } else {
            setIsGalleryOpen(false);
    }
    };

    const onGetImage = (imageId) => {
        return new Promise((resolve, reject) => {
            const data = {
                accessToken: accessToken,
                language: i18n.language,
                id: imageId
            };

            getImage(data).then(async (response) => {
                if (response.status === 200) {
                    const blob = await response.blob();
                    let fileURL;
                    if (blob.type === 'image/heif') {
                        const jpegBlob = await heic2any({
                            blob: blob,
                            toType: 'image/jpeg',
                            quality: 1
                        });
                        fileURL = URL.createObjectURL(jpegBlob);
                    } else {
                        fileURL = URL.createObjectURL(blob);
                    }
                    resolve(fileURL);
                } else {
                    reject(new Error("Failed to get image."));
                }
            }).catch(error => {
                reject(error);
            });
        });
    };

    const onBack = () => {
        setIsGalleryOpen(false);
    }

    const selectGallery = (galleryId) => {
        sharedSuggestions(token_lang, galleryId, relationshipId).then((result) => {
            if (result?.status === 200) {
                setSelectedGalleries((prevSelectedGalleries) => ({
                    ...prevSelectedGalleries,
                    [galleryId]: !prevSelectedGalleries[galleryId],
                }));
            }
        })
    };

    return (
        <Modal className='openprofile' show={show} size='lg' onHide={() => close()}>
            <Modal.Header closeButton>
                <Modal.Title>{t('suggestions.sharedwithowngallery')}</Modal.Title>
            </Modal.Header>
            <ModalBody className='s_share_gallery'>
                {!isGalleryOpen ? <>
                    {galleryData?.length ?
                        <>
                            {galleryData?.map((gallery) => (
                                gallery.mediaDTOS ?
                                    <div key={gallery?.id}>
                                        <div className='s_image_select'>
                                            <Form.Group>
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={selectedGalleries[gallery.id]}
                                                    onChange={() => selectGallery(gallery.id)}
                                                    className='msg_pre_checkbox'
                                                />
                                            </Form.Group>
                                        </div>
                                        <div
                                            onClick={() => onGalleryClick(gallery)}
                                            className='s_gallery_container'>
                                            <img className='' src={gallery?.thumbnails?.[0] ? base64Image(gallery?.thumbnails?.[0]) : UserIcon} />
                                        </div>
                                        <div className='g_name_container'>
                                            <span className='galleryname'>{gallery?.name.length > 20 ? gallery?.name.substring(0, 20) : gallery?.name}
                                            </span>
                                        </div>
                                    </div>
                                    : null
                            ))}
                        </> : 
                        <div className='openprofile_spinner'>
                            <Spinner/>
                        </div>
                    }</>
                    :
                    <>{galleryImages?.length ?
                        <div className='image-wrapper'>
                            <div className='images_container'>
                                {galleryImages?.map((image) => (
                                    <div key={image.image.id} id={image.image.id} className='g_container'>
                                        <div className='gallery_images'>
                                            <img
                                                className='image'
                                                src={image.imageUrl}
                                                alt={image.image.name}
                                            />

                                        </div>
                                        <div className='g_name_container'>
                                            <span className='galleryname'>
                                                {image.image.name.length > 40 ? `${image.image.name.slice(0, 40)}...` : image.image.name}
                                            </span>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            <div>
                            </div>
                            <Form.Group className='list_btn' >
                                <div>
                                    <Button
                                        onClick={() => onBack()}
                                        className='mb-3 mt-1'
                                        variant="outline-danger"
                                    >
                                        <FontAwesomeIcon className='left_arrow' icon={faArrowLeft} />
                                        {t('suggestions.back')}
                                    </Button>
                                </div>
                            </Form.Group>
                        </div> :  
                        <div className='openprofile_spinner'>
                            <Spinner />
                        </div>
                    }
                    </>
                }
            </ModalBody>
        </Modal>
    )
}

export default ShareMyGallery;
