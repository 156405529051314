import { useTranslation } from "react-i18next";
import { useContext, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getMemberDetails } from "../login/loginService";
import { getCurrentURL } from '../registrationProcess/registrationProcessService'
import { LoginContext } from '../../context/loginContext';
import { Button } from "react-bootstrap";
import Alert from 'react-bootstrap/Alert';
import { resetUser, reuseUser } from "./cancellationService";
import GlkHeader from '../../components/Header/GlkHeader';
import './cancellation.css'


function Cancellation() {

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    let {
        loginData: {
            accessToken
        },
        dispatch
    } = useContext(LoginContext);
    const navigate = useNavigate();
    const [showErrorAlert, setShowErrorAlert] = useState(false);

    const reuseUserData = () => {
        reuseUser(accessToken, currentLang).then((response) => {
            if (response?.status === 200) {
                navigateToRegistration();
                return;
            } else {
                setShowErrorAlert(true)
            }
        })
    }

    const resetUserData = () => {
        resetUser(accessToken, currentLang).then((response) => {
            if (response?.status === 200) {
                navigateToRegistration();
                return;
            } else {
                setShowErrorAlert(true)
            }

        })
    }

    const navigateToRegistration = () => {
        getMemberDetails(accessToken).then((memberResponse) => {
            if (memberResponse?.status === 200) {
                dispatch({ type: 'MEMBER_DETAILS', data: memberResponse.data });
                getCurrentURL(accessToken).then((res) => {
                    dispatch({ type: 'CURRENT_URL', data: res.data });
                    navigate('/registration');
                })
                return;
            } else {
                setShowErrorAlert(true)
            }
        })
    }

    return (
        <div className='login_container'>
            <GlkHeader />
            <div className="main_wrapper">
                <div className="cancellation_box">
                    <div className="login_main_container">
                        <div>
                            <h2>{t('cancellation.header')}</h2>
                            <Button className="cancellation_btn" onClick={reuseUserData}>
                                {t('cancellation.reuseUser')}
                            </Button>
                            <Button className="cancellation_btn" onClick={resetUserData}>
                                {t('cancellation.resetUser')}
                            </Button>
                        </div>
                        <Alert className='alert_questionaire' show={showErrorAlert} dismissible onClose={() => setShowErrorAlert(false)}
                            variant="danger" id={`err_1`}>{t('cancellation.error')}</Alert>
                    </div>
                </div>
                <div className="legal">
                    <a className="legal_link" target="_blank" href="https://www.gleichklang.de/impressum/" aria-label="{t('member.footer.imprint')}">{t('member.footer.imprint')}</a> | <a className="legal_link" target="_blank" href="https://www.gleichklang.de/datenschutz/" aria-label="{t('member.login.dataProtection')}">{t('member.footer.dataProtection')}</a> | <a className="legal_link" target="_blank" href="https://www.gleichklang.de/agb/" aria-label="{t('member.footer.terms')}">{t('member.footer.terms')}</a>
                </div>
            </div>
        </div>
    )
}
export default Cancellation;
