import './GlkFooter.css';
import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneVolume,
  faSquarePhone,
  faEnvelope,
  faEarthEurope,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from "react-i18next";
import { LoginContext } from '../../context/loginContext';
import Spinner from '../spinner';

function GlkFooter() {
  const { t, i18n } = useTranslation();
  const languageCode = i18n.language;
  let { loginData: {
    memberDetails,
    }
  } = useContext(LoginContext)
	
  const handleChangeLanguage = (value) => {
    i18n.changeLanguage(value);
  }

  return (
  <>
    {memberDetails ?
      <footer className='app_footer'>
      <div className='footer_details'>
        <a className='text_white imprint_label' href="https://www.gleichklang.de/impressum/">{t("member.footer.imprint")}</a>
        <p className='text_white'>  Gleichklang limited   Oesterleystr. 1   D-30171 Hannover</p>
        <div className="text_white glk_contact_details_container">
          <div className="contact_number_free">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faPhoneVolume} />
              </div>
              <a className="text_white" href="tel: +49 (0)511 51516660" aria-label="call on 49 (0)511 51516660">+49 (0)511 51516660</a>
            </div>
            <span className="for_font_size">{t('member.footer.contactTime')}</span>
          </div>
          <div className="contact_number_paid">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faSquarePhone} />
              </div>
              <a className="text_white" href="tel: +49 (0) 1805 - 7772 3332" aria-label="Call on +49 (0) 1805 - 7772 3332"> +49 (0) 1805 - 7772 3332 </a>
            </div>
            <span className="for_font_size">(0.14 €/min)</span>
          </div>
          <div className="contact_email">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faEnvelope} />
              </div>
              <a className="text_white" href="mailto:anfragen@gleichklang.de" aria-label="mail to anfragen@gleichklang.de">anfragen@gleichklang.de</a>
            </div>
          </div>
          <div className="contact_website">
            <div className='contact_numberAndicon'>
              <div className='for_icon_circle'>
                <FontAwesomeIcon icon={faEarthEurope} />
              </div>
              <a className="text_white" href="https://www.gleichklang.de">www.gleichklang.de</a>
            </div>
          </div>
        </div>
      </div>
			<div className="footer_select_lng">
				<div className='select_lag'>
          <select
            value={languageCode} 
            aria-label={t('member.footer.selectLang')} 
            id="language1"
            onChange={(e) => handleChangeLanguage(e.target.value)}
          >
            <option value='en'>English</option>
            <option value='de'>Deutsch</option>
          </select>
        </div>
			</div>
    </footer> : 
    <div className='glk_loader'>
      <Spinner/>
    </div>
    }
    </>
  )
}

export default GlkFooter