import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import GlkHeader from '../../components/Header/GlkHeader';
import membershipIcon from '../../assets/images/membership.png';
import Banner from '../../components/banner/banner';
import Pagination from 'react-bootstrap/Pagination';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import './messageToGK.css'
import React, { useEffect, useContext, useState } from 'react';
import { getMessages } from './membershipServices'
import { LoginContext } from '../../context/loginContext';
import Spinner from '../../components/spinner';
import WriteMessageToGleichklang from './writeMessageToGleichklang';
import {
  faEnvelope,
  faArrowLeft,
  faPaperclip,
  faArrowRight,
  faEnvelopeOpen
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import SupportConversation from './supportConversation';

function MessageToGK() {
  const { t, i18n } = useTranslation();
  const [page, setPage] = useState({
    pageNo: 1,
    pageSize: 20
  })
  const [totalPage, setTotalPage] = useState()
  const [filters, setFilters] = useState({
    attachment: null,
    unread: null,
    subject: null
  })
  const [messageType, setMessageType] = useState("INCOMING")
  const [messages, setMessages] = useState(null)
  
  const [showWriteMessagePopup, setShowWriteMessagePopup] = useState(false);
  const [reportedMessages, setReportedMessages] = useState({messages: '', isOpen: false});

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const token_lang = {
    language: i18n.language,
    accessToken
  }

  const data = {
    "image": membershipIcon,
    "menuName": t('mymembership.mymembership'),
    "header": t('mymembership.msgtoGk.msgToGk'),
    "description": t('mymembership.msgtoGk.description'),
    "color": "#063978"
  }

  useEffect(() => {
    fetchMessages(page.pageNo, page.pageSize)
  }, [page, filters, messageType])

  const fetchMessages = (pageNo, pageSize) => {
    let filterString = ""
    if (filters.attachment) {
      filterString = filterString + '&attachment=true'
    }
    if (filters.unread) {
      filterString = filterString + '&unread=true'
    }
    if (filters.subject && filters.subject.trim().length > 1) {
      filterString = filterString + `&search=${filters.subject.trim()}`
    }

    const payload = {
      pageNo: pageNo,
      pageSize: pageSize,
      filterString: filterString,
      messageType: messageType
    }

    getMessages(token_lang, payload).then((response) => {
      if (response.status === 200) {
        setMessages(response.data)
        const totalPages = Math.floor(response.data.totalMessages / pageSize)
        const remainder = response.data.totalMessages % pageSize;
        if (remainder !== 0) {
          setTotalPage(totalPages + 1);
        } else {
          setTotalPage(totalPages)
        }

      } else {
        console.log("show error msg")
      }
    })
  }

  const handleMessageTypeChange = (value) => {
    setPage({
      pageNo: 1,
      pageSize: 20
    })
    setFilters({ attachment: null, unread: null, subject: null })
    setMessageType(value)
  }

  const updateFilters = (event, eventType) => {
    switch (eventType) {
      case "subject":
        setFilters(prevFilters => ({
          ...prevFilters,
          subject: event.target.value.trim()
        }));
        break;

      case "attachment":
        setFilters(prevFilters => ({
          ...prevFilters,
          attachment: event.target.checked ? true : null
        }));
        break;

      case "unread":
        setFilters(prevFilters => ({
          ...prevFilters,
          unread: event.target.checked ? true : null
        }));
        break;

    }
  }

  const updatePageSize = (value) => {
    setPage(prevPage => ({
      ...prevPage, pageSize: value, pageNo: 1
    }));
  }
  const updatePageNo = (value) => {
    setPage(prevPage => ({
      ...prevPage, pageNo: value
    }));
  }

  const renderPaginationItems = () => {
    const items = [];
    const { pageNo, pageSize } = page;

    items.push(
      <Pagination.Prev
        onClick={() => updatePageNo(Math.max(pageNo - 1, 1))}
        key="prev"
        disabled={pageNo === 1}
      />
    );

    if (totalPage <= 7) {
      for (let i = 1; i <= totalPage; i++) {
        items.push(
          <Pagination.Item
            onClick={() => updatePageNo(i)}
            key={i}
            active={i === pageNo}
          >
            {i}
          </Pagination.Item>
        );
      }
    } else {
      items.push(
        <Pagination.Item
          onClick={() => updatePageNo(1)}
          key={1}
          active={1 === pageNo}
        >
          1
        </Pagination.Item>
      );
      items.push(
        <Pagination.Item
          onClick={() => updatePageNo(2)}
          key={2}
          active={2 === pageNo}
        >
          2
        </Pagination.Item>
      );

      if (pageNo > 4) {
        items.push(<Pagination.Ellipsis key="ellipsis-start" />);
      }

      const startPage = Math.max(3, pageNo - 1);
      const endPage = Math.min(totalPage - 2, pageNo + 1);

      for (let i = startPage; i <= endPage; i++) {
        items.push(
          <Pagination.Item
            onClick={() => updatePageNo(i)}
            key={i}
            active={i === pageNo}
          >
            {i}
          </Pagination.Item>
        );
      }

      if (pageNo < totalPage - 3) {
        items.push(<Pagination.Ellipsis key="ellipsis-end" />);
      }

      items.push(
        <Pagination.Item
          onClick={() => updatePageNo(totalPage - 1)}
          key={totalPage - 1}
          active={totalPage - 1 === pageNo}
        >
          {totalPage - 1}
        </Pagination.Item>
      );
      items.push(
        <Pagination.Item
          onClick={() => updatePageNo(totalPage, pageSize)}
          key={totalPage}
          active={totalPage === pageNo}
        >
          {totalPage}
        </Pagination.Item>
      );
    }

    items.push(
      <Pagination.Next
        onClick={() => updatePageNo(Math.min(pageNo + 1, totalPage), pageSize)}
        key="next"
        disabled={pageNo === totalPage}
      />
    );

    return items;
  };

  const openWriteMessagePopup = () => {
    setShowWriteMessagePopup(true)
  }
  const closeWriteMessagePopup = () => {
    setShowWriteMessagePopup(false)
    fetchMessages(page.pageNo, page.pageSize)
  }

  const openRepoetedMessage = (messages) => {    
    setReportedMessages({messages: messages , isOpen: true});   
  }

  const closeMessages = () => {
    setReportedMessages({messages: '' , isOpen: false});  
  }

  return (
    <div>
      <GlkHeader />
      <main className="main_wrapper membership_wrapper">
        <Banner data={data} />
        <div className='msg2gk_container'>
          <Row className="mb-3">
            <Form.Group as={Col} controlId="formGridState">
              <Form.Label>{t('mymembership.msgtoGk.msgtype')}</Form.Label>
              <Form.Select onChange={(e) => handleMessageTypeChange(e.target.value)}
                defaultValue={t('mymembership.msgtoGk.in')}>
                <option key={"INCOMING"} value={"INCOMING"}>{t('mymembership.msgtoGk.in')}</option>
                <option key={"OUTGOING"} value={"OUTGOING"}>{t('mymembership.msgtoGk.out')}</option>
              </Form.Select>
            </Form.Group>
            <Form.Group as={Col} controlId="formGridCity">
              <Form.Label>{t('mymembership.msgtoGk.subject')}</Form.Label>
              <Form.Control onBlur={(e) => updateFilters(e, "subject")} />
            </Form.Group>
            <Form.Group as={Col} className="attachment_unread" id="formGridCheckbox">
              <Form.Check name='payment' onChange={(e) => updateFilters(e, "attachment")} className='attachment' type="checkbox" label={t('mymembership.msgtoGk.attachment')} />
              {messageType === "INCOMING" ?
                <Form.Check name='payment' onChange={(e) => updateFilters(e, "unread")} className='attachment' type="checkbox" label={t('mymembership.msgtoGk.unread')} />
                : null}
            </Form.Group>
          </Row>

          <Button className='suggest_btn' variant="success" onClick={() => openWriteMessagePopup()}>
            {t('mymembership.msgtoGk.write')}
            <Badge className='plus_bg'>+</Badge>
            <span className="visually-hidden">{t('mymembership.msgtoGk.write')}</span>
          </Button>

          {messages ? messages?.messages?.map((message) => (
            <div onClick={() => openRepoetedMessage(message)} className="msg_info" role="dialog" aria-label="Dialog">
              <div className="message_email">
                <span className="message_envelop">
                  <span className='icons_envelop'>
                    {messageType === "INCOMING" && message.unread === false
                      ? <FontAwesomeIcon icon={faEnvelopeOpen} /> : <FontAwesomeIcon icon={faEnvelope} />}
                  </span>
                  {messageType === "INCOMING" ? t('mymembership.msgtoGk.from') : t('mymembership.msgtoGk.to')
                  }:Gleichklang team
                </span>
              </div>
              <div className="mesasge_reply">
                <span className="">
                  <span className='icons_leftarrow'>
                    {messageType === "INCOMING" ? <FontAwesomeIcon icon={faArrowLeft} /> :
                      <FontAwesomeIcon icon={faArrowRight} />
                    }
                  </span>
                  {message.subject}
                </span>
              </div>
              <div className="message_attachment">
                <span className="pl-0">
                  {message?.attachments.length > 0 ? <span className='icon_paperclip'>
                    <FontAwesomeIcon icon={faPaperclip} />
                  </span> : null}
                  {message?.date}
                </span>
              </div>
            </div>
          )) : <Spinner />}
          {messages?.totalMessages ? <Pagination className="nature_pagination msgtoglk_pagination">
            {renderPaginationItems()}
            <Form.Label htmlFor='formRecordperpage'>{t('suggestions.recordperpage')}</Form.Label>
            <Form.Select
              id="formRecordperpage"
              onChange={(e) => updatePageSize(e.target.value)}
              defaultValue={page.pageSize}
              className='record_per_page' >
              <option value={5}>5</option>
              <option value={20}>20</option>
              <option value={50}>50</option>
              <option value={100}>100</option>
            </Form.Select>
          </Pagination> : null}
          {showWriteMessagePopup === true ?
            <WriteMessageToGleichklang
              show={showWriteMessagePopup}
              close={closeWriteMessagePopup}
            /> : null
          }
            {reportedMessages?.messages ? <SupportConversation
              show={reportedMessages?.isOpen}
              close={closeMessages}
              messages={reportedMessages}
            /> : null  }        
        </div>
      </main>
    </div>
  )
}

export default MessageToGK