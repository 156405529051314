export const baseUrl = process.env.REACT_APP_BASE_URL;
const personalDetailsURL = 'membership/personal/details'
const statsURL = 'membership/statistics'
const msgToGlkURL = 'support/message/type' 
const msgToGlkPostURL = 'support/message' 
const attachmentURL ='support/message/attachment'
const getReportedMessageURL = 'support/message/id'
const subcriptionOfferURL = 'products?productType='
const saveNewPasswordURL = 'member/profile/password'
export async function getPersonalDetails(data) {
  return getCall(data.accessToken, data.language, personalDetailsURL)    
}

export async function getStatistics(payload) {
  return getCall(payload.accessToken, payload.language, statsURL)
}
export async function getUpgradeOffer(payload) {
  return getCall(payload.accessToken, payload.language, subcriptionOfferURL+"UPGRADE_OFFER")
}
export async function getServiceOffer(payload) {
  console.log(payload);
  return getCall(payload.accessToken, payload.language, subcriptionOfferURL+"SERVICE_OFFER")
}

export async function getMessages(token_lang, payload) {
    const params = `${`?pageNo=${payload.pageNo}`}&${`pageSize=${payload.pageSize}`}${payload.filterString}`;
    const url = `${msgToGlkURL}/${payload.messageType}${params}`
    return getCall(token_lang.accessToken, token_lang.language, url)
}

export async function sendMsgToGlk(token_lang, messagePayload) {
  
    return fetch(`${baseUrl}/${msgToGlkPostURL}`, {
      method: 'POST',
      headers: getHeaders(token_lang.accessToken, token_lang.language, null),
      body: messagePayload
    }).then((response) =>
      response.json().then((data) => ({
        data: data,
        status: response.status,
      })).then((response) => {
        return response;
      })    
    ).catch((err) => {
      throw err;
    });
  }

const getCall = (token, language, url) => {
    if (token) {
      return fetch(`${baseUrl}/${url}`, {
        method: 'GET',
        headers: getHeaders(token, language, 'application/json'),
        'mode': 'cors'
      })
        .then((response) =>
          response.json().then((data) => ({
            data: data,
            status: response.status,
          }))
        )
        .then(async (responseData) => {
          return responseData;
        })
        .catch((err) => {
          throw err;
        });
    } else {
      console.log("no access token!")
    }
  }

  const getHeaders = (token, language, contentType) => {
    let headers = {
      'Authorization': `Bearer ${token}`,
      'Accept-Language': language
    }
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    return headers;
  }

  export async function getAttachment(token_lang, attachmentId) {
  if (token_lang?.accessToken) {
    return fetch(`${baseUrl}/${attachmentURL}/${attachmentId}`, {
      method: 'GET',
      headers: getHeaders(token_lang?.accessToken, token_lang?.language, 'application/json'),
      'mode': 'cors'
    })
    .then((response) =>{
      return (response)
    })
    .catch((err) => {
      throw err;
    });
  } else {
    console.log("no access token!")
  }
}

export async function getReportedMessage(token_lang, messageId) {  
  if (token_lang?.accessToken) {
    return fetch(`${baseUrl}/${getReportedMessageURL}/${messageId}`, {
      method: 'GET',
      headers: getHeaders(token_lang?.accessToken, token_lang?.language, 'application/json'),
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {        
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  } else {
    console.log("no access token!")
  }
}

export async function saveNewPassword(token_lang, payload) {
  return fetch(`${baseUrl}/${saveNewPasswordURL}`, {
    method: 'PATCH',
    headers: getHeaders(token_lang.accessToken, token_lang.language, 'application/json'),
    body: JSON.stringify(payload)
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}

