import './pendingPrePayment.css';
import React, { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import GlkHeader from '../../components/Header/GlkHeader';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCheckSquare,
    faTimesCircle,
		faEnvelope,
		faSquarePhone,
		faPhoneVolume
} from '@fortawesome/free-solid-svg-icons';
import { getPaymentDetails, changePaymentMethod } from '../registrationProcess/registrationProcessService';
import Payment from '../registrationProcess/payment';
import { getPaymentStatus } from './paymentServices';

function ProcessPayment(props) {
    const { t, i18n } = useTranslation();
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    let navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [prepaymentData, setPrepaymentData] = useState(null);
    const [paymentStatus, setPaymentStatus] = useState(null);
    const [isChangePaymentMethod, setChangePaymentMethod] = useState(false)
    const [showPaymentPage, setShowPaymentPage] = useState(false)
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const token_lang = {
        language: currentLang,
        accessToken
    }

    useEffect(() => {
        getPaymentDetails(token_lang).then((result) => {
            if (result?.status === 200) {
                setPrepaymentData(result?.data)
            }
        })
    }, [])
		
		//const paymentReference = prepaymentData?.externalReferenceId;
		const paymentReference = "4437-0048-0000";

    useEffect(() => {
        getPaymentStatus(paymentReference).then((result) => {
            if (result?.status === 200) {
                setPaymentStatus(result?.data)
            }
        })
    }, [])


    const onNext = () => {
        navigate('/home');
        return;
    }

    return (
        <>
          <GlkHeader showMenuBar={false} />
					{paymentStatus?.status == "confirmed" ? 
            <div className="main_wrapper pending_payment_wrapper payment_process">
													
								<div className="home_banner info_banner">
									<div className="home_banner_content info_banner_content payment_successful">
										<h1><FontAwesomeIcon icon={faCheckSquare} /> Payment successful</h1>
										<h2>Subscription has been activated</h2>
										<p>Your online payment for the amount of 280 EUR has been successfully processed. The payment reference is: xxx</p>	
										<p>We have activated your membership with full access to all features of the Gleichklang community.</p>
										<p>Please note that it takes up to 15 hours until you get your first suggestions.</p>	
										<Button
														className='main_button'
														variant="primary"
														onClick={() => onNext()}
												>
													Go to member area
												</Button>
												
									</div>
								</div>
													
								<div className="home_banner info_banner">
									<div className="home_banner_content info_banner_content gleichklang_tipps">
										<h1>Tipps from Gleichklang</h1>
										<h2>for a successful partner search</h2>
										<p>You will find some useful informations on how to improve your chances for a successful search in the options section on your dashboard.</p>
										<p>If you have any questions, please don't hesitate to contact our team by using the messenger inside your membeship area or by contact information listed here.</p>
										<p>You can also have a look to our <a href="https://www.gleichklang.de/faq/" target="_blank">FAQ</a> which will guide you to every area of Gleichklang.</p>
									</div>
								</div>						
				
								<div className="home_banner contact_banner">
									<div className="home_banner_content contact_banner_content">
										<h1>{t('registration.pendingPayment.contactTeamHeader')}</h1>
										<h2>{t('registration.pendingPayment.contactTeam')}</h2>
										
										<div className="contact_details_container">
												<div className="contact_number_free">
														<div className='contact_numberAndicon'>
																<div className='for_icon_circle'>
																		<FontAwesomeIcon icon={faPhoneVolume} />
																</div>
																<div>
																	<a className="text_white" href="tel: +49 (0)511 51516660" aria-label="call on 49 (0)511 51516660">+49 (0)511 51516660</a>
																	<span className="for_font_size">{t('member.footer.contactTime')}</span>
																</div>
														</div>
												</div>
												<div className="contact_number_paid">
														<div className='contact_numberAndicon'>
																<div className='for_icon_circle'>
																		<FontAwesomeIcon icon={faSquarePhone} />
																</div>
																<div>
																	<a className="text_white" href="tel: +49 (0) 1805 - 7772 3332" aria-label="Call on +49 (0) 1805 - 7772 3332"> +49 (0) 1805 - 7772 3332 </a>
																	<span className="for_font_size">(0.14 €/min)</span>
																</div>
														</div>
												</div>
												<div className="contact_email">
														<div className='contact_numberAndicon'>
																<div className='for_icon_circle'>
																		<FontAwesomeIcon icon={faEnvelope} />
																</div>
																<a className="text_white" href="mailto:anfragen@gleichklang.de" aria-label="mail to anfragen@gleichklang.de">anfragen@gleichklang.de</a>
														</div>
												</div>
										</div>	
									
									</div>
					
							</div>
						
            </div>
					:<div>Payment failed</div>}
        </>
    )
}
export default ProcessPayment;
