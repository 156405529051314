import React, { useEffect, useState, useContext } from 'react'
import Modal from 'react-bootstrap/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import { saveQuestionaire } from '../personalDetailsService';
import { LoginContext } from '../../../context/loginContext';
import { useTranslation } from 'react-i18next';
import { Alert } from 'react-bootstrap';
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from 'html-to-draftjs';

import {
    EditorState,
    convertToRaw,
    ContentState    
  } from "draft-js";

function EditFreeText(props) {
    const [selectedAnswer, setSelectedAnswer] = useState([]);
    const [isFreeTextLengthValid, setIsFreeTextLengthValid] = useState(false);
    const [errResponse, setErrResponse] = useState();
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [editorStates, setEditorStates] = useState({});
    const [focusedEditors, setFocusedEditors] = useState([]);
    const [changedEditors, setChangedEditors] = useState([]);

    let {
        loginData: {
          accessToken
        },
      } = useContext(LoginContext);
    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    const token_lang = {
        language: currentLang,
        accessToken
      }

    useEffect(() => {
        if (props?.profileData?.freeTextQuestionnaire?.questionGroups) {
            props?.profileData?.freeTextQuestionnaire?.questionGroups.map((item, index) => (
                item?.questions?.map((data) => {
                    if (data?.type === 'TextQuestion') {
                        const freeTextContent = data?.textQuestion?.answer || '';   
                                         
                        const initialEditorState = EditorState.createWithContent(
                            ContentState.createFromBlockArray(
                                htmlToDraft(freeTextContent)
                            )
                        )
                        
                        setEditorStates(prevEditorStates => ({
                            ...prevEditorStates,
                            [data.id]: initialEditorState
                        }));

                        setSelectedAnswer(prevAnswers => ({
                            ...prevAnswers,
                            [data.id]: [freeTextContent]
                        }));
                    }
                })
            ));
        }
    }, []);
    

    const onDataChange = (questionId, answer) => {        
        selectedAnswer[questionId] = [answer];
        setSelectedAnswer({ ...selectedAnswer });
      }

    const onSaveQuestionair = (questionId, answer) => {        
        const answerData = { [questionId]: { answerValues: [answer], profileVisible: false } };
        const answerBody = {
          questionnaireId: props?.profileData?.freeTextQuestionnaire?.id,
          answers: answerData
        }
        saveQuestionaire(answerBody, token_lang).then((response) => {
            if (response?.status === 200) {
                setErrResponse(null)
                props.setRefreshQuestionaire(true);
            } else {
                setIsError(true);
                setErrorMessage(response.data.message);
                setErrResponse(response.data)
            }
        })
      }

    const onFreetextSave = (e, items) => {
        focusedEditors?.push(items.id);
        setFocusedEditors(focusedEditors);

        if (e.target.value.length < 200 && items.name === "Free text") {
            setIsFreeTextLengthValid(true);
        } else {
            setIsFreeTextLengthValid(false);
            onSaveQuestionair(items.id, e.target.value);
        }
    };

    const onEditorStateChange = (editorState, items) => {
        changedEditors.push(items.id)
        setChangedEditors(changedEditors)
        setEditorStates(prevStates => ({
            ...prevStates,
            [items.id]: editorState
        }));

        let plainText = editorState.getCurrentContent().getPlainText();
        onDataChange(items.id, plainText);
    };

    const closeFreeText = () => {
        props.handleClose();
        const answers = {};

        changedEditors.forEach((questionId) => {
            if (focusedEditors != questionId) {
                const editorState = editorStates[questionId];
                if (editorState) {
                    let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent()))?.trim();

                    if (htmlContent && htmlContent.length > 0) {
                        answers[questionId] = { answerValues: [htmlContent], profileVisible: false };
                    }
                }
            }
        });

        if (Object.keys(answers).length > 0) {
            const answerBody = {
                questionnaireId: props?.profileData?.freeTextQuestionnaire?.id,
                answers: answers
            };
            saveQuestionaire(answerBody, token_lang).then((response) => {
                if (response?.status === 200) {
                    setErrResponse(null)
                    props.setRefreshQuestionaire(true);
                } else {
                    setIsError(true);
                    setErrorMessage(response.data.message);
                    setErrResponse(response.data)
                    props.setShowFreeTextError({ errorMsg: response.data , isError: true, statusCode: response?.status, statusError:  response.data.message});
                }
            })
        }
    }
    
    return (
        <div className="modal show">
            <Modal className={'incomplete_q_modal '+props.type} show={props.show} onHide={() => closeFreeText()} >
                <Modal.Header closeButton>
                    <div className="profile_logo_name">
                        <div className="profile_info">
                            <h3 className="partnership_color"><FontAwesomeIcon icon={faUser} /> {t('myprofile.editprofile')}</h3>
                        </div>
                    </div>
                </Modal.Header>
                <Modal.Body className='incomplete_questionaire'>
                    {isError ?
                        (
                            <Alert
                                className='member_address mt-2'
                                variant="danger"
                                id='main_alert'
                                dismissible
                            >
                                <p>{errorMessage}</p>
                            </Alert>
                        ) : null}
                    <div className="side_border">
                        <h3 className="headings">{props.profileData?.freeTextQuestionnaire.name}</h3>
                        <div className="descriptions">
                            <p dangerouslySetInnerHTML={{ __html: props.profileData?.freeTextQuestionnaire.description }} />
                        </div>
                    </div>                    
                    {props.profileData?.freeTextQuestionnaire.questionGroups?.map((data) => (
                        <div key={data.name} className="modal_content side_border">
                            <h3 className="headings">{data.name}</h3>
                            <div className="descriptions">
                                <p dangerouslySetInnerHTML={{ __html: data.description }} />
                            </div>
                            <div className='freetext_details'>
                                {
                                    data.questions.map((items) => {
                                        if (items?.type === "TextQuestion"){                                           
                                            return (<>
                                                <Alert className='alert_questionaire' show={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[items.id] ? true : false}
                                                    variant="danger" id={`err_${items.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[items.id]}</Alert>
                                                <Form.Group className=" text_question" id='textquestion'>
                                                    <Form.Label className="" htmlFor={items.id}>{items.name}<span className='required_asterisks'>{items.required ? '*' : null}</span></Form.Label>                                                
                                                    <Editor
                                                        handlePastedText={() => false}
                                                        editorState={editorStates[items.id] || EditorState.createEmpty()}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={(editorState) => onEditorStateChange(editorState, items)}
                                                        onBlur={() => {
                                                            focusedEditors?.push(items.id);
                                                            setFocusedEditors(focusedEditors);                                                    

                                                            let htmlContent = draftToHtml(convertToRaw(editorStates[items.id].getCurrentContent())).trim();
                                                            onFreetextSave({ target: { value: htmlContent } }, items);
                                                        }}
                                                        toolbar={{
                                                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'history'],
                                                            list: { inDropdown: true },
                                                            textAlign: { inDropdown: true },
                                                          }}
                                                    />
                                                    {isFreeTextLengthValid && items.name === "Free text" ?
                                                        (
                                                            <Alert
                                                                className='member_address mt-2'
                                                                variant="danger"
                                                                id='main_alert'
                                                                dismissible
                                                            >
                                                                <p>{t('suggestions.freetextlimit')}</p>
                                                            </Alert>
                                                        ) : null}
                                                </Form.Group>
                                                </>)}

                                        if (items.choiceQuestion?.type === "ComboBox")
                                            return (
                                                <Form.Group className=" mb-3 combo_box" id='combobox'>
                                                    <Form.Label id={data.id}>{data.name}
                                                        <span className='required_asterisks'>{data.required ? '*' : null}</span>
                                                    </Form.Label>
                                                    <Form.Select
                                                        id={data.id}
                                                        onChange={(e) => {
                                                            const selectedChoice = items?.choiceQuestion.choices.find(choice => choice.id === e.target.value);
                                                            onDataChange(items.id, selectedChoice);
                                                            onSaveQuestionair(items.id, e.target.value)
                                                          }}
                                                    >
                                                        <option>{t('registration.buttons.pleaseselect')}</option>
                                                        {items.choiceQuestion.choices.map(choice => (
                                                            <option
                                                                selected={choice.id === items.choiceQuestion?.selectedChoices[0]?.id}
                                                                key={choice.id}
                                                                value={choice.id}
                                                            >
                                                                {choice.value}
                                                            </option>
                                                        ))}
                                                    </Form.Select>
                                                </Form.Group>
                                            )
                                    }
                                    )}
                            </div>
                        </div>
                    ))}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default EditFreeText;