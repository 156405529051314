import React, { useRef, useContext, useState, useEffect, useCallback, useMemo } from 'react';
import GlkHeader from '../../../components/Header/GlkHeader';
import Banner from '../../../components/banner/banner';
import suggestionIcon from '../../../assets/images/suggestion.png';
import './messenger.css';
import Form from 'react-bootstrap/Form';
import useWebSocketComponent from './webSocket';
import { LoginContext } from '../../../context/loginContext';
import UsersList from './usersList';
import ChatHistory from './chatHistory';
import Spinner from '../../../components/spinner';
import { useTranslation } from 'react-i18next';

function Messenger() {
  const { sendMessage, userDetails, chatRoom, usersChat} = useWebSocketComponent();
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const [currentUser, setCurrentUser] = useState({});
  const [currentAvatar, setCurrentAvatar] = useState('');
  const [pageNo, setPageNo] = useState(1);
  const [recordsRemaining, setRecordsRemaining] = useState(true);
  const [attachmentFiles, setAttachmentFiles] = useState([]);
  const [isUserAvailable, setIsUserAvailable] = useState(false);
  const [isMessageError, setIsMessageError] =  useState(false);
  const [userListPageNo, setUserListPageNo] = useState(1);
  const [isFileLoader, setIsFileLoader] = useState(false);
  const [isFileResponseReceived, setIsFileResponseReceived] = useState(false);
  const [filters, setFilters] = useState({
    friendship: false,
    partnership: false,
    contactList: false,
    hideUnavailable: true,
    unanswered: false,
    unread: false,
  });

  const {
    loginData: { memberDetails, accessToken },
  } = useContext(LoginContext);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const getUsers = {
      type: "AUTH",      
      token: accessToken,
    };
    sendMessage(JSON.stringify(getUsers));
  }, [accessToken]);

  useEffect(() => {
    if (chatRoom?.subType === 'CHAT_ROOMS') {
      if(chatRoom?.recordsRemaining){
        setUserListPageNo(prev => prev + 1);
      }      
      setUsers((prevUsers) => {
        let newUsers = chatRoom?.chatRooms;
        return [...new Set([...prevUsers, ...newUsers])]
      });

      if(chatRoom?.chatRooms){
        setIsUserAvailable(false)
      }     
    }
  }, [chatRoom?.chatRooms])

  useEffect(() => {
    if (userDetails?.error) {
      setIsMessageError(true)
      setIsFileLoader(false);

    }
  }, [userDetails])
  
  useEffect(() => {    
    if (usersChat?.fileType === 'IMAGE' || usersChat?.fileType === 'FILE') {      
      setAttachmentFiles((prevAttachments) => [usersChat, ...prevAttachments]);
      setIsFileResponseReceived(false);
    }

    if (usersChat?.subType === 'CHAT_HISTORY' || usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE") {
      if (usersChat?.subType === "CHAT_HISTORY") {
        setRecordsRemaining(usersChat?.recordsRemaining);
      }

      setIsFileLoader(false);

      const newMessages =
      usersChat?.type === "TEXT_MESSAGE" || usersChat?.type === "FILE_MESSAGE"
          ? [usersChat]
          : usersChat?.messages;

      setChats((prevMessages) => {
        const isChatHistory = usersChat?.subType === "CHAT_HISTORY";
        const currentUserId = currentUser?.userId;
        const newMessage = newMessages[0];
        const isCurrentUserInvolved = newMessage && (currentUserId === newMessage.receiverId || currentUserId === newMessage.senderId);

        if (isChatHistory) {
          return [...new Set([...prevMessages, ...newMessages])];
        } else if (isCurrentUserInvolved) {
          return [...new Set([...newMessages, ...prevMessages])];
        } else {
          const additionalMessages = usersChat?.messages || [];
          return [...new Set([...prevMessages, ...additionalMessages])];
        }
      });
    }

    if (usersChat?.subType === "DELETE") {
      setChats((prevMessages) => 
        prevMessages.filter((msg) => msg.id != usersChat.messageId)
      );
    }
  
  }, [usersChat]);

  const getUserList = (page) => {
    if (!users.length) {
      setIsUserAvailable(true);
    }
    const getUsers = {
      type: "API",
      subType: "CHAT_ROOMS",
      pageNo: page,
      language: i18n.language
    };
    sendMessage(JSON.stringify(getUsers));
  }

  useEffect(() => {
    const markMesgRead = {
        type: "API",
        subType: "MARK_READ",
        targetUserId: currentUser?.userId
        };
    setTimeout(() => {
      sendMessage(JSON.stringify(markMesgRead));
    }, 2000);
  }, [currentUser?.userId]);
    
  useEffect(() => {
    getUserList(userListPageNo);
  }, [userListPageNo]);

  useEffect(() => {
    setChats([]);
  }, [currentUser]);

  const data = {
    image: suggestionIcon,
    menuName: t('messenger.suggestions'),
    header:  t('messenger.message'),
    description:  t('messenger.messagedescription'),
    color: "#12a15e",
  };

  const fetchChatHistory = (page) => {
    const message = {
      type: "API",
      subType: "CHAT_HISTORY",
      pageNo: page,
      targetUserId: currentUser?.userId,
      pageSize: 20,
    };
    if (currentUser?.userId) {
      sendMessage(JSON.stringify(message));
    }
  };

  useEffect(() => {
      fetchChatHistory(pageNo);      
  }, [pageNo, currentUser]);

  const chatContainerRef = useRef(null);
  const lastContactElementRef = useCallback(
    (node) => {
      if (chatContainerRef.current) chatContainerRef.current.disconnect();
      chatContainerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && recordsRemaining) {
          setPageNo((prevMessagePage) => prevMessagePage + 1);
        }
      });
      if (node) chatContainerRef.current.observe(node);
    },
    [recordsRemaining]
  );

  const onFilterChange = (e) => {
    const { name, checked } = e.target;
    setFilters({
      ...filters,
      [name]: checked,
    });
  };

  const filteredUsers = useMemo(() => {
    const filtered = users.filter(user => {
      const group1 = filters.friendship || filters.partnership || filters.contactList;
      const group1Match =
        (filters.friendship && user.friendship) ||
        (filters.partnership && user.partnership) ||
        (filters.contactList && user.contactListUser);
      const group2 = filters.hideUnavailable || filters.unanswered || filters.unread;
      const group2Match =
        (!filters.hideUnavailable || user.available) &&
        (!filters.unanswered || user.unanswered) &&
        (!filters.unread || user.unread);
      return (!group1 || group1Match) && (!group2 || group2Match);
    });
    return Array.from(new Set(filtered.map(user => user.relationshipId)))
      .map(id => filtered.find(user => user.relationshipId === id));
  }, [users, filters]); 

  return (
    <div>
      <GlkHeader />
      <main className="main_wrapper suggestions_wrapper">
        <Banner data={data} />        
          <div className='messenger_container'>
            <div className='filter_container'>
              { memberDetails?.partnershipActive === true && memberDetails?.friendshipActive === true ? <>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className='msg_pre_checkbox'
                  type="checkbox"
                  label={t('messenger.friendship')}
                  name="friendship"
                  checked={filters.friendship}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className='msg_pre_checkbox'
                  type="checkbox"
                  label={t('messenger.partnership')}
                  name="partnership"
                  checked={filters.partnership}
                  onChange={onFilterChange}
                />
              </Form.Group></> : null}
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className='msg_pre_checkbox'
                  type="checkbox"
                  label={t('messenger.contactlist')}
                  name="contactList"
                  checked={filters.contactList}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className='msg_pre_checkbox'
                  type="checkbox"
                  label={t('messenger.hac')}
                  name="hideUnavailable"
                  checked={filters.hideUnavailable}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className='msg_pre_checkbox'
                  type="checkbox"
                  label={t('messenger.unanswered')}
                  name="unanswered"
                  checked={filters.unanswered}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group id="formGridCheckbox">
                <Form.Check
                  className='msg_pre_checkbox'
                  type="checkbox"
                  label={t('messenger.unread')}
                  name="unread"
                  checked={filters.unread}
                  onChange={onFilterChange}
                />
              </Form.Group>
            </div>
            {!isUserAvailable  ?
              <div className='m_suggestion_chat'> 
                  <UsersList
                      users={filteredUsers}
                      isFileLoader={isFileLoader}
                      currentUser={currentUser}
                      currentAvatar={currentAvatar}
                      sendMessage={sendMessage}
                      setPageNo={setPageNo}
                      isUser={users?.length == 0} 
                      isUserAvailable={isUserAvailable}
                      isFilterUser={filteredUsers.length == 0}
                      setCurrentUser={setCurrentUser}
                      setCurrentAvatar={setCurrentAvatar}
                      isFileResponseReceived={isFileResponseReceived}
                    />
                    <ChatHistory
                      chats={chats}
                      setUsers={setUsers}
                      userDetails={userDetails}
                      isMessageError={isMessageError}
                      isFileLoader={isFileLoader}
                      setIsFileLoader={setIsFileLoader}
                      setIsMessageError={setIsMessageError}
                      sourceType={'MESSENGER'}
                      sendMessage={sendMessage}
                      currentUser={currentUser}
                      setCurrentUser={setCurrentUser}
                      isFilterUser={filteredUsers.length == 0}
                      currentAvatar={currentAvatar}
                      attachmentFiles={attachmentFiles}
                      chatContainerRef={lastContactElementRef}
                      setIsFileResponseReceived={setIsFileResponseReceived}
                      isFileResponseReceived={isFileResponseReceived}
                    />
              </div> : <Spinner />}
          </div>
      </main>
    </div>
  );
}

export default Messenger;
