import React, { useState, useEffect, useContext } from 'react';
import GlkHeader from '../../components/Header/GlkHeader';
import Accordion from 'react-bootstrap/Accordion';
import './personalData.css';
import membershipIcon from '../../assets/images/membership.png';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/banner/banner';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import ProgressBar from 'react-bootstrap/ProgressBar';
import { getPersonalDetails, saveNewPassword } from './membershipServices.js';
import { LoginContext } from '../../context/loginContext';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash  } from '@fortawesome/free-solid-svg-icons';
import { checkPasswordStrength } from '../commonMethods';
import AlertModal from '../commonComponents/alert';
import Modal from 'react-bootstrap/Modal';

function PersonalData() {
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const [personalDetails, setPersonalDetails] = useState(null);
  const [dob, setDob] = useState();
  const [terminateValue, setTerminateValue] = useState("temporary");
  const [passwordMetaData, setPasswordMetaData] = useState(null)
  const [password, setPassword] = useState({old: null, new: null, repeat: null})
  const [passwordAlerts, setPasswordAlerts] = useState({new: false, repeat: false, common: false, commonValue: '', commonType: ''})
  const [showPopup, setShowPopup] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  let {
    loginData: {
      memberDetails,
      accessToken
    }, 
  } = useContext(LoginContext);


  const data = {
    "image": membershipIcon,
    "menuName": "My Membership",
    "header": "Personal Data",
    "description": "You can change your address, add a further address (e.g. second home, holiday address), change password and email, block messages from Gleichklang, and cancel your membership here. If you have another address, it is important that you add this, as it broadens your circle of possible suggestions.",
    "color": "#063978"
  }

  useEffect(() => {
    const data = {
      language: currentLang,
      accessToken: accessToken
    }
    getPersonalDetails(data).then((res) => {
      if (res?.status === 200) {
        setPersonalDetails(res.data);
        if (res && res.data.dateOfBirth) {
          const dateString = new Intl.DateTimeFormat('fr-CA', {year: 'numeric', month: '2-digit',day: '2-digit'}).format(new Date(res.data.dateOfBirth));
          setDob(res.data?.dateOfBirth)
        }
      }
    })    
  }, [currentLang, accessToken])
  const toggleShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
};
const toggleShowNewPassword = () => {
  setShowNewPassword(!showNewPassword);
};
const toggleShowRepeatPassword = () => {
  setShowRepeatPassword(!showRepeatPassword);
};

const validatePassword = async (value) => {
  const pwdMetaData = await checkPasswordStrength(value, t)
  setPasswordMetaData(pwdMetaData)
  updatePassword(value, "new", pwdMetaData.strength)
}

  const updatePassword = (value, type, strength) => {
    let updatedPasswordAlert = passwordAlerts;
    let updatedPasswordValue = password;
    if (passwordAlerts.common && passwordAlerts.commonType === 'api') {
      updatedPasswordAlert = { ...passwordAlerts, common: false, commonValue: '', commonType: '' };
    }

    switch (type) {
      case "old":
        if (value.trim().length > 1 && passwordAlerts.commonType === 'old')
          updatedPasswordAlert = { ...passwordAlerts, common: false, commonValue: '', commonType: '' };
        updatedPasswordValue = { ...password, old: value }
        break;
      case "new":
        if (strength < 3)
          updatedPasswordAlert = { ...passwordAlerts, new: true };
        else
          updatedPasswordAlert = { ...passwordAlerts, new: false };
        updatedPasswordValue = { ...password, new: value }
        break;
      case "repeat":
        if (passwordMetaData?.strength < 3)
          updatedPasswordAlert = { ...passwordAlerts, new: true };
        else
          updatedPasswordAlert = { ...passwordAlerts, new: false };
        if (password?.new != value)
          updatedPasswordAlert = { ...passwordAlerts, repeat: true };
        else
          updatedPasswordAlert = { ...passwordAlerts, repeat: false };
        updatedPasswordValue = { ...password, repeat: value }
        break;
    }
    setPasswordAlerts(updatedPasswordAlert);
    setPassword(updatedPasswordValue);
  }

const savePassword = async() => {
  if(passwordMetaData?.strength < 3) {
    setPasswordAlerts(prev => ({ ...prev, new: true}));
    return
  }
  else setPasswordAlerts(prev => ({ ...prev, new: false}));
  if(password?.new != password?.repeat) {
    setPasswordAlerts(prev => ({ ...prev, repeat: true}));
    return
  }
  else setPasswordAlerts(prev => ({ ...prev, repeat: false}));

  if(!password || !password.old || password?.old.trim().length < 1 ) {
    setPasswordAlerts(prev => ({ ...prev, common: true, commonValue: 'old pwd cant be empty', commonType: 'old'}));
    return
  } else setPasswordAlerts(prev => ({ ...prev, common: false, commonValue: '', commonType: ''}));
  
  if(passwordMetaData?.strength >= 3 && password?.old && password?.new && password?.repeat && password?.new == password?.repeat) {
    const tokenAndLanguage = {
      language: currentLang,
      accessToken: accessToken
    }
    const payload = {
      oldPassword: password.old,
      newPassword: password.new,
      repeatNewPassword: password.repeat
    }

    saveNewPassword(tokenAndLanguage, payload).then((response) => {
      if (response?.status === 200) {
        setShowPopup(true)
        setPasswordAlerts(prev => ({ ...prev, common: false, commonValue: '', commonType: ''}));   
        setPassword({old: '', new: '', repeat: ''})
      } else {
        setPasswordAlerts(prev => ({ ...prev, common: true, commonValue: response?.data?.message, commonType: 'api'}));
      }
    })
  }
}

  return (
    <div>
    <GlkHeader/>
    <main className="main_wrapper membership_wrapper">
      <Banner data={data} />
      <div className='questionaire_in_menubar'>
        <div className='membership_container'>
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className='accordian_header'>{t('member.personaldata.personaldetails.title')}</Accordion.Header>
              <Accordion.Body>
                <Form>
                  <Row className="mb-3">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>{t('member.personaldata.personaldetails.alias')}*</Form.Label>
                      <Form.Control type='text' disabled defaultValue={memberDetails?.alias} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>{t('member.personaldata.personaldetails.firstname')}*</Form.Label>
                      <Form.Control type="text" placeholder="First Name" defaultValue={personalDetails?.firstName} />
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <Form.Label>{t('member.personaldata.personaldetails.lastname')}*</Form.Label>
                      <Form.Control type="text" placeholder="Last Name" defaultValue={personalDetails?.lastName} />
                    </Form.Group>
                  </Row>
                  <Row className="mb-4">
                    <Form.Group as={Col} controlId="formGridEmail">
                      <Form.Label>{t('member.personaldata.personaldetails.sex')}*</Form.Label>
                      <Form.Select defaultValue={personalDetails && personalDetails.sexQuestion ?
                        personalDetails.sexQuestion.choices.filter(opt => opt.choiceId === personalDetails.sexQuestion.answer).value
                        : "Your sex"} >
                        {personalDetails && personalDetails.sexQuestion.choices.map((choice) => {
                          return (<option value={choice.choiceId}>{choice.value}</option>)
                        })
                        }
                      </Form.Select>
                    </Form.Group>

                    <Form.Group as={Col} controlId="formGridPassword">
                      <div className='datepicker_aboutu'>
                              <Form.Label htmlFor="inputPassword5">{t('member.preregistration.dob')}{currentLang === 'de' ? '(z.B. 31.01.2021)' : '(e.g. 01/31/2021)'}*</Form.Label>
                              <DatePicker 
                                  selected={dob}                             
                                  dateFormat={currentLang === 'de' ? "dd.MM.yyyy" : "MM/dd/yyyy"}
                                  onChange={(e) => {
                                    setDob(e)
                                  }}                               
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                  placeholderText={t('member.preregistration.dob')}
                              />
                          </div>
                    </Form.Group>
                  </Row>
                  
                  {personalDetails && personalDetails.addresses ? personalDetails.addresses.map((address, index) => {
                    return(
                    <div>
                      <div id='address' className="form_head_part">
                        <p>{`${t('member.personaldata.personaldetails.address')} - ${index+1}`}</p>
                        <span>
                        </span>
                      </div>
                      <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridCity">
                          <Form.Label>{t('member.personaldata.personaldetails.continent')}</Form.Label>
                          <Form.Select defaultValue={address.continent.value}>
                            <option>{address.continent.value}</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                          <Form.Label>{t('member.personaldata.personaldetails.country')}</Form.Label>
                          <Form.Select defaultValue={address.country.value}>
                            <option>{address.country.value}</option>
                          </Form.Select>
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridState">
                          <Form.Label>{t('member.personaldata.personaldetails.region')}*</Form.Label>
                          <Form.Select defaultValue={address.region.value}>
                            <option>{address.region.value}</option>
                          </Form.Select>
                        </Form.Group>
                      </Row>
                      <Row>
                        <Form.Group as={Col} controlId="formGridZip">
                          <Form.Label>{t('member.personaldata.personaldetails.zip')}*</Form.Label>
                          <Form.Control type='text' placeholder='zip-code' defaultValue={address?.zip?.value} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCity">
                          <Form.Label>{t('member.personaldata.personaldetails.city')}</Form.Label>
                          <Form.Control type="text" placeholder='City' defaultValue={address.city} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="formGridCity">
                          <Form.Label>{t('member.personaldata.personaldetails.street')}</Form.Label>
                          <Form.Control type="text" placeholder='street/house no.' defaultValue={address.street} />
                        </Form.Group>

                        <Form.Group className="mb-3 mt-3 missing_zip" id="formGridCheckbox">
                          <Form.Check type="checkbox" label={t('member.personaldata.personaldetails.zipmissing')} />
                        </Form.Group>
                      </Row>
                    </div>)
                  })
                    : null}

                  <div className="personal_data_btn">
                    <Button className='mx-5' as={Col} variant="primary" type="submit">
                    {t('member.personaldata.save')}
                    </Button>
                    <Button className='mx-5' as={Col} variant="primary" type="submit">
                    {t('member.personaldata.personaldetails.addfurtheraddress')}
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1">
              <Accordion.Header className='accordian_header'>{t('member.personaldata.changepassword.title')}</Accordion.Header>
              <Accordion.Body>
                <p>{t('member.personaldata.changepassword.description')}</p>
                <AlertModal className={'mt-3'} show={passwordAlerts.common} setShow={null} heading={'Error'} body={passwordAlerts.commonValue}></AlertModal>
                <Form.Group className='position-relative'>
                <Form.Label className="mt-3" htmlFor="inputPassword5">{t('member.personaldata.changepassword.old')}*</Form.Label>
                <Form.Control
                  type={showOldPassword ? "text" : "password"}
                  id="inputPassword5" onBlur={(e) => { updatePassword(e.target.value, "old", null)}}
                  onChange={(e) => {setPassword(prev => ({...prev, old: e.target.value}))}}
                  aria-describedby="passwordHelpBlock" value={password.old}
                />
                    <span onClick={toggleShowOldPassword} className="showPasswordIcon_membership">
                      <FontAwesomeIcon icon={showOldPassword ? faEyeSlash : faEye} />
                    </span>
                </Form.Group>
                <Form.Group className='position-relative'>
                <Form.Label className="mt-3" htmlFor="inputPassword5">{t('member.personaldata.changepassword.new')}*</Form.Label>
                <AlertModal className={'mt-3'} show={passwordAlerts.new} setShow={null} heading={'Error'} body={t('member.preregistration.errors.pwdBody')}></AlertModal>
                <Form.Control
                  type={showNewPassword ? "text" : "password"}
                  id="inputPassword5" onBlur={(e) => {validatePassword(e.target.value)}}
                  onChange={(e) => {setPassword(prev => ({...prev, new: e.target.value}))}}
                  aria-describedby="passwordHelpBlock" value={password.new}
                />
                    <span onClick={toggleShowNewPassword} className="showPasswordIcon_membership">
                      <FontAwesomeIcon icon={showNewPassword ? faEyeSlash : faEye} />
                    </span>
                      </Form.Group>
                  <div className='progess_bar'>
                    <ProgressBar
                      className='reg_progress_bar'
                      now={passwordMetaData ? (passwordMetaData.strength / 5) * 100 : 0}
                      variant={passwordMetaData ? passwordMetaData.varient : "danger"}
                      label={passwordMetaData?.label}
                      tabIndex="-1"
                      striped
                      aria-label={t('member.preregistration.labels.passwordIs') + " " + passwordMetaData?.label}
                    >
                    </ProgressBar>
                    <p>{t('member.preregistration.pstrength')}</p>
                  </div>
                <Form.Group className='position-relative'>
                <Form.Label className="mt-3" htmlFor="inputPassword5">{t('member.personaldata.changepassword.repeatnew')}*</Form.Label>
                <AlertModal className={'mt-3'} show={passwordAlerts.repeat} setShow={null} heading={'Error'} body={t('mymembership.changePassword.confirmPwdBody')}></AlertModal>
                <Form.Control 
                  type={showRepeatPassword ? "text" : "password"}
                  id="inputPassword5" onBlur={(e) => { updatePassword(e.target.value, "repeat", null)}}
                  onChange={(e) => {setPassword(prev => ({...prev, repeat: e.target.value}))}}
                  aria-describedby="passwordHelpBlock" value={password.repeat}
                />
                    <span onClick={toggleShowRepeatPassword} className="showPasswordIcon_membership">
                      <FontAwesomeIcon icon={showRepeatPassword ? faEyeSlash : faEye} />
                    </span>
                </Form.Group>
                <div className="changePass_btn mt-3">
                  <Button className='mx-5' as={Col} variant="primary" type="submit" 
                    onClick={() => savePassword()} >
                  {t('member.personaldata.save')}
                  </Button>
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header className='accordian_header'>{t('member.personaldata.changeemail.title')}</Accordion.Header>
              <Accordion.Body>
                <p>{t('member.personaldata.changeemail.description')}</p>
                <Form.Label className="mt-3" htmlFor="inputPassword5">{t('member.personaldata.changeemail.new')}**</Form.Label>
                <Form.Control
                  type="password"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                />
                <Form.Label className="mt-3" htmlFor="inputPassword5">{t('member.personaldata.changeemail.repeatnew')}**</Form.Label>
                <Form.Control
                  type="password"
                  id="inputPassword5"
                  aria-describedby="passwordHelpBlock"
                />
                <div className="changePass_btn">
                  <Button className='mx-5' as={Col} variant="primary" type="submit">
                  {t('member.personaldata.save')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className='accordian_header'>{t('member.personaldata.messagepreference.title')}</Accordion.Header>
              <Accordion.Body>
                <div className="form_head_part">
                  <p>{t('member.personaldata.messagepreference.subtitle')}</p>
                  <span>
                  </span>
                </div>
                <p>{t('member.personaldata.messagepreference.description')}</p>
                <Form.Group className="mb-3 first_child" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.messagepreference.recommendationMailNotifications')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.messagepreference.cipherMessageNotification')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.messagepreference.postiveRankingNotifications')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.messagepreference.footPrintNotifications')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.messagepreference.newsNotification')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.messagepreference.disableAllNotifications')} />
                </Form.Group>
                <div className="changePass_btn">
                  <Button className='mx-5' as={Col} variant="primary" type="submit">
                  {t('member.personaldata.save')}
                  </Button>
                </div>

              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className='accordian_header'>{t('member.personaldata.cancellation.title')}</Accordion.Header>
              <Accordion.Body>
                <div className="form_head_part">
                  <p>{t('member.personaldata.cancellation.cancelAutoRenewalTitle')}</p>
                  <span>
                  </span>
                </div>
                <Button className='mx-5 mb-4' as={Col} variant="secondary" type="submit">
                  {t('member.personaldata.cancellation.cancelAutoRenewalButton')}
                </Button>
                <br></br>
                <div className="form_head_part">
                  <p>{t('member.personaldata.cancellation.cancelTitle')}</p>
                  <span>
                  </span>
                </div>
                <p className=' terminate_mrship'>{t('member.personaldata.cancellation.cancelDescription')}</p>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="radio" name='terminate_parent' onClick={()=>{setTerminateValue('permanent')}}
                    label={t('member.personaldata.cancellation.deleteDataDescription')}
                  />
                  <Form.Check className='msg_pre_checkbox' type="radio" name='terminate_parent' onClick={()=>{setTerminateValue('temporary')}}
                    label={t('member.personaldata.cancellation.keepDataDescription')}
                  />
                </Form.Group>

                <div className="form_head_part">
                  <p>{t('member.personaldata.cancellation.keepMailTitle')}</p>
                  <span>
                  </span>
                </div>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="radio" name='mail_check'
                    label={terminateValue.includes('permanent') 
                    ? t('member.personaldata.cancellation.deleteDataKeepMail') : t('member.personaldata.cancellation.keepDatakeepMail')}
                  />
                  <Form.Check className='msg_pre_checkbox' type="radio" name='mail_check'
                    label={terminateValue.includes('permanent') 
                    ? t('member.personaldata.cancellation.deleteDataDeleteMail') : t('member.personaldata.cancellation.keepDataNoMail')}
                  />
                </Form.Group>

                <div className="form_head_part">
                  <p>{t('member.personaldata.cancellation.cancelReasonsTitle')}</p>
                  <span>
                  </span>
                </div>
                <Form.Group className="mb-3 first_child" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.cancellation.successThorughGK')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.cancellation.noTime')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.cancellation.unsuitableRecommendations')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.cancellation.successElsewhere')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.cancellation.other')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.cancellation.tooLessRecommendations')} />
                </Form.Group>
                <Form.Group className="mb-3 mt-3" id="formGridCheckbox">
                  <Form.Check className='msg_pre_checkbox' type="checkbox" label={t('member.personaldata.cancellation.unhappyWithService')} />
                </Form.Group>
                <div className="changePass_btn">
                  <Button className='mx-5' as={Col} variant="primary" type="submit">
                  {t('member.personaldata.cancelMembership')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
		</main>
      <Modal className="popup_modal" show={showPopup} onHide={() => setShowPopup(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('mymembership.changePassword.changeSaved')}</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
            <Button variant="outline-danger" onClick={() => { setShowPopup(false) }}>
              {t('common.close')}
            </Button>          
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default PersonalData;