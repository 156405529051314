export const baseUrl = process.env.REACT_APP_BASE_URL
const loginURL = 'login/memberLogin'
const memberDetailURL = 'member/details'

export async function doLogin(data) {
  return fetch(`${baseUrl}/${loginURL}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data),
  }).then((response) =>
    response.json().then((data) => ({
      data: data,
      status: response.status,
    })).then((response) => {
      return response;
    })    
  ).catch((err) => {
    throw err;
  });
}

export async function getMemberDetails(accessToken) {
  if (accessToken) {
    return fetch(`${baseUrl}/${memberDetailURL}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${accessToken}`,
      },
      'mode': 'cors'
    })
      .then((response) =>
        response.json().then((data) => ({
          data: data,
          status: response.status,
        }))
      )
      .then(async (responseData) => {
        return responseData;
      })
      .catch((err) => {
        throw err;
      });
  }
}
