import React, { useEffect, useState } from 'react'
import GlkHeader from '../../components/Header/GlkHeader';
import membershipIcon from '../../assets/images/membership.png';
import Banner from '../../components/banner/banner'
import './statistics.css'
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { useTranslation } from 'react-i18next';
import Alert from 'react-bootstrap/Alert';
import {getStatistics} from './membershipServices'


function Statistics() {
  const { t, i18n } = useTranslation();
  const [userStats, setUserStats] = useState(null)
  const [showNoStatMessage, setShowNoStatMessage] = useState(false)

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const data = {
    "image": membershipIcon,
    "menuName": t('mymembership.mymembership'),
    "header": t('mymembership.statistics.statistics'),
    "description": t('mymembership.statistics.description'),
    "color": "#063978"
  }

  useEffect(() => {
    const payload = {
      accessToken: accessToken,
      language: i18n.language
    }
    getStatistics(payload).then((response) => {
      if(response.status === 200) {
        setUserStats(response.data)
        setShowNoStatMessage(false)
      }
      else {
        setUserStats(null)
        setShowNoStatMessage(true)
      }
    })
  }, [])

  return (
    <div>
    <GlkHeader/>
    <main className="main_wrapper membership_wrapper">
      <Banner data= {data}/>
      <div className='msg2gk_container'> 
      {/* <div className='container'>
        <div className="form_head_part text">
          <p>{t('mymembership.statistics.activity')}</p>
          <span className='underline'>
          </span>
        </div>
      </div> */}
          <Alert className='alert_questionaire' show={showNoStatMessage} variant="danger" id='main_alert'>
            <p>{t('mymembership.statistics.errorMsg')}</p>
          </Alert>
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.activity')}</span>
          <span className="underline"></span>
        </div>
        {showNoStatMessage===true ? null : <div> 
        <div className="form-group mmship-statics-activity">
          <p>{t('mymembership.statistics.viewProposalReceived')}{userStats?.datingActivity?.totalViewedByTotalProposalReceived}{t('mymembership.statistics.proposalRec')}</p>
          <p>{t('mymembership.statistics.firstReplied')}{userStats?.datingActivity?.totalRepliesByTotalMessageSentFirst}{t('mymembership.statistics.firstRepliedRec')}</p>
          <p>{t('mymembership.statistics.firstWrote')}{userStats?.datingActivity?.firstWroteMessageByTotalProposals}{t('mymembership.statistics.firstWroteRec')}</p>
        </div>
        {/* <div className="form_head_part">
          <p>{t('mymembership.statistics.cipher')}</p>
          <span>
          </span>
        </div> */}
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.cipher')}</span>
          <span className="underline"></span>
        </div>
        <div className="text_direction">
          {userStats?.receivedMessages?.partnership ? 
          <p>{t('mymembership.statistics.partnership')} {userStats?.receivedMessages?.partnership} </p> : null}
          {userStats?.receivedMessages?.friendship ? 
          <p>{t('mymembership.statistics.friendship')} {userStats?.receivedMessages?.friendship} </p> : null}
          <p>{t('mymembership.statistics.contactList')} {userStats?.receivedMessages?.contactList} </p>
        </div>
        {/* <div className="form_head_part">
          <p>{t('mymembership.statistics.suggestion')}</p>
          <span>
          </span>
        </div> */}
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.suggestion')}</span>
          <span className="underline"></span>
        </div>
        <div className="text_direction">
          {userStats?.receivedSuggestions?.partnership ? 
          <p>{t('mymembership.statistics.partnership')} {userStats?.receivedSuggestions?.partnership} </p> : null}
          {userStats?.receivedSuggestions?.friendship ? 
          <p>{t('mymembership.statistics.friendship')} {userStats?.receivedSuggestions?.friendship} </p> : null}
        </div>
        {/* <div className="form_head_part">
          <p>{t('mymembership.statistics.sentcipher')}</p>
          <span>
          </span>
        </div>     */}
        <div className="container_statistics">
          <span className="text">{t('mymembership.statistics.sentcipher')}</span>
          <span className="underline"></span>
        </div>
        <div className="text_direction">
          {userStats?.sentMessages?.partnership ? 
          <p>{t('mymembership.statistics.partnership')} {userStats?.sentMessages?.partnership} </p> : null}
          {userStats?.sentMessages?.friendship ? 
          <p>{t('mymembership.statistics.friendship')} {userStats?.sentMessages?.friendship} </p> : null}
          <p>{t('mymembership.statistics.contactList')} {userStats?.sentMessages?.contactList} </p>
        </div>     
        </div>} 
      </div>
		</main>
    </div>
  )
}

export default Statistics