import React, { useRef, useContext, useState, useEffect } from 'react';
import './messenger.css';
import { getAvetar } from '../suggestionService';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import happyFace from '../../../assets/images/icon_angenommen.png';
import UserIcon from '../../../assets/images/profile_pic_1.png';
import maleSvg from '../../../assets/images/male.svg';
import femaleSvg from '../../../assets/images/female.svg';
import intersex from '../../../assets/images/intersexual.svg';
import kontactLogo from '../../../assets/images/icon_category_kontakt.png';
import friendshipIcon from '../../../assets/images/icon_category_friendship.png';
import partnershipIcon from '../../../assets/images/icon_category_partnership.png';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../../context/loginContext';
function UsersList(props) {
  const {
    users,
    isUser,
    setPageNo,
    currentUser,
    isFilterUser,
    isFileLoader,
    setCurrentUser,
    isUserAvailable,
    setCurrentAvatar,
    isFileResponseReceived
  } = props;

  const [avatars, setAvatars] = useState({});
  const [searchQuery, setSearchQuery] = useState("");

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;

  const token_lang = {
    language: currentLang,
    accessToken
  };

  const avtar = async (id) => {
    try {
      const result = await getAvetar(token_lang, id);
      if (result?.status === 200) {
        const blob = await result.blob();
        const fileURL = URL.createObjectURL(blob);
        return fileURL;
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
    return '';
  };

  useEffect(() => {
    const fetchAvatars = async () => {
      if (!users || !Array.isArray(users)) {
        return;
      }

      const avatarPromises = users.map(async (user) => {
        const avatarUrl = await avtar(user.avatarId);
        return { id: user.avatarId, avatarUrl };
      });

      try {
        const avatarResults = await Promise.all(avatarPromises);
        const avatarMap = avatarResults.reduce((acc, { id, avatarUrl }) => {
          acc[id] = avatarUrl;
          return acc;
        }, {});
        setAvatars(avatarMap);
      } catch (error) {
        console.error("Error fetching avatars:", error);
      }
    };

    fetchAvatars();
  }, [users]);

  const getUserChat = (user) => {
    setPageNo(1)
    setCurrentUser(user);
    setCurrentAvatar(avatars?.users?.avatarId)    
  };

  useEffect(() => {
    getUserChat(users?.[0]);
    setCurrentAvatar(avatars?.users?.[0].avatarId)
  }, [])

  const onUsersSeacrh = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredUsers = users?.filter(user =>
    user.alias.toLowerCase().includes(searchQuery.toLowerCase())
  );
  

  return (
    <div className='msgnr_suggestion_container'>
      {!isUserAvailable && isUser ?
        <div className='no_user'>
          <span>{t('messenger.noresultfound')}</span>
          <p>{t('messenger.nouseravailable')}</p>
        </div> : 
        <>
          {isFilterUser ? 
            <div className='no_user'>
              <span>{t('messenger.noresultfound')}</span>
              <p>{t('messenger.nouserfilter')}</p>
            </div>:
            <div className="m_suggestion_search">
              <InputGroup>
                <InputGroup.Text id="search_suggestions">
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-search" viewBox="0 0 16 16">
                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001q.044.06.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1 1 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0" />
                  </svg>
                </InputGroup.Text>
                <Form.Control
                  id="search_suggestions"
                  type="text"
                  placeholder={t('messenger.searchsuggestion')}
                  value={searchQuery}
                  onChange={onUsersSeacrh}
                />
              </InputGroup>
              <div className='m_suggestion_list'>
                {filteredUsers?.map((user, index) => (
                  <div
                    key={`${user?.userId}-${index}`}
                    // className='list_container'
                    className={`list_container ${(user.userId) == (currentUser && currentUser.userId) ? 'active_user' : ""}`}
                    onClick={() => { if (!isFileLoader && !isFileResponseReceived) getUserChat(user); }}
                    id={user?.userId}
                    dis
                  >
                    <div className='m_suggestion_img'>
                      <img
                        src={
                          avatars?.[user?.avatarId]
                            ? avatars?.[user?.avatarId]
                            : (user?.categories == null && user?.contactListUser)
                              ? kontactLogo
                              : UserIcon
                        } />
                      <p>{user.alias}</p>
                    </div>
                    <div>
                      <ul className='m_suggestion_icons'>
                        {user.categories?.map((category, index) => (
                          <React.Fragment key={index}>
                            {category.value === 'Friendship' && <li><img src={friendshipIcon} alt='Friendship' /></li>}
                            {category.value === 'Partnership' && <li><img src={partnershipIcon} alt='Partnership' /></li>}
                          </React.Fragment>
                        ))}
                        {user?.sex?.value == 'Male' ? <li>
                          <img src={maleSvg} />
                        </li> : null}
                        {user?.sex?.value == 'Female' ? <li>
                          <img src={femaleSvg} />
                        </li> : null}
                        {user?.sex?.value == 'Intersexual' ? <li>
                          <img src={intersex} />
                        </li> : null}
                        {user?.categories != null && user?.contactListUser ? <li>
                          <img src={kontactLogo} />
                        </li> : null}
                      </ul>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          }
        </>
        }
    </div>
  )
}

export default UsersList;