import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus, faEnvelope, faPen, faChevronCircleRight, faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import community_icon_1 from '../../assets/images/community1.jpg';
import community_icon_2 from '../../assets/images/community2.jpg';
import community_icon_3 from '../../assets/images/community3.jpg';
import community_icon_4 from '../../assets/images/community4.jpg';
import suggest_btn from '../../assets/images/suggest_btn.png';
import GlkHeader from '../../components/Header/GlkHeader.js';
import membershipIcon from '../../assets/images/mem_img.png';
import volumeIcon from '../../assets/images/volume_icon.png';
import userIcon from '../../assets/images/user-icon01.png';
import mem_img from '../../assets/images/mem_img.png';
import Person1 from '../../assets/images/personality.png';
import Person2 from '../../assets/images/pairship.png';
import Person3 from '../../assets/images/community.png';
import Person4 from '../../assets/images/friendship.png';
import thumbUp from '../../assets/images/perfect.png';
import CardGroup from 'react-bootstrap/CardGroup';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Menubar from './menuBar.js';
import './home.css';
import News from './news.js';
import Footprint from './footprint.js';
import IncompleteQuestionnaire from './incompleteQuestionnaire.js';
import {getAvatar, getAudio, getHomeDetails, getIncompleteQuetions, incompleteQuestionnaires, downloadPdf, uploadAvatar, uploadAudio} from './homeServices.js';
import AudioButton from "./audiobutton.js";
import { useEffect, useState, useRef } from 'react';
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';

function Home() {
  const [homeDetails, setHomeDetails] = useState(null);
  const [incompleteQueData, setIncompleteQueData] = useState(null);
  const [loadQuestionnaire, setLoadQuestionnaire] = useState(null);
  const [showQuestionnairePopup, setShowQuestionnairePopup] = useState(false);
  const [uploadType, setUploadType] = useState(null);
  const [refreshIncompleteQ, setRefreshIncompleteQ] = useState(false);
  const [refreshNews, setNewsRefresh] = useState(false);
	
  const [partnershipAvatar, setPartnershipAvatar] = useState(null);
  const [friendshipAvatar, setFriendshipAvatar] = useState(null);
  const [partnershipAudio, setPartnershipAudio] = useState(null);
  const [friendshipAudio, setFriendshipAudio] = useState(null);
		
  let {
    loginData: {
      accessToken
    }, 
  } = useContext(LoginContext);
	
  let navigate = useNavigate();
	
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const inputImageSelect = useRef();
  
  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
    getHomeDetails(token_lang).then((res) => {
      if (res?.status === 200) {
        setHomeDetails(res.data);    
        setNewsRefresh(false);    
      }
    })    
  }, [currentLang, accessToken, refreshNews])

  useEffect(() => {
    getIncompleteQuetions(token_lang).then((response) => {
      if(response?.status === 200){
        setIncompleteQueData(response.data);
        setRefreshIncompleteQ(false);
      }
    }) 
  },[currentLang, accessToken, refreshIncompleteQ]);

  const getQuestionnaire = (id, questionsId) => {
    const data = {
      id: id,
      questionsId: questionsId,
      language: currentLang,
      accessToken: accessToken
    }
    incompleteQuestionnaires(data).then((result) => {
      setLoadQuestionnaire(result.data);
      setShowQuestionnairePopup(true);
    })
  }


  const closeQuestionnairePopup = () => setShowQuestionnairePopup(false)

  const openPdf = async (type) => {
    const data = {
      type: type,
      language: currentLang,
      accessToken: accessToken
    }
    const response = await downloadPdf(data)
    const blob = await response.blob();
    const file = new Blob([blob], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(file);
    window.open(fileURL);
    window.URL.revokeObjectURL(fileURL)
  }

  const UploadFile = async (e) => {
    e.preventDefault();
    const fileData = e.target.files[0];
    let formData = new FormData();
    formData.append('file', fileData, fileData.name);
    const category = uploadType.includes('partnership') ? "PARTNERSHIP" : "FRIENDSHIP";
      const data = {
        form: formData,
        category: category,
        language: currentLang,
        accessToken: accessToken
      }
    if (uploadType.includes("avatar")) {
      const response = await uploadAvatar(data);
      setUploadType(null);
			getIncompleteQuetions(token_lang).then((response) => {
				if(response?.status === 200){
					setIncompleteQueData(response.data);
					setRefreshIncompleteQ({setRefreshIncompleteQ});
				}
			}); 
			setAvatar();
    }
    else if (uploadType.includes("audio")) {
      const audioResponse = await uploadAudio(data);
      setUploadType(null)
    }
  }

  const cardData = [
    {
      header: t('member.avtars.partnership'),
      icon: userIcon,
      info: t('member.avtars.profileMissing'),
      text: t('member.avtars.uploadPicture'),
      type: "partnership.avatar",
      isMissingAvtarOrAudio: incompleteQueData?.missingAvatarCategories.find((item) => item ==='PARTNERSHIP')
    },
    {
      header: t('member.avtars.friendship'),
      icon: userIcon,
      info: t('member.avtars.profileMissing'),
      text: t('member.avtars.uploadPicture'),
      type: "friendship.avatar",
      isMissingAvtarOrAudio: incompleteQueData?.missingAvatarCategories.find((item) => item === 'FRIENDSHIP')
    },
    {
      header: t('member.avtars.partnership'),
      icon: volumeIcon,
      info: t('member.avtars.missingAudio'),
      text: t('member.avtars.addAudioP'),
      type: "partnership.audio",
      isMissingAvtarOrAudio:incompleteQueData?.missingAudioCategories.find((item) => item ==='PARTNERSHIP')
    },
    {
      header: t('member.avtars.friendship'),
      icon: volumeIcon,
      info: t('member.avtars.missingAudio'),
      text: t('member.avtars.addAudioF'),
      type: "friendship.audio",
      isMissingAvtarOrAudio: incompleteQueData?.missingAudioCategories.find((item) => item === 'FRIENDSHIP')
    }
  ];
		
	const setAvatar = async () => {
		try {
			if (incompleteQueData?.partnershipAvatar?.id && incompleteQueData.partnershipAvatar.id !== null) {
					const data = {
							accessToken: accessToken,
							language: currentLang,
							avatarId: incompleteQueData.partnershipAvatar.id
					}
					getAvatar(data).then((response) => {
							processImage(response,'partnership')
					})
			}
			if (incompleteQueData?.friendshipAvatar?.id && incompleteQueData.friendshipAvatar.id !== null) {
					const data = {
							accessToken: accessToken,
							language: currentLang,
							avatarId: incompleteQueData.friendshipAvatar.id
					}
					getAvatar(data).then((response) => {
							processImage(response,'friendship')
					})
			}
		} catch (err) {
			console.error(err);
		}
	};

	useEffect(() => {
		setAvatar();
	}, [incompleteQueData]);

	const processImage = async (response,type) => {
			if (response.status === 200) {
					const blob = await response.blob();
					const fileURL = URL.createObjectURL(blob);
					if (type=='partnership') {
						setPartnershipAvatar(fileURL)
					} else {
						setFriendshipAvatar(fileURL)
					}
			}
	}

	useEffect(() => {
			if (incompleteQueData?.partnershipAudio?.id && incompleteQueData.partnershipAudio.id !== null) {
					const data = {
							accessToken: accessToken,
							language: currentLang,
							audioId: incompleteQueData.partnershipAudio.id
					}
					getAudio(data).then((response) => {
							processAudio(response,'partnership')
					})
			}
			if (incompleteQueData?.friendshipAudio?.id && incompleteQueData.friendshipAudio.id !== null) {
					const data = {
							accessToken: accessToken,
							language: currentLang,
							audioId: incompleteQueData.friendshipAudio.id
					}
					getAudio(data).then((response) => {
							processAudio(response,'friendship')
					})
			}
	}, [incompleteQueData])

	const processAudio = async (response,type) => {
			if (response.status === 200) {
					const blob = await response.blob();
					const fileURL = URL.createObjectURL(blob);
					if (type=='partnership') {
						setPartnershipAudio(fileURL)
					} else {
						setFriendshipAudio(fileURL)
					}
			}
	}  

	function navigateToPage(path) {
		const pathUrl = {
			suggestions: '/suggestions/suggestions',
			messenger: '/suggestions/messenger',
		};

		navigate(pathUrl[path]);
	}
	
  return (
    <>
      <GlkHeader />
			<main className="main_wrapper">
			
				<div className="membership_area">
				
					<div className="home_banner">
						<div className="home_banner_content">
							<h1>{t('member.homebanner.makeImpact')}</h1>
							<h2>{t('member.homebanner.heading')}</h2>
							<p>{t('member.homebanner.text')}</p>
							<p>{t('member.homebanner.text2')}</p>
							<p><Trans i18nKey="member.homebanner.text3" 
							components={{ faqLink: <a target='_blank' rel='noreferrer noopener' href='https://www.gleichklang.de/faq/'>link</a> }}/></p>
							<div className='home_btns'>
								<Button onClick={() => navigateToPage('suggestions')} className='suggest_btn' variant="success">
									<FontAwesomeIcon icon={faUserPlus} />
									{t('member.menubar.suggestions')}
									<Badge bg="warning">{homeDetails?.suggestions}</Badge>
									<span className="visually-hidden">{t('member.header.unread_suggestions')}</span>
								</Button>

								<Button onClick={() => navigateToPage('messenger')} className='msg_btn' variant="primary">
									<FontAwesomeIcon icon={faEnvelope} />
									{t('member.menubar.messages')}
									<Badge bg="warning">{homeDetails?.messages}</Badge>
									<span className="visually-hidden">{t('member.header.unread_messages')}</span>
								</Button>
							</div>
						</div>
					</div>

					<div className="news_footprint_container">
						{homeDetails?.news?.length ? 
						<News
						 news={homeDetails?.news} 
						 setNewsRefresh={setNewsRefresh}
						 /> : 
						 <div className="news_section">
								<h3>{t('member.news.newsheading')}</h3>
								<p className="noContent">{t('member.news.noUnread')}</p>
							</div>
						 }
						{
							homeDetails?.footPrints?.length ? <Footprint footprint={homeDetails?.footPrints} /> : 
							<div className="footprint_section">
								<h3>{t('member.footprint.footprint')}</h3>
								<p className="noContent">{t('member.footprint.noFootprint')}</p>
							</div>
							}
					</div>
					
					<div className="membership_section">
						<div className="membership_texts">
							<h3> {t('member.yourmembership.membership')} </h3>
							<p aria-label="button You have mail from us!">
								<a>{t('member.yourmembership.maildesc')}</a>
							</p>
							<p aria-label="button Your subscription will run for 4 months!">
								<a>{t('member.yourmembership.subscription')}</a>
							</p>
						</div>
						<div className="membershipIcon">
							<img src={membershipIcon} alt={t('member.yourmembership.membership')} />
						</div>
					</div>
				
				</div>
				
				<div className="home_profile">
					<section className="home_profile_section">
						<div className="home_profile_container">
								<div className="your_profile_texts">
									<h3>{t('member.yourprofile.yourprofile')}</h3>
									{
										incompleteQueData?.questionnaires && incompleteQueData.questionnaires !== null ?
										<div className="text_unans">
											<p>{t('member.yourprofile.text')}</p>
										</div> 
									: <div className="text_ans">
											<img src={thumbUp} alt="Thumb up" />
											<h2>{t('member.yourprofile.perfect')}</h2>
											<p>{t('member.yourprofile.allFilled')}</p>
										</div> 
									}									
									{incompleteQueData?.questionnaires?.map((data, index) => (
										<p 
										tabIndex='0'
										key={index}  
										className="label"
										onClick={() => getQuestionnaire(data.id, data.unAnsweredQuestions)}
										>{data.title} <FontAwesomeIcon icon={faPen} /></p>
									))}
								</div>
						</div>
						<div className='home_profile_popup'>
							{showQuestionnairePopup ?
								<IncompleteQuestionnaire
									questionnaire={loadQuestionnaire}
									show={showQuestionnairePopup}
									handleClose={closeQuestionnairePopup}
									setRefreshIncompleteQ={setRefreshIncompleteQ}
								/>
								: null}
						</div>
					</section>

					<div className='card_container'>
						<h3>{t('member.yourmembership.avatarsAndAudio')}</h3>
						{cardData.map((card, index) => (
							card.isMissingAvtarOrAudio ? 
							card.type == 'partnership.avatar' || card.type == 'friendship.avatar' ?
							<Button className='card_btn' key={index}>
								<CardGroup>
									<Card
										key={index}
										text={'Success'.toLowerCase() === 'light' ? 'dark' : 'white'}
										className="mb-2 card_for_missings"
										onClick={() => { 
											setUploadType(card.type)
											inputImageSelect.current.click()}}
									>
										<Card.Header className='missing_header'><FontAwesomeIcon icon={faTimesCircle} />{card.header}</Card.Header>
										<Card.Body className='card_body'>
											<Card.Title className='cards_icon'>
												<img src={card.icon} alt="Card Icon" />
											</Card.Title>
											<Card.Text className='card_info'>{card.info}</Card.Text>
											<Card.Text className='button_style missing_button'>{card.text}</Card.Text>
										</Card.Body>
									</Card>
								</CardGroup>
							</Button> :
							<Button className='card_btn' key={index}>
								<CardGroup>
									<Card
										key={index}
										text={'Success'.toLowerCase() === 'light' ? 'dark' : 'white'}
										className="mb-2 card_for_missings"
										onClick={() => {
										navigate('/personal-details/audio')}}
									>
										<Card.Header className='missing_header'><FontAwesomeIcon icon={faTimesCircle} />{card.header}</Card.Header>
										<Card.Body className='card_body'>
											<Card.Title className='cards_icon'>
												<img src={card.icon} alt="Card Icon" />
											</Card.Title>
											<Card.Text className='card_info'>{card.info}</Card.Text>
											<Card.Text className='button_style missing_button'>{card.text}</Card.Text>
										</Card.Body>
									</Card>
								</CardGroup>
							</Button> 
							: card.type == 'partnership.avatar' || card.type == 'friendship.avatar' ?
							<Button className='card_btn available_avatar' key={index}>
								<CardGroup>
									<Card
										key={index}
										text={'Success'.toLowerCase() === 'light' ? 'dark' : 'white'}
										className="mb-2 card_for_missings"
										onClick={() => {
											setUploadType(card.type)
											inputImageSelect.current.click()}}
									>
										<Card.Header><FontAwesomeIcon icon={faCheckCircle} />{card.header}</Card.Header>
										<Card.Body className='card_body'>
											<Card.Title className='cards_icon'>
											{ card.type == 'partnership.avatar' ?
												<img src={partnershipAvatar && partnershipAvatar !== null ? partnershipAvatar : userIcon} alt={t('member.avtars.avatarForPartnership')} /> : 
												<img src={friendshipAvatar && friendshipAvatar !== null ? friendshipAvatar : userIcon} alt={t('member.avtars.avatarForFriendship')} />
											}
											</Card.Title>
											<Card.Text className='button_style'>{t('member.avtars.changePicture')}</Card.Text>
										</Card.Body>
									</Card>
								</CardGroup>
							</Button> : card.type == 'partnership.audio' || card.type == 'friendship.audio' ?
							<Button className='card_btn available_audio' key={index}>
								<CardGroup>
									<Card
										key={index}
										text={'Success'.toLowerCase() === 'light' ? 'dark' : 'white'}
										className="mb-2 card_for_missings"
										onClick={() => {
											navigate('/personal-details/audio');
											}}
									>
										<Card.Header><FontAwesomeIcon icon={faCheckCircle} />{card.header}</Card.Header>
										<Card.Body className='card_body'>										
											<Card.Title className='cards_icon'>
											{ card.type == 'partnership.audio' ?
												<AudioButton url={partnershipAudio} /> : 
												<AudioButton url={friendshipAudio} />
											}
											</Card.Title>
											<Card.Text className='button_style'>{t('member.avtars.changeAudio')}</Card.Text>
										</Card.Body>
									</Card>
								</CardGroup>
							</Button> : null  
						))}
						<div className='card_input'>
							<input id="file-upload"
								ref={inputImageSelect}
								name="audio-and-image-upload" 
								aria-label={t('member.avtars.fileUpload')} 
								onChange={(e) => UploadFile(e)}
								type="file"
								/>
						</div>
						
					</div>
				</div>

				<div className="analyses_section">
					<h3> {t('member.analyses.analyses')} </h3>
					<p>  {t('member.analyses.description')}  </p>
					<div className="download_questionair_pdf">
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('personal')}}
							tabIndex="0" 
							aria-label="Download personality profile PDF">
							<div>
								<img src={Person1} alt="Personality profile link" />
							</div>
							<p>{t('member.analyses.personalityProfile')}</p>
						</div>
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('partnership')}}
							tabIndex="0" 
							aria-label="Download partnership profile PDF">
							<div>
								<img src={Person2} alt="Partnership profile link" />
							</div>
							<p>{t('member.analyses.partnershipProfile')}</p>
						</div>
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('companyprofile')}}
							tabIndex="0" 
							aria-label="Download social profile PDF">
							<div>
								<img src={Person3} alt="Social profile link" />
							</div>
							<p>{t('member.analyses.socialProfile')}</p>
						</div>
						<div className="profile_pdf_sec" 
							onClick={() => {openPdf('friendship')}}
							tabIndex="0" 
							aria-label="Download friendship profile PDF">
							<div>
								<img src={Person4} alt="Friendship profile link" />
							</div>
							<p>{t('member.analyses.friendshipProfile')}</p>
						</div>
					</div>
				</div>

				<div className="option_section">
					<h3> {t('member.option.option')} </h3>
					<div className="option_texts">
						<a href="https://www.gleichklang.de/optimierung/" target="_blank">{t('member.option.requestsupport')}</a>
						<p>{t('member.option.requestsupportText')}</p>
					</div>
					<div className="option_texts">
						<a href="/member/member/membership/subscription#upgrades">
						{t('member.option.forlife')}
						</a>
						<p> {t('member.option.forlifeText')} </p>
					</div>
					<div className="option_texts">
						<a href="https://de.research.net/r/effekte" target="_blank">{t('member.option.opportunities')}</a>
						<p> {t('member.option.opportunitiesText')} </p>
					</div>
					<div className="option_texts">
						<a href="https://www.gfgebauer.de/#coaching" target="_blank">{t('member.option.learn')}</a>
						<p> {t('member.option.learnText')} </p>
					</div>
					<div className="option_texts">
						<a href="https://gleichklang-studien.de/" target="_blank">{t('member.option.yourself')}</a>
						<p> {t('member.option.yourselfText')} </p>
					</div>
				</div>

				<div className="community_sec">
					<h3>{t('member.community.community')} </h3>
					<p>{t('member.community.communityDesc')}</p>
					<div className="community_items">
						<div className="community_box">
							<img src={community_icon_1} alt={t('member.community.imageFor')+" "+t('member.community.order')}/>
								<a target="_blank" href="https://www.gleichklang.de/flyer/">{t('member.community.order')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
						<div className="community_box">
							<img src={community_icon_2} alt={t('member.community.imageFor')+" "+t('member.community.recommend')}/>
								<a target="_blank" href="https://www.gleichklang.de/werbemoeglichkeit-empfehlen/">{t('member.community.recommend')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
						<div className="community_box">
							<img src={community_icon_3} alt={t('member.community.imageFor')+" "+t('member.community.email')}/>
									<a target="_blank" href="https://www.gleichklang.de/freunde-zu-gleichklang-einladen/">{t('member.community.email')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
						<div className="community_box">
							<img src={community_icon_4} alt={t('member.community.imageFor')+" "+t('member.community.mail')}/>
									<a target="_blank" href="https://www.gleichklang.de/freunde-zu-gleichklang-einladen/#perpost">{t('member.community.mail')} <FontAwesomeIcon icon={faChevronCircleRight} /></a>
						</div>
					</div>
				</div>
					
			</main>

    </>
  );
}

export default Home;