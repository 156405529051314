import { useEffect, useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import Modal from 'react-bootstrap/Modal';
import { useTranslation } from "react-i18next";

function ImageCarousel(props) {
    const [index, setIndex] = useState(0);
    const [slide, setSlide] = useState(false);
    const { t } = useTranslation(); 

    useEffect(() => {
        if (props.show !== null) {
            setIndex(props.show);
            setSlide(false);
            setTimeout(() => setSlide(true), 0); // Enable slide after initial render
        }
    }, [props.show]);

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    return (
        <Modal 
            className='image_carousel' 
            fullscreen
            show={props.show !== null} 
            onHide={() => props.setShow(null)}
        >
            <Modal.Header closeButton />
            <Modal.Body>
                <Carousel
                    className='carousal carousel_wrap'
                    activeIndex={index}
                    onSelect={handleSelect}
                    interval={null}
                    slide={slide} 
                >
                    {props.images?.map((image, idx) => (
                        <Carousel.Item key={image.image.name} className='carousel_image'>
                            <img src={image.imageUrl}    alt={t('member.gallery.checkimg', { index: idx + 1 })}/>
                        </Carousel.Item>
                    ))}
                </Carousel>
            </Modal.Body>
        </Modal>
    );
}

export default ImageCarousel;
