import React, { useEffect, useState, useContext } from 'react'
import payment_icons from '../../assets/images/payrexx_icons.png';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { getPaymentDetails, getAdressForPayment } from './registrationProcessService';
import { LoginContext } from '../../context/loginContext';
import { getPaymentStatus } from '../pendingPayment/paymentServices';
import usePreventBack from './preventBack/preventBack';

function PaymentResult(props) {
  const [prepaymentData, setPrepaymentData] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState();
  const [showPayrexx, setShowPayrexx] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const { t, i18n } = useTranslation();
  usePreventBack();
  const currentLang = i18n.language;
  let {
    loginData: {
      memberDetails,
      accessToken
    },
  } = useContext(LoginContext);
  const token_lang = {
    language: currentLang,
    accessToken
  }

  useEffect(() => {
		setTimeout(() => {
			window.scrollTo(0, 0);
		}, 0);
    getPaymentDetails(token_lang).then((result) => {
      if (result?.status === 200) {
        setPrepaymentData(result?.data)
      }

    })
  }, [])

  useEffect(() => {
    getAdressForPayment(accessToken).then((response) => {
      if (response?.status === 200) {
        setFirstName(response?.data?.firstName);
        setLastName(response?.data?.lastName);
        setEmail(response?.data?.email);
      }
    })
  }, [])

  const showHidePayrexx = (state) => {
    setShowPayrexx(state);
  }  	

  let iFrame = '';
  const listenToPayframe = () => {

    setTimeout(function(){

      //iFrame = $('#payrexx-embed');
      iFrame = document.getElementById('payrexx-embed');
      
      if (typeof iFrame !== 'undefined') {

        console.log('iframe loaded');

        var transaction = null;
        var lastPostMessageHeight = 0;

        var updateIframeHeight = function() {
          var height = lastPostMessageHeight;
          
          // This can be individually changed.
          // Currently the full height is only applied for big displays (> 590px in width)
          if (window.innerWidth <= 590) {
            iFrame.style.cssText = "height: 100%";
          } else if (height) {
            iFrame.style.cssText = "height: "+height +"px";
          }
        };

        var postMessage = function(e) {
          if (typeof e.data === 'string') {
            try {
              var data = JSON.parse(e.data);
            } catch (e) {}

            if (data && data.payrexx) {            
              console.log('data.payrexx');             
              console.log(data.payrexx);
              
              Object.keys(data.payrexx).forEach(function (item) {

                switch (item) {
                  case 'height':
                    lastPostMessageHeight = parseInt(data.payrexx.height);
                    updateIframeHeight();
                    break;
                  case 'transaction':
                    if (typeof data.payrexx.transaction === 'object') {

                      transaction = data.payrexx.transaction;

                      console.log('new transaction triggered');
                      console.log(transaction);

                      showHidePayrexx(false);
                      
                      let paymentReference = prepaymentData?.externalReferenceId;  
                      getPaymentStatus(paymentReference).then((result) => {
                          if (result?.status === 200) {
                              setPaymentStatus(result?.data)
                          }
                      })
                      console.log(paymentStatus);
                      
                      setTimeout(() => {
                        window.scrollTo(0, 0);
                      }, 0);

                    }
                    break;
                }
              });
            }
          }

        }

        window.addEventListener('message', postMessage, false);

        iFrame.addEventListener("load", function() {
          iFrame.contentWindow.postMessage(JSON.stringify({origin: window.location.origin}), iFrame.getAttribute('src'));
          //window.resize(updateIframeHeight);
          updateIframeHeight();
        });

      };
      
    },1000);

  }  

  
  let paymentReference = prepaymentData?.externalReferenceId;
  console.log(paymentReference);
  useEffect(() => {
      getPaymentStatus(paymentReference).then((result) => {
          if (result?.status === 200) {
              setPaymentStatus(result?.data)
          }
      })
  }, [prepaymentData])
  console.log(paymentStatus);
  
  

  const amountText = t('registration.prepayment.ptta')
    .replace(/\$\{amount\}/g, `<strong class="prepayment_highlight">${prepaymentData?.amount} ${prepaymentData?.currency}</strong>`)
    .replace(/\$\{externalReferenceId\}/g, `<strong class="prepayment_highlight">${prepaymentData?.externalReferenceId}</strong>`)
    .replace(/\$\{payment_skip\}/g, `<a href="#" onClick="document.getElementById('button_payment_next').click();return false;" class="prepayment_link">${t('registration.prepayment.paymentSkip')}</a>`);
  return (
    <div>
      {!showPayrexx ?
      <div className='from_control questionair_container payment_result'>
        {!prepaymentData ? <div>loading...</div> :      
          <div>            
            {props?.paymentMethods === 'DIRECT_DEBIT' || prepaymentData?.hasPendingPayment === false 
              || prepaymentData?.prepayment === false || paymentStatus?.data?.payment_status == "confirmed" 
              || paymentStatus?.data?.payment_status == "declined"
                ?
              <div>{prepaymentData?.hasPendingPayment === false || paymentStatus?.data?.payment_status == "confirmed"
                  ? <div className="looking_for">
                      <div className="questionnaireHeader">
                        <h3 className="lineH3 text-center"><strong>{t('registration.payment.thanks')}</strong></h3>
                        <h4>{t('registration.payment.thanksGo')}</h4>
                        <p>{t('registration.payment.thanksDescription')}</p>
                      </div>
                    </div>
                  : <div className="looking_for">
                      <div className="questionnaireHeader">
                        <h3 className="lineH3 text-center"><strong>{t('registration.payment.failed')}</strong></h3>
                        <h4>{t('registration.payment.completeReg')}</h4>
                        <p>{t('registration.payment.failedDesc')}</p>
                      </div>
                  </div>}
              </div> 
              :
              <div>
                <div className="looking_for">
                  <div className="questionnaireHeader">
                    <h3 className="lineH3 text-center"><strong>{t('registration.pendingPayment.paymentDetails')}</strong></h3>
                    <h4>{t('registration.payment.thanksGo')}</h4>
                    <p dangerouslySetInnerHTML={{ __html: amountText }}></p>
                  </div>
                </div>
                <div className='from_control questionair_container payment_methods'>
                  <div className="prepayment_details">
                    <h2>{t('registration.prepayment.bankTransfer')}</h2>
                    <div className="bank_name">
                      <p>GLS Bank </p>
                    </div>
                    <div className="bank_info">
                      <p>
                          <span><b>{t('registration.prepayment.accountOwner')}</b></span>
                          <span>Gleichklang Limited</span>
                      </p>
                      <p>
                          <span><b>IBAN:</b></span>
                          <span>{prepaymentData?.prePaymentBankAccounts[0]?.iban}</span>
                      </p>
                      <p>
                          <span><b>BIC:</b></span>
                          <span>{prepaymentData?.prePaymentBankAccounts[0]?.bic}</span>
                      </p>
                      <p>
                          <span><b>{t('registration.prepayment.amount')}</b></span>
                          <span>{prepaymentData?.amount} {prepaymentData?.currency}</span>
                      </p>
                      <p>
                          <span><b>{t('registration.prepayment.paymentReference')}</b></span>
                          <span>{prepaymentData?.externalReferenceId}</span>
                      </p>
                    </div>
                  </div>
                  <div className="onlinepayment_details">
                      <h2>{t('registration.prepayment.payOnline')}</h2>
                      <div className="bank_name">
                          <p>{t('registration.prepayment.secureFast')}</p>
                      </div>
                      <p>{t('registration.prepayment.onlinepaymentDescription')}</p>                  
                      <Button
                        className='mx-5'
                        variant="primary" 
                        id="button_payrexx"
                        onClick={() => 
                          {showHidePayrexx(true);
                          listenToPayframe();
                        }}
                      >
                        {t('registration.prepayment.payNow')}
                      </Button>
                      <img src={payment_icons} alt={t('registration.prepayment.payOnline')} />
                  </div>
                </div>
              </div>
            } 
          </div>
        }
        <div className="mt-3 step_one_next">
          <Button
            className='mx-5'
            variant="primary"
            onClick={() => props.handleNextQustionaire()}
          >
            {t('registration.buttons.next')}
          </Button>
        </div>
      </div> : 
      <div className="payrexx_container">
        <div className="payrexx_header step_three looking_for payment_methods">
          <div>
            <h3 className="lineH3"><strong>{t('registration.prepayment.payNow')}</strong></h3>
            <p>Please pay the amount of <strong>{prepaymentData?.amount} {prepaymentData?.currency}</strong> using the payment form below. To select a payment provider, please click on the desired icon first. The payment will be done with the reference <strong>{prepaymentData?.externalReferenceId}</strong>.</p>
          </div>
          <Button
              className='mx-5'
              variant="primary" 
              id="button_payrexx_back"
              onClick={() => showHidePayrexx(false)}
            >
              {t('suggestions.back')}
          </Button>
        </div>
        <div className="payrexx_frame">
          {/*<iframe src={"https://gleichklang.payrexx.com/pay?tid=f4ab5d56&appview=1&contact_forename="+firstName+"&contact_surname="+lastName+"&contact_email="+email+"&alias="+memberDetails?.alias+"&custom_referenz="+prepaymentData?.externalReferenceId+"&invoice_amount="+prepaymentData?.amount} allow="payment *" width="100%" height="1000" allowtransparency="true" id="payrexx-embed"></iframe>*/}
          <iframe src={"https://glktest.payrexx.com/pay?tid=7fe0e55e&appview=1&contact_forename="+firstName+"&contact_surname="+lastName+"&contact_email="+email+"&custom_alias="+memberDetails?.alias+"&custom_referenz="+prepaymentData?.externalReferenceId+"&invoice_amount="+prepaymentData?.amount} allow="payment *" width="100%" height="1000" allowtransparency="true" id="payrexx-embed"></iframe>
        </div>
      </div>
      }
    </div>
  )
}

export default PaymentResult;