import React, { useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { ModalBody, Alert, Modal, Form } from 'react-bootstrap';
import { LoginContext } from '../../context/loginContext';
import UserIcon from '../../assets/images/user-icon01.png';
import './photogallery.css';
import Spinner from '../../components/spinner';

function ShareOrMoveImage(props) {
    const [galleryData, setGalleryData] = useState()
    const { errorMsg, isError, setIsError, setSpaceMessageForTargetGallery, spaceMessageForTargetGallery, galleries } = props;    

    const onClose = () => {
        props.setShow(false);
        props.setSelectTargetGallery([]);
    }

    const { t, i18n } = useTranslation();
    const { loginData: { accessToken } } = useContext(LoginContext);

    useEffect(() => {
        setGalleryData(galleries);
    }, []);

    const base64Image = (image) => {
        return `data:image/jpeg;base64,${image}`
    };

    const onGallerySelect = (galleryId) => {
        props.setSelectTargetGallery(prevSelectedImages => {
            if (prevSelectedImages.includes(galleryId)) {
                return prevSelectedImages.filter(id => id !== galleryId);
            } else {
                return [...prevSelectedImages, galleryId];
            }
        });
    };

    return (
        <Modal className='sharegallery' size='lg' show={props.show} onHide={() => onClose()}>
            <Modal.Header closeButton>
                <Modal.Title></Modal.Title>
            </Modal.Header>
            <ModalBody>
                {galleryData ? (
                    <div>
                        <div className="status_myprofile">
                            <h3> {props.copyOrMoveId == 'move' ? t('member.gallery.moveto') : t('member.gallery.copyto')}</h3>
                        </div>
                        <div className='galleries_container'>
                            {galleryData?.map((gallery, index) =>
                                <div key={gallery.id} className='g_container'>
                                    <div className='gallery_imgs'>
                                        <div className='image_select'>
                                            <Form.Group className="" >
                                                <Form.Check
                                                    type="checkbox"
                                                    checked={props.selectTargetGallery.includes(gallery.id)}
                                                    onChange={() => onGallerySelect(gallery.id)}
                                                    className='msg_pre_checkbox'
                                                   aria-label='image_select'
                                                />
                                            </Form.Group>
                                        </div>
                                        <img className='image' src={gallery?.thumbnails?.[0] && gallery?.thumbnails?.[0].thumbnail ? base64Image(gallery?.thumbnails?.[0].thumbnail) : UserIcon} alt='' />

                                    </div>
                                    <div className='g_name_container'>
                                        <span className='galleryname'>{gallery?.name.length > 20 ? gallery?.name.substring(0, 20) : gallery?.name}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        {spaceMessageForTargetGallery.length > 0 ? <Alert className='alert_questionaire' show={spaceMessageForTargetGallery.length>0} dismissible onClose={() => setSpaceMessageForTargetGallery([])}
                            variant="danger" id={`err_1`}>{spaceMessageForTargetGallery?.map((error) => (<p>{error}</p>))}</Alert> : null}
                        {isError ? <Alert
                            className='member_address'
                            variant="danger"
                            id='main_alert'
                            dismissible
                            onClose={() => setIsError(false)}
                        >
                            <p>{errorMsg.errorMsg}</p>
                            {errorMsg.errors?.length ?  errorMsg.errors?.map((error) => (<p>{error}</p>)) : null}
                        </Alert> : null}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Button className='mb-3 mt-1' variant="primary" onClick={() => props.moveOrCopyOrDeleteImage(props.selectedImages, props.copyOrMoveId)}>
                                {t('mymembership.subscription.save')}
                            </Button>
                        </div>
                    </div>
                ) :
                    <div className='gallery_spinner'>
                        <Spinner />
                    </div>
                }
            </ModalBody>
        </Modal>
    );
}

export default ShareOrMoveImage;
