import React, { useContext, useEffect, useState, useRef } from 'react';
import { Modal, ModalBody, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getAttachment, getReportedMessage } from './membershipServices';
import { LoginContext } from '../../context/loginContext';
import heic2any from 'heic2any';
import WriteMessageToGleichklang from './writeMessageToGleichklang';
import Alert from 'react-bootstrap/Alert';
import { Spinner } from 'react-bootstrap';

function SupportCoversation(props) {
    const { show, close, messages } = props;
    const [attachments, setAttachments] = useState([]);
    const [msgHistory, setMsgHistory] = useState();
    const [reply, setReply] = useState({ message: '', isReply: false })
    const [errorMessage, setErrorMessage] = useState('');
    const [isAttachment, setIsAttachment] = useState(false);
    const [replyToMessagesAttachmentErr, setReplyToMessagesAttachmentErr] = useState();

    let { loginData: { accessToken, memberDetails } } = useContext(LoginContext);
    const { t, i18n } = useTranslation();

    const token_lang = {
        language: i18n.language,
        accessToken
    };

    const inputImageSelect = useRef();

    const openReplyToMessagesAttachments = async(attachmentId) => {
        try {
            const fileUrl = await onGetImage(attachmentId);
            return fileUrl;           
        } catch (error) {
            console.error(`Error fetching image:`, error);
            const fileUrl = ''
            setReplyToMessagesAttachmentErr(t('mymembership.msgtoGk.fileurl'))
            return fileUrl;            
        }      
    };

    useEffect(() => {
        if (replyToMessagesAttachmentErr) {
            const timer = setTimeout(() => {
                setReplyToMessagesAttachmentErr('');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [replyToMessagesAttachmentErr]);

    const openAttachment = (fileUrl) => {
        if (!fileUrl) {
            setErrorMessage(t('mymembership.msgtoGk.fileurl'));
            return false;
        }
        setErrorMessage('');
        return true;
    };

    useEffect(() => {
        if (errorMessage) {
            const timer = setTimeout(() => {
                setErrorMessage('');
            }, 5000);

            return () => clearTimeout(timer);
        }
    }, [errorMessage]);

    useEffect(() => {
        if (messages) {
            messages?.messages?.attachments?.forEach(async (file) => {
                try {
                    const fileUrl = await onGetImage(file.id);
                    const fileId = file.id;
                    setAttachments((attachment) => [
                        ...attachment,
                        { fileId, fileUrl },
                    ]);
                } catch (error) {
                    console.error(`Error fetching image:`, error);
                    const fileUrl = ''
                    const fileId = file.id;
                    setAttachments((attachment) => [
                        ...attachment,
                        { fileId, fileUrl },
                    ]);
                }
            });
        }
        return () => {
            if (attachments) {
                URL.revokeObjectURL(attachments);
            }
        };
    }, [messages]);

    useEffect(() => {
        getReportedMessage(token_lang, messages?.messages?.id).then(async (response) => {
            if (response?.status == 200) {
                setMsgHistory(response?.data)
            }
        })
    }, [])

    const openReply = () => {
        setReply({ message: messages?.messages, history: msgHistory, isReply: true })
    }

    const closeReplyPopUp = () => {
        setReply({ message: '', isReply: false })
    }

    const onGetImage = (attachmentId) => {
        setIsAttachment(true);
        return new Promise((resolve, reject) => {
            getAttachment(token_lang, attachmentId).then(async (response) => {
                if (response.status === 200) {
                    const blob = await response.blob();
                    setIsAttachment(false);
                    let fileURL;
                    if (blob.type === 'image/heif') {
                        const jpegBlob = await heic2any({
                            blob: blob,
                            toType: 'image/jpeg',
                            quality: 1
                        });
                        fileURL = URL.createObjectURL(jpegBlob);
                    } else {
                        fileURL = URL.createObjectURL(blob);
                    }
                    resolve(fileURL);
                } else {
                    setIsAttachment(false);
                    reject(new Error("Failed to get image."));
                }
            }).catch(error => {
                reject(error);
            });
        });
    };
    
    return (
        <Modal className='openprofile' show={show} size='lg' onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t('mymembership.msgtoGk.msghistory')}</Modal.Title>
            </Modal.Header>
            <ModalBody>
                {messages?.messages?.type === 'INCOMING' ?
                    <div className='reply_btn'>
                        <Button onClick={() => openReply()} className='msg_btn' variant="primary">
                            {t('mymembership.msgtoGk.reply')}
                        </Button>
                    </div>
                    : null}
                {messages?.messages?.type === 'INCOMING' ?
                    <>
                        <div className='replytomsg_heading'>
                            <span>{memberDetails?.alias}</span>
                            <h3>{messages?.messages?.subject}</h3>
                            <span>{messages?.messages?.date}</span>
                        </div>
                        {msgHistory?.body ?
                            <span className='replytomsg_msg' dangerouslySetInnerHTML={{ __html: msgHistory?.body }}></span> :
                            <div className='msgtoglk_loader'>
                                <Spinner />
                            </div>
                        }
                           <div className="attachment_download">
                            <Alert className='alert_questionaire ms-2' show={errorMessage} dismissible onClose={() => setErrorMessage('')}
                                variant="danger" id={`err_1`}>{errorMessage && <p className="error-message">{errorMessage}</p>}
                            </Alert>
                            {msgHistory?.body && isAttachment ? 
                                <div>
                                    <p>File Loading...</p>
                                    <Spinner />
                                </div> :
                                <>
                                    {attachments?.map((file) => (
                                        <a
                                            key={file?.fileId}
                                            ref={inputImageSelect}
                                            href={file?.fileUrl}
                                            onClick={(e) => {
                                                if (!openAttachment(file?.fileUrl)) e.preventDefault();
                                            }}
                                            target="_blank"
                                            >
                                            <label className="attachments_labes btn btn-secondary">
                                                {messages?.messages?.attachments?.find(
                                                    (attachment) => attachment.id === file?.fileId
                                                )?.attachmentName}
                                            </label>
                                        </a>
                                    ))}
                                </>
                            }
                        </div>
                        {
                            msgHistory?.replyToMessages.map((replyMsg) => (<>
                                <div className='replytomsg_heading'>
                                    <span>{memberDetails?.alias}</span>
                                    <h3>{replyMsg?.subject}</h3>
                                    <span>{replyMsg?.date}</span>
                                </div>
                                <span className='replytomsg_history' dangerouslySetInnerHTML={{ __html: replyMsg?.body }}></span>
                                {replyToMessagesAttachmentErr && <Alert className='alert_questionaire' show={replyToMessagesAttachmentErr} dismissible onClose={() => setReplyToMessagesAttachmentErr('')}
                                    variant="danger" id={`err_1`}>{<p className="error-message">{replyToMessagesAttachmentErr}</p>}
                                </Alert>}
                                {replyMsg?.attachments.map((attachment) => (
                                    <button
                                        key={attachment.id}
                                        onClick={async (e) => {
                                            const fileUrl = await openReplyToMessagesAttachments(attachment.id);
                                            if (fileUrl) {
                                                window.open(fileUrl, '_blank', 'noopener,noreferrer');
                                            }
                                            else {
                                                e.preventDefault();
                                            }
                                        }}
                                        className="attachments_labes btn btn-secondary"
                                    >
                                        {attachment.attachmentName}
                                    </button>
                                ))}
                            </>))
                        }  
                    </> :
                    <>
                        <div className='replytomsg_heading'>
                            <span>{memberDetails?.alias}</span>
                            <h3>{messages?.messages?.subject}</h3>
                            <span>{messages?.messages?.date}</span>
                        </div>
                        {msgHistory?.body ?
                            <span className='replytomsg_history' dangerouslySetInnerHTML={{ __html: msgHistory?.body }}></span> :
                            <div className='msgtoglk_loader'>
                                <Spinner />
                            </div>
                            }
                        <div className="attachment_download">
                            <Alert className='alert_questionaire ms-2' show={errorMessage} dismissible onClose={() => setErrorMessage('')}
                                variant="danger" id={`err_1`}>{errorMessage && <p className="error-message">{errorMessage}</p>}
                            </Alert>
                            {msgHistory?.body && isAttachment ?
                                <div>
                                    <p>File Loading...</p>
                                    <Spinner />
                                </div>
                                :
                                <>
                                    {attachments?.map((file) => (
                                        <a
                                            key={file?.fileId}
                                            ref={inputImageSelect}
                                            href={file?.fileUrl}
                                            onClick={(e) => {
                                                if (!openAttachment(file?.fileUrl)) e.preventDefault();
                                            }}
                                            target="_blank"
                                            >
                                            <label className="attachments_labes btn btn-secondary">
                                                {messages?.messages?.attachments?.find(
                                                    (attachment) => attachment.id === file?.fileId
                                                )?.attachmentName}
                                            </label>
                                        </a>
                                    ))}
                                </>
                            }
                        </div>
                    </>
                }
                {reply?.isReply ?
                    <WriteMessageToGleichklang
                        show={reply?.isReply}
                        close={closeReplyPopUp}
                        reply={reply}
                    /> : null
                }
            </ModalBody>
        </Modal>
    );
}

export default SupportCoversation;
