import React, { useState, useRef, useEffect, useContext } from 'react'
import './questionair.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faCircleInfo,faMultiply
  } from '@fortawesome/free-solid-svg-icons';
import { questionaireAnswer, completeRegistration } from '../registrationProcess/registrationProcessService'
import { LoginContext } from '../../context/loginContext';
import { useTranslation } from "react-i18next";
import RegionalSearch from './regionalSearch';
import Alert from 'react-bootstrap/Alert';
import { AlertHeading } from 'react-bootstrap';
import DescriptionAlert from './descriptionAlert';
import {autoFocusByID, numberOnlyWithDigit } from '../commonMethods';
import usePreventBack from './preventBack/preventBack';
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import Select from 'react-select';
import {
    EditorState,
    convertToRaw,
    ContentState,
    convertFromHTML,
  } from "draft-js";


function Questionaire(props) {
    const [answers, setAnswers] = useState({})
    const [errResponse, setErrResponse] = useState({errorMessageByQuestionId: {}})
    const [idescription, setIdescription] = useState();
    const [isIAlertOpen, setIsIAlertOpen] = useState(false);
    const [freeTextValue, setFreeTextValue]=useState(null);
    const [freeTextError, setFreeTextError]=useState(null);
    const [freeTextId, setFreeTextId]=useState(null);
    const [error, setError] = useState(false);
    const [selectAlert, setSelectAlert] = useState('')
    const [radioAlert, setRadioAlert] = useState("")
    const [errorIds, setErrorIds] = useState([]);
    const [showAlertHeading, setShowAlertHeading] = useState(false)
    const [errorMgsByID, setErrorMgsByID] = useState("");
    const [radioError, setRadioError]=useState(null);
    const [selectError, setSelectError]=useState(null);
    const [editorStates, setEditorStates] = useState({});
    const [selectedChildrenAge, setSelectedChildrenAge] = useState([]);
    const [numberOfChildren,setNumberOfChildren]=useState()
    const [childrenAgeData,setChildrenAgeData]=useState(null)
    const [childrenObjectData,setChildrenObjectData]=useState(null)
    const [textQuestionId,setTextQuestionId]=useState()

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const questionRefs = useRef(new Map());
    const handleClose = () => setIsIAlertOpen(false);
    const defaultOption = <option value={''}>{t('registration.questionnaires.pleaseSelectOption')}</option>

    const onAlertOpen = (data) => {
        setIsIAlertOpen(true);
        setIdescription(data)
    }

    let {
        loginData: {
            accessToken
        },
        dispatch
    } = useContext(LoginContext);

    usePreventBack();

    useEffect(() => { 
        if (errorIds.length > 0) {
            const firstErrorElement = questionRefs.current.get(errorIds[0]);
            if (firstErrorElement) {
                firstErrorElement.scrollIntoView({ behavior: 'smooth' });
            }
        } else{
            window.scrollTo(0, 0)
        }
    }, [errorIds]);

    useEffect(() => { 
        props?.questionaire?.questionGroups?.filter((e) => e.id === 121).map((item, index) => {
            setChildrenObjectData(item);
            item?.questions?.filter((e) => e.id === 202).map((data) => {
              setNumberOfChildren(data.numberQuestion.answer);
            });
            item?.questions?.filter((e) => e.id === 204).map((data) => {
            setChildrenAgeData(data)
            setSelectedChildrenAge(
              data.textQuestion?.answer
                ?.split(',')
                .map(item => parseInt(item.trim(), 10)) 
                .filter(value => !isNaN(value)) 
            );
            
            });
          });
    }, [props]);

    const forQuetionaireAnswer = () => {
        const newErrorIds = [];
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((data) => {
                if (data.required && (!answers[data.id] || answers[data.id].answerValues?.length === 0 ||answers[data.id].answerValues?.includes(""))) {
                    newErrorIds.push(data.id.toString());
                }
            });
        });

        setErrorIds(newErrorIds);
        validateUIResubmit(newErrorIds)
        if(validateMulRangeResubmit()) return;

        const answerBody = {
            questionnaireId: props?.questionaire?.id,
            answers: answers
        };
        try {

            let cleanedAnswerBody = {...answerBody};
            let cleanedRequest = {};
            for (let key in cleanedAnswerBody?.answers) {
                if (!cleanedAnswerBody?.answers[key]?.answerValues?.includes("")) {
                    cleanedRequest[key] = cleanedAnswerBody.answers[key];
                } else {
                    delete cleanedAnswerBody.answers[key];
                }
            }

            questionaireAnswer(cleanedAnswerBody, accessToken, currentLang)
                .then((res) => {
                    if (res?.status === 200) {
                        setAnswers({});
                        setErrResponse({});
                        dispatch({ type: 'CURRENT_URL', data: res.data });
                        if (res.data?.nextQuestionnaire !== null) {
                            props.setQuestionaireId(res.data?.nextQuestionnaire);
                        } else {
                            props.setQuestionaireId(res.data?.nextStep);
                        }
                    } else {
                        setErrResponse(res.data);
                        setShowAlertHeading(res.data?.message ? true : false)
                    }
                })
        }
        catch (error) {
        }
    };
    const handleOptionClick = (question, answer, questionGroup, editorState) => {         
        if (question?.id === 202 && 1 <= answer && answer <= 20) {
            setNumberOfChildren(answer);
            if (answer < selectedChildrenAge?.length) {
              setSelectedChildrenAge([]);
              handleOptionClick(childrenAgeData,[''],childrenObjectData ); 
            }
          }
        if(question.type === 'TextQuestion' && question.textQuestion.type==="RichTextArea"){
            setFreeTextError(null)
            setFreeTextId(question.id)
            setFreeTextValue(answer)
            let plainText = editorState?.getCurrentContent().getPlainText();        

            if(question.required === true && plainText?.length >= 200 && errResponse?.errorMessageByQuestionId?.[question.id]) {
                let errResponseTemp = errResponse
                delete errResponseTemp.errorMessageByQuestionId[question.id]
                setErrResponse(errResponseTemp)
            }
        }
        if (question.type === 'ChoiceQuestion' && Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            if (question.choiceQuestion.multiSelect === true) {
                let shouldEnable = false
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption ||
                            (answers[question.id] && answers[question.id].answerValues.indexOf(enablerOption) >= 0);
                        if (shouldEnable) { return true; }
                        return false;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    props.setStoreQuestionaire(tempQn)
                })

                if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                    answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
                    updatedAnswersValues(answers)  
                } else if (!answers[question.id]) {
                    answers[question.id] = { "answerValues": [answer], "profileVisible": false }
                    updatedAnswersValues(answers)  
                }
                return;
            }
            else {
                let shouldEnable = false;
                Object.keys(question.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                    question.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                        shouldEnable = parseInt(answer) === enablerOption
                        if (shouldEnable) { return true; }
                        return false;
                    })
                    const tempQn = { ...props.questionaire }
                    tempQn.questionGroups.filter(qg => qg.id === questionGroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable
                    if (!shouldEnable) {
                        answers[key] = {}
                        hideChild(key, questionGroup.id, tempQn, answers)
                    }
                    props.setStoreQuestionaire(tempQn)
                })
            }
        }
        if (question.type === 'ChoiceQuestion' && question.choiceQuestion.multiSelect === true) {
            if (answers[question.id] && answers[question.id].answerValues.indexOf(answer) < 0) {
                answers[question.id].answerValues = [...answers[question.id].answerValues, answer]
            } else if (!answers[question.id]) {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers)  
        } else {
            if (answers[question.id]) {
                if (question.id == 204) {
                    answers[question.id].answerValues = answer;          
                  } 
                  else{
                      answers[question.id].answerValues = [answer]
                  }
            } else {
                answers[question.id] = { "answerValues": [answer], "profileVisible": false }
            }
            updatedAnswersValues(answers)            
        }
    };

    const handleOnChange = () => {
        const newErrorIds = [];
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((data) => {
                if (data.enabledQuestion && data.required && (!answers[data.id] || answers[data.id].answerValues?.length === 0 || answers[data.id].answerValues?.includes(""))) {
                    newErrorIds.push(data.id.toString());
                }
            });
        });
        if (newErrorIds.length === 0) {
            setErrResponse({})
            setShowAlertHeading(false)
        }
    }
    
    const updatedAnswersValues =(answers) => {
        for (let key in answers) {
            if (Object.keys(answers[key]).length !== 0 && !answers[key]?.answerValues?.includes("")) {
                setAnswers({ ...answers })
            } else {
                delete answers[key];
            }
        }
    }
    
    const hideChild = (parentId, qgroupId, storeQuestionnaire, savedAnswers) => {
        const questionGroup = storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0]
        const parentQuestion = questionGroup.questions.filter(ques => ques.id === parseInt(parentId))[0]
        if (parentQuestion.type === 'ChoiceQuestion' && Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).length > 0) {
            Object.keys(parentQuestion.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                savedAnswers[key] = {}
                storeQuestionnaire.questionGroups.filter(qg => qg.id === qgroupId)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = false;
            })
        }
    }

    const validateWithTab = (e, data) => {                 
        if (e.type === "keydown" && e.keyCode ===9 ) {
          if (e.target.type === "select-one") {
            if (e.target.value === "" && data.required) {
              setSelectError(t('registration.questionnaires.fieldRequired'));
              setSelectAlert(data.id);
              e.preventDefault()
            } else {
              setSelectAlert('');
              setSelectError(null)
            }
          } else if (e.target.type === "radio" ) {
            if (!e.target.checked && data.required) {
              setRadioError(t('registration.questionnaires.fieldRequired'));
              setRadioAlert(data.id);
              e.preventDefault()
            } else {
              setRadioAlert('');
              setRadioError(null)
            }
          }
        }
      };

      const handleSetErrorIds = (qId, isError) => {
        const idString = qId.toString();
        if (isError) {
            if (!errorIds.includes(idString)) {
                const updatedErrorIds = [...errorIds, idString];
                setErrorIds(updatedErrorIds);
            }
        } else {
            const updatedErrorIds = errorIds.filter(a => a !== idString);
            setErrorIds(updatedErrorIds);
        }
    };
    

    const validateUIResubmit = (errorIds) => {
        for (let i = 0; i < errorIds.length; i++) {
            updateErrorState((t('registration.questionnaires.fieldRequired')), errorIds[i])
        }
    }
    const validateMulRangeResubmit = () => {
        const ele = document.querySelectorAll('input[min]');
        let isSubmit = false;
        if(ele.length > 0) {
            const eventTarget = {};
            for (let i = 0; i < ele.length; i++) {
                const element = ele[i];
                const qId =  element.id;
                const GqId = element.getAttribute('data-qid');
                eventTarget.target = {value: element.value, min: element.min, max: element.max}
                if(element.value) {
                    const res = validateMultipleRanges(eventTarget, qId, GqId)
                    if(res){
                        isSubmit = true
                        handleSetErrorIds(qId, true); 
                    }else{
                        isSubmit = false
                        handleSetErrorIds(qId, false); 
                    }
                }
            }
            return isSubmit;
        }
        return false;
    }

    const updateErrorState = (message, qId) => {     
        let updatedErrResponse = errResponse;
        if(!updatedErrResponse.errorMessageByQuestionId) updatedErrResponse = {errorMessageByQuestionId: {}}
        updatedErrResponse.errorMessageByQuestionId[qId] = message;
        setErrResponse(updatedErrResponse);
        setErrorMgsByID(message);       
    } 

    const clearErrorState = (qId) => {
        let errResponseTemp = errResponse;
        if(!errResponseTemp.errorMessageByQuestionId) errResponseTemp = {errorMessageByQuestionId: {}}
        if (errResponseTemp && errResponseTemp.errorMessageByQuestionId && errResponseTemp.errorMessageByQuestionId[qId]) {
            delete errResponseTemp.errorMessageByQuestionId[qId];
        }
        if(Object.keys(errResponseTemp.errorMessageByQuestionId).length === 0) errResponseTemp = {errorMessageByQuestionId: {}}
        setErrResponse(errResponseTemp);
        setErrorMgsByID('');
    };

    const validateMultipleRanges = (e, qId, GqId) => {
        let isFirstInput = false;
        let ele = null;
        if(document.querySelectorAll(`[data-qid='${GqId}']`).length > 0) {
            ele = document.querySelectorAll(`[data-qid='${GqId}']`)
        } else {
            ele = document.querySelectorAll(`[data-qid='${qId}']`)
        }
        if(ele.length > 0) {
            let range = [];
        
            ele.forEach((v, k) => {
                if(v.value) range.push({ value: Number(v.value), Gqid: GqId });
            });
            if(ele[0].id == e.target.id) isFirstInput = true;
            
            if(ele[0].value) {
                const minRange = e.target.min ? Number(e.target.min) : 0;
                const maxRange = e.target.max ? Number(e.target.max) : 0;
                const currentValue = Number(e.target.value);
    
                if(isFirstInput) {
                    if (currentValue < minRange || currentValue > maxRange) {
                        updateErrorState(t("registration.questionnaires.minMaxRangeError", { minRange, maxRange }), qId);
                        return true;
                    } else {
                        clearErrorState(qId);
                        return false;
                    }
                } else {
                    if(range.length >= 2) {
                        const firstValue    = range[0].value;
                        const secondValue   = range[1].value;
                        const resMinMax = secondValue - firstValue;
                        if((GqId == 2 || GqId == 16) && resMinMax < 5) {
                            updateErrorState(t("registration.questionnaires.maximumRange"), ele[1].id);
                            return true;
                        }
                        if(GqId == 13 && resMinMax < 10) {
                            updateErrorState(t("registration.questionnaires.maximumHeight"), ele[1].id);
                            return true;
                        }

                        if (currentValue < minRange || currentValue > maxRange) {
                            updateErrorState(t("registration.questionnaires.maximumValue", { minRange, maxRange }), qId);
                            return true;
                        } else {
                            if (Number(ele[0].value) < Number(ele[0].min) || Number(ele[0].value) > Number(ele[0].max)) {
                                updateErrorState(t("registration.questionnaires.minMaxRangeError", { minRange, maxRange }), ele[0].id);
                                if(e.type === 'keydown' && e.keyCode === 9 && e.shiftKey !== true) {
                                    autoFocusByID(ele[0].id);
                                }
                                clearErrorState(qId);      
                                return true;
                            }
                            clearErrorState(ele[0].id);
                            clearErrorState(qId);
                            return false;
                        }
                    } else {
                        if (currentValue < minRange || currentValue > maxRange) {
                            updateErrorState(t("registration.questionnaires.maximumValue", { minRange, maxRange }), qId);
                            return true;
                        }
                    }
                }
            } else {
                if(e.target.value && ele[0].value == '') {
                    let message = '';
                    if(GqId == 2 || GqId == 16) message = t("registration.questionnaires.minAge")
                    if(GqId == 13) message = t("registration.questionnaires.minHeight")
                    updateErrorState(message, qId)
                    return true;
                }
                clearErrorState(qId);
                return false;
            }
        }
    };

    const handleTapNumberQuestion = (e, data, GqId) => {  
        if(data.required) {
            if (e.target.value === "") {
                updateErrorState((t('registration.questionnaires.fieldRequired')), data.id)
            } else {
                if (e.target.min && e.target.max) {
                    validateMultipleRanges(e, data.id, GqId)
                } else {
                    clearErrorState(data.id);
                }
            }
        } else {
            if (e.target.min && e.target.max) {
                validateMultipleRanges(e, data.id, GqId)
            }
        }
    }

    const handleTapBlock = (e, data, GqId) => {
        if (e.type === "keydown" && e.keyCode === 9 && e.shiftKey !== true) {  
            if(data.required) {
                if (e.target.value === "") {
                    updateErrorState((t('registration.questionnaires.fieldRequired')), data.id)
                    e.preventDefault()
                } else {
                    if (e.target.min && e.target.max) {
                        if (validateMultipleRanges(e, data.id, GqId)) e.preventDefault()  
                    } else {
                        clearErrorState(data.id);
                    }
                }
            } else {
                if (e.target.min && e.target.max) {
                    if (validateMultipleRanges(e, data.id, GqId)) e.preventDefault()  
                }
            }
        }
    }
   
    const handleFreeText = (e, data) => {
        if (data.required) {
            const isEmpty = e.target.value === "";
            const freeTextLength = e.target.value.length < 200;
            if (isEmpty || freeTextLength) {
                updateErrorState(t(isEmpty ? 'registration.questionnaires.fieldRequired' : 'suggestions.freetextlimit'), data.id);
                return true;
            }else {
                clearErrorState(data.id);
                return false;
            }
        }
    }

    const  handleTapBlockFreeText = (e, data) => {
        if(data.required && e.type === "keydown" && e.key === "Tab"){
            const isError = handleFreeText(e, data);
            if (isError) {
                e.preventDefault();
            }
        }  
    }
    const errorMessages = errResponse?.errorMessageByQuestionId;
    const lastErrorMessage = errorMessages ? Object.values(errorMessages).pop() : null;
    const autofillAnswers = () => {
        const updatedAnswers = {};
       
        props.questionaire?.questionGroups?.forEach((qgroup) => {
            qgroup.questions.forEach((question) => {
                if (question.type === 'ChoiceQuestion') {
                    if (question.choiceQuestion?.choices.length > 0) {
                        const firstChoiceId = question.choiceQuestion.choices[1].id;    
                        updatedAnswers[question.id] = { "answerValues": [firstChoiceId], "profileVisible": false };
                    }
                }
                if (question.type === 'TextQuestion' || question.type === 'NumberQuestion') {
                    if (question.id == 179) {
                        updatedAnswers[question.id] = { "answerValues": [123], "profileVisible": false };
                    } else updatedAnswers[question.id] = { "answerValues": [], "profileVisible": false };
                    if (question?.textQuestion?.type == 'RichTextArea' && (question.id == 172 || question.id == 165)) {
                        const freeTextContent = "<p>With Gleichklang you can now take the decisive step on the way to a happy partnership or a real friendship After registration you can view and fill in all questionnaires and view your results. This is not binding and you are not obliged to decide to use the paid placement service.This is not binding and you are not obliged to decide to use the paid placement service.<p>";
                        const initialEditorState =
                          EditorState.createWithContent(ContentState.createFromBlockArray(
                            convertFromHTML(freeTextContent).contentBlocks,
                            convertFromHTML(freeTextContent).entityMap
                          ))
            
                        setEditorStates(prevEditorStates => ({
                          ...prevEditorStates,
                          [question.id]: initialEditorState
                        }));
                        updatedAnswers[question.id] = { "answerValues": [freeTextContent], "profileVisible": false };
                    }
                }
            });
        });
        setAnswers(updatedAnswers);
        setErrResponse({})
        setShowAlertHeading(false)
        validateMulRangeResubmit()
    };

    const questionReference = (question, evt) => {
        if (evt) {
            questionRefs.current.set(question.id.toString(), evt);
        } else {
            questionRefs.current.delete(question.id.toString());
        }
    }

    const autoRegister = () => {
        completeRegistration(accessToken).then((result) => {
            if (result?.status === 200) {
                props.setQuestionaireId(result.data?.nextStep);
            } else {
                setErrResponse(result.data);
                setShowAlertHeading(result.data?.message ? true : false)
            }
        })
    }
    
    
    let questionnaireHeader;
    if (props.questionaire?.id == props.allQuestionairIds?.partner?.key) { //Partnership 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
        <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.partnerschaft?.key) { //Partnership 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.['partner.region_question']?.key) { //Partnership 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.partnershipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
        <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.freund?.key) { //Friendship 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step1of3')}</h3>
        <h4>{t('registration.questionnaires.searchingForHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.freundschaft?.key) { //Friendship 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step2of3')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.['friend.region_question']?.key) { //Friendship 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.questionnaires.friendshipHeader')}</strong> &ndash; {t('member.preregistration.step3of3')}</h3>
        <h4>{t('registration.questionnaires.reginalSearchHeader')}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.kontaktliste?.key) { //Personal Details 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step1of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.person?.key) { //Personal Details 2
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step2of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.aussehen?.key) { //Personal Details 3
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step3of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.persoenlichkeit?.key) { //Personal Details 4
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step4of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.hobby_v?.key) { //Personal Details 5
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.personalDetail')}</strong> &ndash; {t('member.preregistration.step5of5')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.gesellschaft?.key) { //Social Engagement 1
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('member.menubar.socialEng')}</strong> &ndash; {t('member.preregistration.step1of1')}</h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.ptext?.key) { //Self description partnership
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourPartnershipProfile')}</strong></h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.ftext?.key) { //Self description friendship
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.yourFriendshipProfile')}</strong></h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else if (props.questionaire?.id == props.allQuestionairIds?.registration?.key) { //Member Survey
        questionnaireHeader = <div className="questionnaireHeader"><h3 className="lineH3 text-center"><strong>{t('registration.m_notification.helpUs')}</strong></h3>
        <h4>{props.questionaire?.name}</h4></div>;
    } else {
        questionnaireHeader = <h4>{props.questionaire?.name}</h4>;
    }

    const onFreetextSave = ( htmlContent, data, qgroup, editorState) => {
        console.log('htmlContent', htmlContent);
        
        handleOptionClick(data, htmlContent, qgroup, editorState)
        if (htmlContent !== "") setError(false)
        handleOnChange()
      };
      
      const onEditorStateChange = (editorState, items) => {
        setEditorStates(prevStates => ({
            ...prevStates,
            [items.id]: editorState
        }));
      };

      const options = [
        { value:1, label: 1 },
        { value:2, label: 2 },
        { value:3, label: 3 },
        { value:4, label: 4 },
        { value: 5, label: 5 },
        { value: 6, label: 6 },
        { value: 7, label: 7 },
        { value: 8, label: 8 },
        { value: 9, label: 9 },
        { value:10, label: 10 },
        { value:11, label: 11 },
        { value:12, label: 12 },
        { value:13, label: 13 },
        { value:14, label: 14 },
        { value:15, label: 15},
        { value:16, label: 16},
        { value:17, label: 17},
        { value:18, label: 18},
        { value:19, label: 19},
        { value:20, label: 20},
      ];
      
      const handleSelect = (data, selectedOption, item, id) => {
        const updatedItems = [...(selectedChildrenAge || []), selectedOption.value];
        
        if (updatedItems.length <= numberOfChildren) {
          setSelectedChildrenAge(updatedItems);
          handleOptionClick(data, updatedItems, id);
          setTextQuestionId('');
        } else {
          setTextQuestionId(id);
        }
      };
      const handleRemove = (index,data,item,id) => {
        const updatedItems = [...selectedChildrenAge];
        updatedItems.splice(index, 1);  
        setSelectedChildrenAge(updatedItems);
        handleOptionClick(data, updatedItems, id)
          if(updatedItems?.length <= numberOfChildren){
          setTextQuestionId('')
          }
      };

    return (
        <div className={"questionnaire_form questionnaire"+props.questionaire?.id}>
            <div className="looking_for">
                {questionnaireHeader}
                <p dangerouslySetInnerHTML={{ __html: props.questionaire?.description }}></p>
            </div>
           {process.env.REACT_APP_ENV != 'PROD' ? <div className="mt-3 mb-3 auto_fill_wrapper">
            {(props.questionaire?.id == 14 || props.questionaire?.id == 4) ? null :
                <Button
                    className='mx-5'
                    variant="secondary"
                    onClick={() => autoRegister()}
                >
                    {t('registration.buttons.autoregister')}
                </Button>
            }
                {props.questionaire?.id == 12 ||
                    props.questionaire?.id == 13 ? null : <Button
                        className='mx-5'
                        variant="success"
                        onClick={autofillAnswers}
                    >
                    {t('registration.buttons.autofill')}
                </Button>}
                <Button
                    className='mx-5'
                    variant="primary"
                    onClick={() => forQuetionaireAnswer()}
                >
                    {t('registration.buttons.next')}
                </Button>
            </div> : null}
            <div className='from_control questionair_container' id='questionnaire_container' ref={scrollRef}>
                {props.questionaire?.questionGroups?.map((qgroup) => (
                    <div key={qgroup.name}>
                        <div className='questionair_header'>
                            <h3 className='questionair_heading'>{qgroup.name}</h3>
                        </div>
                        <div className="questions_wrapper">
                            <p className="group_description" dangerouslySetInnerHTML={{ __html: qgroup.description }}></p>
                        {
                            qgroup.questions.map((data, i) => {
                                
                                if (data.type === 'ChoiceQuestion')
                                    if (data.choiceQuestion?.type === 'ComboBox')
                                        return (
                                        <div key={`combo-${data.id}`} className='questionair_alignment' 
                                        ref={(e) => questionReference(data,e)}             
                                        >
                                            {data.enabledQuestion === true ?
                                                <div>
                                                    <Form.Group className="mt-3 dropdown">
                                                        <span className="disriptionI_label">
                                                            {data?.description ?
                                                                <FontAwesomeIcon
                                                                    icon={faCircleInfo}
                                                                    onClick={() => onAlertOpen(data?.description)}
                                                                /> : null}
                                                            <Form.Label  htmlFor={data.id} id={`label-${data.id}`}>{data.name}
                                                                <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                                                            </Form.Label>
                                                        </span>
                                                        <Form.Select
                                                            id={data.id}
                                                            className={data.required && selectAlert == data.id && 'is-invalid'}
                                                            aria-label={data.required && selectAlert === data.id ? selectError : data.name} 
                                                            aria-required={data.required ? 'true' : 'false'} 
                                                            value={ answers[data.id]?.answerValues?.[0] || defaultOption}
                                                            onChange={(e) => {
                                                                handleOptionClick(data, e.target.value, qgroup)
                                                                handleOnChange()
                                                                if(e.target.value !== '') setSelectAlert('')}}
                                                            onKeyDown={(e) => validateWithTab(e, data)}
                                                        >
                                                            <option value= "">{t('registration.questionnaires.pleaseSelectOption')}</option>
                                                            {data.choiceQuestion.choices.map(choice => (
                                                                <option
                                                                    key={choice.id}
                                                                    value={choice.id}
                                                                >
                                                                    {choice.value}
                                                                </option>
                                                            ))}
                                                        </Form.Select>
                                                        {data.askVisibleToOthers === true ?
                                                            <Form.Check id={`profileVisible${data.id}`}
                                                                name={`profileVisible${data.id}`}
                                                                type="checkbox"
                                                                label={t('common.visibleToOthers')}
                                                                className='msg_pre_checkbox profile_visible'
                                                                onClick={(e) => {
                                                                    if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                        answers[data.id].profileVisible = e.target.checked
                                                                        setAnswers(answers)
                                                                    }
                                                                }}
                                                            >
                                                            </Form.Check> : null}
                                                    </Form.Group>
                                                    {data.required && selectAlert === data.id ? (<Alert className='alert_questionaire'  variant="danger">{selectError}</Alert>): (
                                                        <Alert className='alert_questionaire' show={!answers[data.id] && data.required  &&  errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                        variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                                    )}       
                                                    
                                                </div> : null}
                                        </div>
                                        )
                                if (data.choiceQuestion?.type === 'Radio')
                                    return (
                                        <div key={`radio-${data.id}`} className='radio_section'
                                        ref={(e) => questionReference(data, e)}  
                                        >
                                            {data.enabledQuestion === true ? <div>
                                                <fieldset role="radiogroup" 
                                                    aria-required={data.required ? 'true' : 'false'} 
                                                    aria-labelledby={`legend-${data.id}`}>
                                                    <legend className="disriptionI_label inlineLegend"  id={`legend-${data.id}`}>
                                                        <h4>
                                                            {data?.description ? (
                                                                <FontAwesomeIcon
                                                                    icon={faCircleInfo}
                                                                    onClick={() => onAlertOpen(data?.description)}
                                                                />
                                                            ) : null}
                                                            {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                                                        </h4>
                                                    </legend>                                              
                                                <div id={data.id} className='radio_groups' role='radiogroup'>
                                                    {data.choiceQuestion.choices.map(choice => (
                                                        <Form.Group key={choice.id} className="category_f" id={`label-${choice.id}-${data.id}`}>
                                                            <Form.Check
                                                                checked={answers[data.id]?.answerValues?.includes(choice.id)}
                                                                id={`radio-${choice.id}-${data.id}`}                                              
                                                                type="radio"
                                                                name={data.id}
                                                                label={choice.value}
                                                                className='msg_pre_checkbox'
                                                                onClick={(e) => {
                                                                    handleOptionClick(data, choice.id, qgroup)
                                                                    if(e.target.checked !== "") setRadioAlert("")
                                                                    handleOnChange()
                                                                }}
                                                                onKeyDown={(e) => validateWithTab(e, data)}
                                                            />
                                                        </Form.Group>
                                                    ))
                                                    }
                                                </div>
                                                {data.askVisibleToOthers === true ?
                                                    <Form.Check id={`profileVisible${data.id}`}
                                                        name={`profileVisible${data.id}`}
                                                        type="checkbox"
                                                        label={t('common.visibleToOthers')}
                                                        className='msg_pre_checkbox'
                                                        onClick={(e) => {
                                                            if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                answers[data.id].profileVisible = e.target.checked
                                                                setAnswers(answers)
                                                            }
                                                        }}
                                                    >
                                                    </Form.Check> : null}
                                                    {data.required && radioAlert == data.id ? (
                                                        <Alert className='alert_questionaire'  variant="danger">{radioError}</Alert> 
                                                    ):(
                                                         <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                         variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                                    )}
                                                 </fieldset>
                                            </div> : null}
                                        </div>
                                    )
                                if (data.choiceQuestion?.type === 'Checkbox')
                                    return (
                                        <div key={`check-${data.id}`} className='questionair_alignment'
                                        ref={(e) => questionReference(data, e)}  
                                        >
                                            {data.enabledQuestion === true ? <div>
                                            <fieldset>  
                                                <legend className="disriptionI_label inlineLegend">
                                                    <h4>{data?.description ?
                                                        <FontAwesomeIcon
                                                            icon={faCircleInfo}
                                                            onClick={() => onAlertOpen(data?.description)}
                                                        /> : null}
                                                        {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                                                    </h4>
                                                </legend>
                                                <div className='checkbox_questionair'>
                                                    {data.choiceQuestion.choices.map(choice => (
                                                        <Form.Group key={choice.id} className="category_f">
                                                            <Form.Check
                                                                defaultChecked={answers[data.id]?.answerValues.includes(choice.id)}
                                                                id= {`checkbox-${choice.id}-${data.id}`}
                                                                name={data.id}
                                                                type="checkbox"
                                                                label={choice.value}
                                                                className='msg_pre_checkbox'
                                                                onClick={(e) => {
                                                                    if (e.target.checked) {
                                                                        handleOptionClick(data, choice.id, qgroup)
                                                                        handleOnChange()
                                                                    } else if (answers[data.id] && answers[data.id].answerValues.indexOf(choice.id) >= 0) {
                                                                        answers[data.id].answerValues.splice(answers[data.id].answerValues.indexOf(choice.id), 1)
                                                                        if(answers[data.id].answerValues.length == 0) delete answers[data.id];
                                                                        let shouldEnable = false
                                                                        Object.keys(data.choiceQuestion.enabledQuestionActivatingChoiceKeys).forEach((key) => {
                                                                            data.choiceQuestion.enabledQuestionActivatingChoiceKeys[key].find((enablerOption) => {
                                                                                shouldEnable = answers[data.id] && answers[data.id].answerValues.indexOf(enablerOption) >= 0;
                                                                                if (shouldEnable) { return true; }
                                                                                return false;
                                                                            })
                                                                            const tempQn = { ...props.questionaire }
                                                                            tempQn.questionGroups.filter(qg => qg.id === qgroup.id)[0].questions.filter(ques => ques.id === parseInt(key))[0].enabledQuestion = shouldEnable;
                                                                            if (!shouldEnable) {
                                                                                answers[key] = {};
                                                                            }
                                                                            props.setStoreQuestionaire(tempQn)
                                                                        })
                                                                        setAnswers(answers)
                                                                    }
                                                                }}
                                                            />
                                                        </Form.Group>
                                                    ))}
                                                    {data.askVisibleToOthers === true ?
                                                        <Form.Check id={`profileVisible${data.id}`}
                                                            name={`profileVisible${data.id}`}
                                                            type="checkbox"
                                                            label={t('common.visibleToOthers')}
                                                            className='msg_pre_checkbox'
                                                            onClick={(e) => {
                                                                if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                    answers[data.id].profileVisible = e.target.checked
                                                                    setAnswers(answers)
                                                                }
                                                            }}
                                                        >
                                                        </Form.Check> : null}
                                                </div>
                                                <Alert className='alert_questionaire' show={!answers[data.id] && data.required && errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] ? true : false}
                                                    variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                            </fieldset>
                                            </div> : null}
                                        </div>
                                    );
                                if (data.type === 'NumberQuestion')
                                    return (
                                    <div key={`number-${data.id}`} className='questionair_alignment'
                                    ref={(e) => questionReference(data, e)}  
                                    >
                                        {data.enabledQuestion === true ? <div>
                                            <Form.Group className="mt-3 dropdown">
                                                <div className="disriptionI_label" id={`description-${data.id}`}>
                                                    {data?.description ?
                                                        <FontAwesomeIcon
                                                            icon={faCircleInfo}
                                                            onClick={() => onAlertOpen(data?.description)}
                                                        /> : null}
                                                    <Form.Label id={`label-${data.id}`}  className="radio_label" htmlFor={data.id}>
                                                        {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                                                    </Form.Label>
                                                </div>
                                                <Form.Control
                                                    defaultValue={answers[data.id]?.answerValues[0] || ''}
                                                    type="text"
                                                    id={data.id}
                                                    data-qid={(qgroup.questions.length == 2 || qgroup.questions.length == 3) ? qgroup.id : data.id}
                                                    aria-label={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && errorMgsByID  }
                                                    className={ errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && 'is-invalid'}
                                                    min={data.numberQuestion.minValue}
                                                    max={data.numberQuestion.maxValue}
                                                    onChange={(e) => {
                                                        handleOptionClick(data, e.target.value, qgroup)
                                                        handleOnChange()
                                                    }}
                                                    onKeyUp={(e) => numberOnlyWithDigit(e, 3)}
                                                    onKeyDown={(e) => handleTapBlock(e, data, qgroup.id)}
                                                    onBlur={(e)  => handleTapNumberQuestion(e, data, qgroup.id)}
                                                    aria-required={data.required ? 'true' : 'false'}
                                                />
        
                                                {data.askVisibleToOthers === true ?
                                                    <Form.Check id={`profileVisible${data.id}`}
                                                        name={`profileVisible${data.id}`}
                                                        type="checkbox"
                                                        label={t('common.visibleToOthers')}
                                                        className='msg_pre_checkbox'
                                                        onClick={(e) => {
                                                            if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                answers[data.id].profileVisible = e.target.checked
                                                                setAnswers(answers)
                                                            }
                                                        }}
                                                    >
                                                    </Form.Check> : null}
                                            </Form.Group>
                                            {errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && (
                                                <Alert className='alert_questionaire' 
                                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                            )}

                                        </div> : null}
                                    </div>
                                    )

                                if (data.type === 'TextQuestion')
                                    return (
                                    <div key={`text-${data.id}`} className='questionair_alignment'
                                    ref={(e) => questionReference(data, e)}  
                                    >
                                        {data.enabledQuestion === true ? <div>
                                            <Form.Group className="mt-3 text_question">
                                                <span className="disriptionI_label">
                                                    {data?.description ?
                                                        <FontAwesomeIcon
                                                            icon={faCircleInfo}
                                                            onClick={() => onAlertOpen(data?.description)}
                                                        /> : null}
                                                    <Form.Label className="radio_label" htmlFor={data.id}>
                                                        {data.name} <span className='required_asterisks' aria-hidden="true">{data.required ? '*' : null}</span>
                                                    </Form.Label>
                                                </span>                                                                                                
                                                   {data?.textQuestion?.type == 'TextArea' ?
                                                     <>
                                                   {data.id != 204 ? 
                                                   <Form.Control
                                                   value={answers[data.id] != null && answers[data.id].answerValues != null && answers[data.id].answerValues.length > 0 
                                                       ? answers[data.id]?.answerValues[0] : ''}
                                                   as="textarea"
                                                   className={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && 'is-invalid'}
                                                   id={data.id}
                                                   aria-label={errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && errorMgsByID}
                                                   aria-required={data.required ? 'true' : 'false'} 
                                                   onChange={(e) => {
                                                       handleOptionClick(data, e.target.value, qgroup)
                                                       handleOnChange()
                                                   }}
                                                   onKeyDown={(e)  => handleTapBlockFreeText(e, data)}
                                                   onBlur={(e)  => handleFreeText(e,data)}
                                               />
                                               : 
                                                                <div className={data.required && error && freeTextError === data.id && "is-invalid"}>
                                                                    {selectedChildrenAge?.length != numberOfChildren ? (
                                                                        <Select
                                                                            options={options}
                                                                            onChange={(e) => handleSelect(data, e, qgroup, data.id)}
                                                                            className={data.required && error && freeTextError === data.id && "is-invalid"}
                                                                            placeholder="Select Age of children"
                                                                            isClearable={false}
                                                                            isSearchable={true}
                                                                            value={null}
                                                                        />
                                                                    ) : ""}
                                                                    <div className="selected-items">
                                                                        {selectedChildrenAge?.map((item, index) => (
                                                                            <div key={index} className="selected-item">
                                                                                {item}
                                                                                <div className='selected-item-button' onClick={() => handleRemove(index, data, item, data.id)}><FontAwesomeIcon icon={faMultiply} /></div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </div>
                                                            }
                                                   </>
                                                     :
                                                     <Editor
                                                         editorState={editorStates[data.id] || EditorState.createEmpty()}
                                                         toolbarClassName="toolbarClassName"
                                                         wrapperClassName="wrapperClassName"
                                                         editorClassName="editorClassName"
                                                         onEditorStateChange={(editorState) => {onEditorStateChange(editorState, data);
                                                             let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();
                                                             onFreetextSave( htmlContent, data, qgroup, editorState);
                                                         }}
                                                         toolbar={{
                                                             options:['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker','emoji','history'] ,   
                                                             list: { inDropdown: true },
                                                             textAlign: { inDropdown: true },
                                                         }}
                                                     />
                                                     
                                                }
                                                {data.askVisibleToOthers === true ?
                                                      <Form.Check id={`profileVisible${data.id}`}
                                                        name={`profileVisible${data.id}`}
                                                        type="checkbox"
                                                        label={t('common.visibleToOthers')}
                                                        className='msg_pre_checkbox'
                                                        onClick={(e) => {
                                                            if (answers[data.id] && answers[data.id].answerValues.length > 0) {
                                                                answers[data.id].profileVisible = e.target.checked
                                                                setAnswers(answers)
                                                            }
                                                        }}
                                                    >
                                                    </Form.Check> : null}
                                            </Form.Group>
                                            {errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id] && (
                                                <Alert className='alert_questionaire' 
                                                variant="danger" id={`err_${data.id}`}>{errResponse && errResponse.errorMessageByQuestionId && errResponse.errorMessageByQuestionId[data.id]}</Alert>
                                            )}
                                            
                                    {freeTextError && freeTextId && freeTextId===data.id &&
                                    <Alert className='alert_questionaire' id={data.id}
                                        variant="danger" >
                                        {freeTextError ? freeTextError :""}
                                    </Alert>}
                                        </div> : null}
                                    </div>
                                    )

                                if (data.type === 'RegionQuestion')
                                    return (
                                        <RegionalSearch
                                            key={`region-${data.id}`}
                                            data={data}
                                            questionaireId={props.questionaire?.id}
                                            setAnswers={setAnswers}
                                            isFromRegistration={true}
                                        />
                                    )
                            })
                        }
                        </div>
                    </div>

                ))}
                <div className="mt-3 step_one_next">
                    <Button
                        className='mx-5'
                        variant="primary"
                        onClick={() => forQuetionaireAnswer()}
                    >
                        {t('registration.buttons.next')}
                    </Button>
                </div>
            </div>
            {isIAlertOpen ?
                <DescriptionAlert
                    description={idescription}
                    show={isIAlertOpen}  
                    handleClose={handleClose}                  
                /> : null}
        </div>
    )
}

export default Questionaire;