import React from "react";
import Alert from 'react-bootstrap/Alert';

const AlertModal = ({ show, setShow, heading, body, className='' }) => {
	return (
		<Alert className={`pre_registration_alert ${className && className}`} show={show} variant="danger" onClose={() => setShow(false)}>
			{body}
		</Alert>
	)
}

export default AlertModal;
