import React, { useContext, useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { useTranslation } from 'react-i18next';
import { LoginContext } from '../../context/loginContext';
import {
  getCountry,
  getZipCode,
  getContinentForMemberAddress,
  makePayment,
  getAdressForPayment
} from "./registrationProcessService";
import { AsyncPaginate } from 'react-select-async-paginate';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import Alert from 'react-bootstrap/Alert';
import usePreventBack from './preventBack/preventBack'
import Modal from 'react-bootstrap/Modal';


function Payment(props) {
  const [countries, setCountries] = useState();
  const [zipCode, setZipCode] = useState();
  const [countryId, setCountryId] = useState('');
  const [countryKey, setCountryKey] = useState();
  const [continent, setContinent] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [iban, setIban] = useState();
  const [street, setStreet] = useState();
  const [city, setCity] = useState();
  const [email, setEmail] = useState();
  const [continentValue, setContinentValue] = useState();
  const [errors, setErrors] = useState();
  const [isPaymentDone, setIsPaymentDone] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  // Validation field
  const [isFNameValid, setIsFNameValid] = useState(false)
  const [isLNameValid, setIsLNameValid] = useState(false)
  const [isEmailValid, setIsEmailValid] = useState(false)
  const [isIbanValid, setIsIbanValid] = useState(false)
  const [isAddressError, setIsAddressError] = useState([
    {
      continent: false,
      country: false,
      zip: false,
    },
  ]);  
  const [showPaymentFailedPopup, setShowPaymentFailedPopup] = useState(false)
  usePreventBack();

  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  let {
    loginData: {
      accessToken
    },
    dispatch
  } = useContext(LoginContext);
  const token_lang = {
    language: currentLang,
    accessToken
  }
  let navigate = useNavigate();

  useEffect(() => {
    getAdressForPayment(accessToken).then((response) => {
      if (response?.status === 200) {
        setFirstName(response?.data?.firstName);
        setLastName(response?.data?.lastName);
        setCity(response?.data?.paymentAddress.city);
        setStreet(response?.data?.paymentAddress.street);
        setEmail(response?.data?.email);
        setCountryId(response?.data?.paymentAddress.country.id);
        setCountryKey(response?.data?.paymentAddress.country.key);
        setContinentValue(response?.data?.paymentAddress.continent.id);
        setZipCode({
          label: response?.data?.paymentAddress?.zip?.value,
          value: response?.data?.paymentAddress?.zip?.id
        })
        getCountryData(response?.data?.paymentAddress.continent.id);
      }
    })
  }, [])

  useEffect(() => {
    getContinentForMemberAddress(token_lang).then((result) => {
      if (result?.status === 200) {
        const modifiedContinent = {
          results: result?.data?.locations.map(item => (
            {
              value: item.id,
              label: item.value,
            }))
        }
        setContinent(modifiedContinent?.results)
      }
    })
  }, [])

  const getCountryData = async (id) => {
    const data = await getCountry(accessToken, id)
    if (data?.status === 200) {
      const modifiedCountry = {
        results: data?.data?.locations.map(item => (
          {
            value: item.id,
            label: item.value,
            key: item.key
          }))
      }
      setCountries(modifiedCountry?.results)
    }
  }

  async function loadZipOptions(search, loadedOptions, { page }) {
    const res = await getZipCode(accessToken, countryId, page, search);
    if (res?.status === 200) {
      const { data = [] } = res || {};
      const adaptedJson = {
        results: data?.locations.map(item => ({
          value: item.id,
          label: item.value,
        })),
        has_more: data?.locations.length > 0,
      };
      return {
        options: adaptedJson?.results,
        hasMore: adaptedJson?.has_more,
        additional: {
          page: page + 1,
        },
      };
    }
  }

  const isValidEmailRegex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;

  const validateEmail = (email) => {
    return !(email.match(isValidEmailRegex));
  }

  const validateForm = () => {
    if (!firstName) {
      setErrors(t('registration.payment.e_firstname'));
      return true;
    };
    if (!lastName) {
      setErrors(t('registration.payment.e_lastname'));
      return true;
    };
    if (!email) {
      setErrors(t('registration.payment.e_email'));
      return true;
    }
    if (validateEmail(email)) {
      setErrors(t('registration.payment.e_validemail'));
      return true;
    }

    if (!continentValue) {
      setErrors(t('registration.payment.e_continent'));
      return true;
    };
    if (!countryKey) {
      setErrors(t('registration.payment.e_country'));
      return true;
    };
    if (!zipCode) {
      setErrors(t('registration.payment.e_zipcode'));
      return true;
    };
    if (!iban) {
      setErrors(t('registration.payment.e_iban'));
      return true;
    };
  };

  useEffect(() => {
    if (firstName) setErrors();
    if (!lastName) setErrors();
    if (!email) setErrors();
    if (!continentValue) setErrors();
    if (!countryKey) setErrors();
    if (!zipCode) setErrors();
    if (!iban) setErrors();
  }, [
    iban,
    email,
    zipCode,
    lastName,
    firstName,
    countryKey,
    continentValue
  ])

  const savePaymentData = () => {
    setIsPaymentDone(true)
    if (validateForm()) {
      setIsPaymentDone(false)
      return;
    };

    const body = {
      "firstName": firstName,
      "lastName": lastName,
      "company": null,
      "address": {
        "street": street,
        "country": countryKey,
        "postalCode": zipCode.value,
        "city": city
      },
      "email": email,
      "phone": "",
      "mobile": "",
      "iban": iban
    }
    makePayment(body, accessToken).then((res) => {
      if (res?.status === 200) {
        setIsPaymentDone(false)
        if (props.isUserRegistered === true) {
          navigate('/home');
        } else {
          const currentUrlData = {
            nextQuestionnaire: null,
            success: true,
            nextStep: 'PAYMENTRESULT',
            registrationComplete: false
          }
          dispatch({ type: 'CURRENT_URL', data: currentUrlData });
          props.setQuestionaireId('PAYMENTRESULT')
        }
      } else if (res?.status === 417) {
        setIsPaymentDone(false)
        setShowPaymentFailedPopup(true)
      } else {
        setIsPaymentDone(false)
        setIsError(true);
        setErrorMessage(res?.data?.message);
      }
    })
  }

  const navigateToPendingDD = () => {
    navigate('/pending/dd', { replace: true });
  }

  const setIsDataAddressError = (index, field, value) => {
    setIsAddressError((prevAddresses) => {
      const updatedAddresses = [...prevAddresses];
      updatedAddresses[index][field] = value;
      return updatedAddresses;
    });
  };

  const handlePayFirstName = (e) => {
    if(e.target.value) {
      setIsFNameValid(false);
      return false;
    } else {
      setIsFNameValid(true);
      return true;
    }
  }

  const handlePayLastName = (e) => {
    if(e.target.value) {
      setIsLNameValid(false);
      return false;
    } else {
      setIsLNameValid(true);
      return true;
    }
  }

  const handlePayEmail = (e) => {
    if(e.target.value) {
      if (validateEmail(email)) {
        setIsEmailValid(true);
        return true;
      } else {
        setIsEmailValid(false);
        return false;
      }
    } else {
      setIsEmailValid(true);
      return true;
    }
  }

  const handlePayIban = (e) => {
    if(e.target.value) {
      setIsIbanValid(false);
      return false;
    } else {
      setIsIbanValid(true);
      return true;
    }
  }

  const validateWithTAB = async (evt) => {
    let isError = null;
    if(evt.type === 'keydown' && evt.keyCode === 9 && evt.shiftKey !== true) {
      if(evt.target.id === 'paymentFirstname') isError = handlePayFirstName(evt);
      if(evt.target.id === 'paymentLastname') isError = handlePayLastName(evt);
      if(evt.target.id === 'paymentEmail') isError = handlePayEmail(evt);
      if(evt.target.id === 'formGridIBan') isError = handlePayIban(evt);
      if(evt.target.value === '' || isError) evt.preventDefault();
    }
  }
  
  const validateSELOptionWithTAB = (evt, isdata, type, index) => {    
    let rsType = '';
    if(type === 'continent') rsType = 'continent';
    if(type === 'country') rsType = 'country';
    if(type === 'zip') rsType = 'zip';

    if(!isdata && !isdata?.value) {
      setIsDataAddressError(index, rsType, true)
    } else {
      setIsDataAddressError(index, rsType, false)
    }
    if(evt.type === "keydown" && evt.keyCode === 9) {
      if(!isdata && !isdata?.value) evt.preventDefault();
    }
  }
  
  useEffect(() => {
    window.scrollTo(0, 0)
  }, []);

  return (
    <div>
      <div className="looking_for">
        <div className="questionnaireHeader">
          <h3 className="lineH3 text-center"><strong>{t('member.preregistration.finalizeHeading')}</strong> &ndash; {t('member.preregistration.step2of2')}</h3>
          <h4>{t('registration.payment.finalizePayment')}</h4>
          <p>{t('registration.personalPost.description')}</p>
        </div>
      </div>
      <div className='from_control questionair_container member_address_registration finalize_payment'>
        <div>
          <h3 className='questionair_heading mb-3'>Payment</h3>
        </div>
        <div className="mt-3 questions_wrapper">
          <Form>
            <Row className="mb-3 for_mobile">
              <Form.Group as={Col} controlId="paymentFirstname">
                <Form.Label>{t('registration.payment.firstname')}*</Form.Label>
                <Form.Control
                  type="text"
                  value={firstName} 
                  className={isFNameValid && "is-invalid"}
                  onChange={(e) => setFirstName(e.target.value)}
                  placeholder={t('registration.payment.firstname')}
                  onBlur={(e) => handlePayFirstName(e)}
                  onKeyDown={(e) => {
                    validateWithTAB(e);
                  }}
                />
                {isFNameValid &&<Form.Control.Feedback type="invalid"> {t('registration.payment.e_firstname')} </Form.Control.Feedback>}
              </Form.Group>
              <Form.Group as={Col} controlId="paymentLastname">
                <Form.Label>{t('registration.payment.lastname')}*</Form.Label>
                <Form.Control
                  value={lastName} 
                  className={isLNameValid && "is-invalid"}
                  onChange={(e) => setLastName(e.target.value)}
                  type="text"
                  placeholder={t('registration.payment.lastname')}
                  onBlur={(e) => handlePayLastName(e)}
                  onKeyDown={(e) => {
                    validateWithTAB(e);
                  }}
                />
                {isLNameValid &&<Form.Control.Feedback type="invalid"> {t('registration.payment.e_lastname')} </Form.Control.Feedback>}
              </Form.Group>
            </Row>
            <Row>
              <Form.Group as={Col} controlId="paymentEmail">
                <Form.Label>Email*</Form.Label>
                <Form.Control
                  value={email} 
                  className={isEmailValid && "is-invalid"}
                  onChange={(e) => setEmail(e.target.value)}
                  type='email'
                  onBlur={(e) => handlePayEmail(e)}
                  onKeyDown={(e) => {
                    validateWithTAB(e);
                  }}
                />
                {isEmailValid &&
                  <Form.Control.Feedback type="invalid">
                    {email && validateEmail(email) ? t('registration.payment.e_validemail'): t('registration.payment.e_email')}
                  </Form.Control.Feedback>
                }
              </Form.Group>
              <Form.Group as={Col}>
                <Form.Label htmlFor="continentField">{t('member.personaldata.personaldetails.continent')}*</Form.Label>
                <Select
                  value={continent.find(con => con.value == continentValue)} 
                  options={continent} 
                  inputId="continentField" 
                  className={isAddressError[0].continent && "is-invalid"}
                  onChange={(e) => {
                    getCountryData(e.value);
                    setContinentValue(e.value);
                    setCountryId('');
                    setZipCode('');
                  }}
                  onKeyDown={(e) => validateSELOptionWithTAB(e, continentValue, 'continent', 0)}
                />
                {isAddressError[0].continent && <>
                  <Form.Control.Feedback type="invalid">
                    {t('registration.payment.e_continent')}
                  </Form.Control.Feedback>
                  </>}
              </Form.Group>
            </Row>
            <div>
              <Row className="mb-3 mt-3 for_mobile">
                <Form.Group as={Col}>
                  <Form.Label htmlFor="countryField">{t('member.personaldata.personaldetails.country')}*</Form.Label>
                  <Select
                    value={countryId && countries?.find(country => country.value === countryId)}
                    inputId="countryField" 
                    className={isAddressError[0].country && "is-invalid"}
                    options={countries}
                    onChange={(e) => {
                      const selectedCountryId = e.value;
                      const selectedCountry = countries?.find(country => country.value == selectedCountryId);
                      const countryKey = selectedCountry ? selectedCountry.key : '';
                      setCountryId(e.value);
                      setCountryKey(countryKey);
                      setZipCode('')
                    }} 
                    onKeyDown={(e) => validateSELOptionWithTAB(e, countryId, 'country', 0)}
                    />
                    {isAddressError[0].country && <>
                      <Form.Control.Feedback type="invalid">
                        {t('registration.payment.e_country')}
                      </Form.Control.Feedback>
                    </>}
                </Form.Group>
                <Form.Group as={Col}>
                  <Form.Label htmlFor="zipField">{t('member.personaldata.personaldetails.zip')}*</Form.Label>
                  <AsyncPaginate
                    key={countryId} 
                    inputId="zipField"
                    value={zipCode}
                    className={isAddressError[0].zip ? "is-invalid async_paginate" : "async_paginate"}
                    loadOptions={loadZipOptions}
                    isDisabled={!countryId}
                    cacheUniqs={[countryId]}
                    onChange={(e) => {
                      setZipCode(e);
                    }}
                    onKeyDown={(e) => validateSELOptionWithTAB(e, zipCode, 'zip', 0)}
                    additional={{
                      page: 1,
                    }}
                    placeholder={t('registration.buttons.pleaseselect')}
                  />
                  {isAddressError[0].zip && <>
                    <Form.Control.Feedback type="invalid">
                      {t('registration.payment.e_zipcode')}
                    </Form.Control.Feedback>
                  </>}
                </Form.Group>
              </Row>
              <Row className='for_mobile'>

                <Form.Group as={Col} controlId="formGridCity">
                  <Form.Label>{t('member.personaldata.personaldetails.city')}</Form.Label>
                  <Form.Control
                    onChange={(e) => setCity(e.target.value)}
                    type="text"
                    placeholder='City'
                    value={city}
                  />
                </Form.Group>

                <Form.Group as={Col} controlId="formGridStreet">
                  <Form.Label>{t('member.personaldata.personaldetails.street')}</Form.Label>
                  <Form.Control
                    onChange={(e) => setStreet(e.target.value)}
                    type="text"
                    placeholder='Street/House No.'
                    value={street}
                  />
                </Form.Group>
              </Row>
            </div>
            <Row className="mt-4 mb-4 for_mobile">
              <Col md={6}>
              <Form.Group controlId="formGridIBan">
                <Form.Label>IBAN*</Form.Label>
                <Form.Control
                  onChange={(e) => setIban(e.target.value)}
                  type="text"
                  value={iban}
                  className={isIbanValid && "is-invalid"}
                  onBlur={(e) => handlePayIban(e)}
                  onKeyDown={(e) => {
                    validateWithTAB(e);
                  }}
                />
                {isIbanValid &&<Form.Control.Feedback type="invalid"> {t('registration.payment.e_iban')} </Form.Control.Feedback>}
              </Form.Group>
              </Col>
            </Row>
            {errors ?
              <Alert
                className='member_address'
                variant="danger"
                id='main_alert'
                dismissible
              >
                <p>{errors}</p>
              </Alert>
              : null}
            {isError ? <Alert
              className='member_address'
              variant="danger"
              id='main_alert'
              onClose={() => setIsError(false)}
              dismissible
            >
              <p>{errorMessage}</p>
            </Alert>
              : null}
            <div className="personal_data_btn">
              <Button
                onClick={() => savePaymentData()}
                disabled={isPaymentDone}
                className='mx-5'
                variant="primary"
              >
                {t('registration.payment.paymentnow')}
              </Button>
            </div>
          </Form>
        </div>
        <div className="modal show">
          <Modal className="registration_modal" show={showPaymentFailedPopup}>
            <Modal.Header>
              <Modal.Title>{t('registration.payment.failed')}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <p>{t('registration.payment.failedDesc')}</p>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="primary" onClick={() => { navigateToPendingDD(); }}>
                {t('registration.buttons.next')}</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Payment;