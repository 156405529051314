export const baseUrl = process.env.REACT_APP_BASE_URL;
const validateAliasURL = 'pre_registration/validate/alias'
const validateEmailURL = 'pre_registration/validate/email'
const savePreRegisterUserURL = 'pre_registration/create'
const sexQuestionURL = 'pre_registration/sex'

export async function validateAlias(data) {
    return fetch(`${baseUrl}/${validateAliasURL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': data.language,
        },
        body: JSON.stringify(data.body),
    })
        .then((response) =>
            response.json().then((data) => ({
                data: data,
                status: response.status,
            }))
        )
        .then(async (responseData) => {
            return responseData;
        })
        .catch((err) => {
            throw err;
        });

}

export async function validateEmail(data) {
    return fetch(`${baseUrl}/${validateEmailURL}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': data.language,
        },
        body: JSON.stringify(data.body),
    })
        .then((response) =>
            response.json().then((data) => ({
                data: data,
                status: response.status,
            }))
        )
        .then(async (responseData) => {
            return responseData;
        })
        .catch((err) => {
            throw err;
        });

}

export async function getSexQuestion(data) {
    return fetch(`${baseUrl}/${sexQuestionURL}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': data.language,
        },
        'mode': 'cors'
    })
        .then((response) =>
            response.json().then((data) => ({
                data: data,
                status: response.status,
            }))
        )
        .then(async (responseData) => {
            return responseData;
        })
        .catch((err) => {
            throw err;
        });

}

export async function savePreRegistrationData(body, language) {
    body.birthDate = new Intl.DateTimeFormat('en-US',{month:'2-digit',day:'2-digit', year:'numeric'}).format(new Date(body.birthDate))
    return fetch(`${baseUrl}/${savePreRegisterUserURL}`, {    
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Accept-Language': language,
        },
        body: JSON.stringify(body),
        'mode': 'cors'
    })
        .then((response) =>
            response.json().then((data) => ({
                data: data,
                status: response.status,
            }))
        )
        .then(async (responseData) => {
            return responseData;
        })
        .catch((err) => {
            throw err;
        });

}

