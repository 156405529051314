import React, { useContext, useEffect, useState } from 'react';
import { Form, Modal, Button, Alert, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ModalBody } from 'react-bootstrap';
import { LoginContext } from '../../context/loginContext';
import { sendMsgToGlk } from './membershipServices'
import { faTrash, faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import {
    EditorState,
    convertToRaw
  } from "draft-js";
  import Accordion from 'react-bootstrap/Accordion';


function WriteMessageToGleichklang(props) {    
    const { show, close, reply} = props;
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [subject, setSubject] = useState('');
    const [messageBody, setMessageBody] = useState('');
    const [formErrors, setFormErrors] = useState({ subject: '', reportData: '' });
    const [isError , setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState();
    const [isResponse, setIsResponse] = useState(false);
    const [editorText, setEditorText] = useState(null);
    const [editorStates, setEditorStates] = useState();


    const { t, i18n } = useTranslation();
    const { loginData: { accessToken } } = useContext(LoginContext);
    const token_lang = { language: i18n.language, accessToken };

    useEffect(() => {
        if(reply?.isReply)
        setSubject(reply?.message?.subject);
    }, [])

    const sendMessage = async () => {
        setIsResponse(true)
        if (!subject || editorText == null || editorText == '') {
            setFormErrors({
                subject: !subject ? t('mymembership.msgtoGk.subjectError') : '',
                reportData: editorText == null || editorText == '' ? t('mymembership.msgtoGk.msgError') : ''
            });
            setIsResponse(false);
            return;
        }

        // Create FormData
        let formData = new FormData();
        const writeMsgToGlkRequest = reply?.isReply ? {
            subject,
            body: messageBody,
            replyToMessage: reply?.message?.id
        } :  {
            subject,
            body: messageBody,
        };

        for (const file of selectedFiles) {
            formData.append('files', file, file.name);
        }
        formData.append('request', new Blob([JSON.stringify(writeMsgToGlkRequest)], {
            type: 'application/json'
        }));

        try {
            const result = await sendMsgToGlk(token_lang, formData);
            if (result?.status === 200) {
                close();
                setIsResponse(false);
            }
            else {
                setIsError(true)
                setErrorMessage(result?.data?.message)
                setIsResponse(false);
            }
        } catch (error) {
            console.error('Failed to send message to Gleichklang team:', error);
        }
    };

    const uploadFiles = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFiles(prevFiles => [...prevFiles, ...files]);

        let formData = new FormData();
        files.forEach(file => formData.append('files', file, file.name));

        const writeMsgToGlkRequest = {
            subject,
            body: messageBody
        };

        formData.append('request', new Blob([JSON.stringify(writeMsgToGlkRequest)], {
            type: 'application/json'
        }));        
    };

    const deleteFile = (fileName) => {
        const newFiles = selectedFiles.filter(file => file.name !== fileName);
        setSelectedFiles(newFiles);

        let formData = new FormData();
        newFiles.forEach(file => formData.append('files', file, file.name));

        const writeMsgToGlkRequest = {
            subject,
            body: messageBody
        };

        formData.append('request', new Blob([JSON.stringify(writeMsgToGlkRequest)], {
            type: 'application/json'
        }));        
    };

    const onEditorStateChange = (editorState) => {
        let plainText = editorState.getCurrentContent().getPlainText();  
        setEditorStates(editorState)   
        
        setEditorText(plainText)
        let htmlContent = draftToHtml(convertToRaw(editorState.getCurrentContent())).trim();

            setMessageBody(htmlContent);
            setFormErrors({ ...formErrors, reportData: '' });
    };    

    return (
        <Modal className='openprofile' show={show} size='lg' onHide={close}>
            <Modal.Header closeButton>
                <Modal.Title>{t('suggestions.writemessage')}</Modal.Title>
            </Modal.Header>
            <ModalBody>               
                <Form.Group className="text_question mt-3" controlId="formGridCity">
                    <Form.Label>{t('suggestions.recipient')}</Form.Label>
                    <Form.Control
                        value='Gleichklang Team'
                        disabled
                    />
                </Form.Group>
                <Form.Group className="text_question mt-3" controlId="formGridCity">
                    <Form.Label>{t('suggestions.subject')}</Form.Label>
                    <Form.Control
                        value={subject}
                        isInvalid={!!formErrors.subject}
                        onChange={(e) => {
                            setSubject(e.target.value);
                            setFormErrors({ ...formErrors, subject: '' });
                        }}
                    />
                    <Form.Control.Feedback type="invalid">
                        {formErrors.subject}
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="msgtoglk_editor  mt-3" id='textquestion'>
                    <Form.Label className="" htmlFor="status">{t('suggestions.messagetext')}</Form.Label>                   
                    <Editor
                        editorState={editorStates ||  EditorState.createEmpty()}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={(editorState) => onEditorStateChange(editorState)}
                        toolbar={{
                            options: ['inline', 'blockType', 'fontSize', 'fontFamily', 'list', 'textAlign', 'colorPicker', 'history'],
                            list: { inDropdown: true },
                            textAlign: { inDropdown: true },
                          }}
                    />                    
                    <p className='abuse_message_err'>{formErrors.reportData}</p>
                </Form.Group>
                {selectedFiles.length > 0 && selectedFiles.map((file) => (
                    <div key={file.name} className='report_file_preview_wrapper'>
                        <div className='paperclip_name_wraper'>
                            <FontAwesomeIcon
                                className='paperclip'
                                icon={faPaperclip}
                            />
                            <p>{file.name}</p>
                        </div>
                        <div>
                            <FontAwesomeIcon
                                className='trash'
                                icon={faTrash}
                                onClick={() => deleteFile(file.name)}
                            />
                        </div>
                    </div>
                ))}
                {reply?.message && <Accordion>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{reply?.message?.subject}</Accordion.Header>
                        <Accordion.Body>
                            <span className='replytomsg_msg' dangerouslySetInnerHTML={{ __html: reply?.history?.body }}></span>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>}
                {isError ? <Alert
                    className='member_address mt-3'
                    variant="danger"
                    id='main_alert'
                    onClose={() => setIsError(false)}
                    dismissible
                  >
                    <p>{errorMessage}</p>
                  </Alert> : null}
                <div className='report_abuse_btn'>
                    <div>
                        <input
                            type="file"
                            style={{ display: 'none' }}
                            onChange={uploadFiles}
                            id="fileInput"
                            multiple
                        />
                        <Button
                            onClick={() => document.getElementById('fileInput').click()}
                            variant="primary"
                            disabled={isResponse}
                        >
                            {t('suggestions.attachment')}
                        </Button>
                    </div>
                    <div>
                        <Button
                            onClick={sendMessage}
                            className='save_report'
                            variant="success"
                            disabled={isResponse}>
                            {t('mymembership.msgtoGk.send')}
                        </Button>
                        <Button variant="outline-danger" onClick={close}>
                            {t('common.close')}
                        </Button>
                    </div>
                </div>                
            </ModalBody>
        </Modal>
    );
}

export default WriteMessageToGleichklang;
