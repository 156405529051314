import './pendingPrePayment.css';
import payment_icons from '../../assets/images/payrexx_icons.png';
import React, { useEffect, useState, useRef } from 'react'
import Button from 'react-bootstrap/Button';
import GlkHeader from '../../components/Header/GlkHeader';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next'
import { useContext } from 'react';
import { LoginContext } from '../../context/loginContext';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import {
    faPhoneVolume,
    faSquarePhone,
    faEnvelope, 
		faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { getPaymentDetails, changePaymentMethod } from '../registrationProcess/registrationProcessService';
import Payment from '../registrationProcess/payment';

function PendingPrePayment(props) {
    const { t, i18n } = useTranslation();
    let {
        loginData: {
            accessToken
        },
    } = useContext(LoginContext);
    let navigate = useNavigate();
    const [paymentMethod, setPaymentMethod] = useState(null);
    const [prepaymentData, setPrepaymentData] = useState(null);
    const [isChangePaymentMethod, setChangePaymentMethod] = useState(false)
    const [showPaymentPage, setShowPaymentPage] = useState(false)
    const currentLang = i18n.language;
    const scrollRef = useRef(null);
    const token_lang = {
        language: currentLang,
        accessToken
    }

    useEffect(() => {
        getPaymentDetails(token_lang).then((result) => {
            if (result?.status === 200) {
                setPrepaymentData(result?.data)
            }
        })
    }, [])


    const onNext = () => {
        navigate('/home');
        return;
    }

    const changeMethod = () => {
        if(paymentMethod && paymentMethod === 'DIRECT_DEBIT') {
            changePaymentMethod(token_lang).then((result) => {
                if (result?.status === 200) {
                    setShowPaymentPage(true)
                }
            })
        } else {
            scrollRef.current?.scrollIntoView({
                behavior: 'smooth'
            });
        }
    }

    return (
        <>
            <GlkHeader showMenuBar={false} />
            {showPaymentPage === false ? 
            <div className="main_wrapper pending_payment_wrapper">
							
							<div className="left_area">								
				
								<div className="home_banner info_banner">
									<div className="home_banner_content info_banner_content">
										<h1><FontAwesomeIcon icon={faExclamationTriangle} /> {t('registration.pendingPayment.mainHeading')}</h1>
										<h2>{t('registration.pendingPayment.header')}</h2>
										<p><Trans i18nKey="registration.pendingPayment.description"/></p>										
									</div>
								</div>					
				
								<div className="home_banner contact_banner">
									<div className="home_banner_content contact_banner_content">
										<h1>{t('registration.pendingPayment.contactTeamHeader')}</h1>
										<h2>{t('registration.pendingPayment.contactTeam')}</h2>
										
										<div className="contact_details_container">
												<div className="contact_number_free">
														<div className='contact_numberAndicon'>
																<div className='for_icon_circle'>
																		<FontAwesomeIcon icon={faPhoneVolume} />
																</div>
																<div>
																	<a className="text_white" href="tel: +49 (0)511 51516660" aria-label="call on 49 (0)511 51516660">+49 (0)511 51516660</a>
																	<span className="for_font_size">{t('member.footer.contactTime')}</span>
																</div>
														</div>
												</div>
												<div className="contact_number_paid">
														<div className='contact_numberAndicon'>
																<div className='for_icon_circle'>
																		<FontAwesomeIcon icon={faSquarePhone} />
																</div>
																<div>
																	<a className="text_white" href="tel: +49 (0) 1805 - 7772 3332" aria-label="Call on +49 (0) 1805 - 7772 3332"> +49 (0) 1805 - 7772 3332 </a>
																	<span className="for_font_size">(0.14 €/min)</span>
																</div>
														</div>
												</div>
												<div className="contact_email">
														<div className='contact_numberAndicon'>
																<div className='for_icon_circle'>
																		<FontAwesomeIcon icon={faEnvelope} />
																</div>
																<a className="text_white" href="mailto:anfragen@gleichklang.de" aria-label="mail to anfragen@gleichklang.de">anfragen@gleichklang.de</a>
														</div>
												</div>
										</div>	
									
									</div>
								</div>
					
							</div>
							
							<div className='from_control questionair_container'>
							
									<div className='questionair_header' ref={scrollRef}>
											<h3 className='questionair_heading'>{t('registration.pendingPayment.paymentDetails')}</h3>
											<div className="payment_methods">
													<div className='prepayment_result'>
															<p><Trans i18nKey="registration.prepayment.ptta" values={{amount:prepaymentData?.amount}}>
															{t('registration.prepayment.ptta')}
															</Trans></p>
													</div>
													<div className="prepayment_details">
															<h2>{t('registration.prepayment.bankTransfer')}</h2>
															<div className="bank_name">
																	<p>GLS Bank </p>
															</div>
															<div className="bank_info">
																	<p>
																			<span><b>{t('registration.prepayment.accountOwner')}</b></span>
																			<span>Gleichklang Limited</span>
																	</p>
																	<p>
																			<span><b>IBAN:</b></span>
																			<span>{prepaymentData?.prePaymentBankAccounts[0].iban}</span>
																	</p>
																	<p>
																			<span><b>BIC:</b></span>
																			<span>{prepaymentData?.prePaymentBankAccounts[0].bic}</span>
																	</p>
																	<p>
																			<span><b>{t('registration.prepayment.amount')}</b></span>
																			<span>{prepaymentData?.prePaymentBankAccounts[0].bic}</span>
																	</p>
																	<p>
																			<span><b>{t('registration.prepayment.paymentReference')}</b></span>
																			<span>{prepaymentData?.externalReferenceId}</span>
																	</p>
															</div>
													</div>
													<div className="onlinepayment_details">
															<h2>{t('registration.prepayment.payOnline')}</h2>
															<div className="bank_name">
																	<p>{t('registration.prepayment.secureFast')}</p>
															</div>
															<p>{t('registration.prepayment.onlinepaymentDescription')}</p>
															<a className="btn btn-primary" href={"https://gleichklang.payrexx.com/pay?tid=f4ab5d56&invoice_amount="+prepaymentData?.amount+"&custom_referenz="+prepaymentData?.externalReferenceId} target="_blank">{t('registration.prepayment.payNow')}</a>
															<img src={payment_icons} alt={t('registration.prepayment.payOnline')} />
													</div>
											</div>
									</div>
									<div className='questionair_header'>
											<h3 className='questionair_heading'>{t('registration.pendingPayment.changeMethod')}</h3>
											{isChangePaymentMethod === false ?
													<Button
															className='change_button'
															variant="primary"
															onClick={() => setChangePaymentMethod(true)}
													>
															{t('registration.buttons.changePaymentMethod')}
													</Button> :
													<div>
															<Form.Group as={Col} controlId="formGridEmail" className='mt-3'>
																	<Form.Label>{t('registration.subscription.paymentmethod')}</Form.Label>
																	<Form.Select onChange={(e) => setPaymentMethod(e.target.value)} >
																			{/* <option value=''>{t('registration.buttons.pleaseselect')}</option> */}
																			<option value='PREPAYMENT'>{t('registration.pendingPayment.prepayment')}</option>
																			<option value='DIRECT_DEBIT'>{t('registration.pendingPayment.directDebit')}</option>
																	</Form.Select>
															</Form.Group>

															<div className="button_area">
																	<Button className='save_button'
																			variant="primary"
																			onClick={() => {
																					setChangePaymentMethod(false)
																					changeMethod();
																			}}
																	>{t('registration.buttons.save')}</Button>

																	<Button className='cancel_button'
																			variant="primary"
																			onClick={() => setChangePaymentMethod(false)}
																	>{t('registration.buttons.cancel')}</Button>
															</div>
													</div>}
									</div>
									{isChangePaymentMethod === false ?
											<div className="mt-3 step_one_next">
													<Button
															className='main_button'
															variant="primary"
															onClick={() => onNext()}
													>
															{t('registration.buttons.next')}
													</Button>
											</div> : null}
							</div>
						
            </div>
            :<Payment isUserRegistered={true} />}
        </>
    )
}
export default PendingPrePayment;
