import React, { useState, useEffect, forwardRef } from 'react';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import step2 from "../../assets/images/step_2.png";
import promise from "../../assets/images/promise.png";
import AGBdataprotection from './AGBdataprotection';
import { getSexQuestion } from './preRegistrationService'
import { useTranslation } from 'react-i18next';
import AlertModal from '../commonComponents/alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import "./aboutyou.css";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import { Alert, Row } from 'react-bootstrap';
import { autoFocusByID, autoFocusFirstElement, numberOnlyWithDigit } from '../commonMethods';

function Aboutyou(props) {
    const [isgotoNext, setIsgotoNext] = useState(true)
    const [data, setData] = useState({ ...props.data })
    const [sexQuestion, setSexQuestion] = useState(null)
    const [showAlert, setShowAlert] = useState(false)
    const [showDateOfBirthAlert, setShowDateOfBirthAlert] = useState("")
    const [dateOfBirthError, setDateOfBirthError] = useState(false)
    const [showSexAlert, setShowSexAlert] = useState("")
    const [sexAlert, setSexAlert] = useState(false)
    const [categoryErrorMeg, setCategoryErrorMeg] = useState("")
    const [isCategoryError, setIsCategoryError] = useState(false)
    const [monthOfBirth, setMonthOfBirth] = useState('')
    const [dayOfBirth, setDayOfBirth] = useState('')
    const [yearOfBirth, setYearOfBirth] = useState('')
    const [monthAlert, setMonthAlert] = useState(false)
    const [dayAlert, setDayAlert] = useState(false)
    const [yearAlert, setYearAlert] = useState(false)

    const { t, i18n } = useTranslation();
    const currentLang = i18n.language;

    useEffect(() => {
        setData(props && props.data)
    }, [])

    useEffect(() => {
        const finalData = {
            language: currentLang
        }
        getSexQuestion(finalData).then((res) => {
            if (res?.status === 200 && res.data) {
                setSexQuestion(res?.data)
            }
        });
    }, [currentLang])

    useEffect(() => {
        if(monthOfBirth && dayOfBirth && yearOfBirth) {
            let newDayOfBirth = dayOfBirth;
            let newMonthOfBirth = monthOfBirth;
            if(dayOfBirth.length === 1) newDayOfBirth = '0'+dayOfBirth;
            if(monthOfBirth.length === 1) newMonthOfBirth = '0'+newMonthOfBirth;

            var newRsDate = yearOfBirth+"-"+newMonthOfBirth+"-"+newDayOfBirth;
            data[`birthDate`] = new Date(newRsDate)
            setData({ ...data })
        }
    }, [monthOfBirth, dayOfBirth, yearOfBirth])

    const handleGenderError = (evt) => {
        let message = t('member.preregistration.errors.aboutYou')
        if (evt.target.value === '' || evt.target.value === undefined) {
            let dobMsg = t('member.preregistration.errors.sex')
            setShowSexAlert(message + (dobMsg ? " " + dobMsg : ""))
            setSexAlert(true);
            return true;
        } else {
            setShowSexAlert('')
            setSexAlert(false);
            return false;
        }
    }

    const validateWithTAB = (evt) => {
        let isError = null;
        if(evt && evt.type === 'keydown' && evt.keyCode === 9) {
            if(evt.target.id === 'formGridSexAnswer') isError = handleGenderError(evt)
            if(evt.target.id === 'month') isError = handleMonth(evt)
            if(evt.target.id === 'day') isError = handleDay(evt)
            if(evt.target.id === 'year') isError = handleYear(evt)
            if(evt.target.value === '' || isError) evt.preventDefault();
        }
    }

    const isLeapYear = (year) => {
        return (year % 4 === 0 && year % 100 !== 0) || (year % 400 === 0);
      };
      
      const getDaysInMonth = (month, year) => {
        const daysInMonths = [31, isLeapYear(year) ? 29 : 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        return daysInMonths[month - 1];
      };
      
      const isValidateBirthDate = (evt) => {
        if (!data[`birthDate`]) {
          if (!monthOfBirth) setMonthAlert(true);
          if (!dayOfBirth) setDayAlert(true);
          if (!yearOfBirth) setYearAlert(true);
          return false;
        }
      
        const daysInMonth = getDaysInMonth(monthOfBirth, yearOfBirth);
        if (dayOfBirth > daysInMonth) {
          setDayAlert(true);
          let categoryMsg = '';
          if (monthOfBirth == 2 && dayOfBirth == 29) {
            categoryMsg = isLeapYear(yearOfBirth)
              ? t(`member.preregistration.errors.max${29}`)
              : t(`member.preregistration.errors.max${28}`);
          } else {
            categoryMsg = t(`member.preregistration.errors.max${daysInMonth}`);
          }
          setShowDateOfBirthAlert(categoryMsg);
          setDateOfBirthError(true);
          return false;
        }
      
        setDayAlert(false);
        setDateOfBirthError(false);
      
        const years = calculateAge(data[`birthDate`]);
        setShowDateOfBirthAlert('');
      
        if (years) {
          if (years < 18 || years >= 120) {
            let dobMsgAllow = t('member.preregistration.labels.dateOfBirthOver18');
            setShowDateOfBirthAlert((dobMsgAllow ? " " + dobMsgAllow : ""));
            setDateOfBirthError(true);
            setShowAlert(true);
            autoFocusByID('birthDate');
            return false;
          } else {
            setShowAlert(false);
            setDateOfBirthError(false);
            return true;
          }
        }
      };
      

    const validateCategory = (evt) => {
        let message = t('member.preregistration.errors.aboutYou')
        if ((data[`categories`] && data[`categories`].length === 0) || data[`categories`] == undefined) {
            let categoryMsg = t('member.preregistration.errors.categories')
            setCategoryErrorMeg(message + (categoryMsg ? " " + categoryMsg : ""))
            setIsCategoryError(true);
            autoFocusByID("catFRIENDSHIP");
        } else {
            setCategoryErrorMeg("");
            setIsCategoryError(false);
        }
    }

    const onGoToNext = () => {
        handleGenderError({ target: { value: data[`sexAnswer`], id: 'formGridSexAnswer' } })
        validateCategory({ target: { value: data[`categories`] } })
        if (!isValidateBirthDate(data[`birthDate`])) {
            autoFocusFirstElement();
            return;
        }
        setDateOfBirthError(false)
        if ((data[`categories`] &&
            (data[`categories`].indexOf("PARTNERSHIP") >= 0 || data[`categories`].indexOf("FRIENDSHIP") >= 0))
            && data[`sexAnswer`] && data[`birthDate`]) {
            setIsgotoNext(false)
        } else {
            setShowAlert(true)
            autoFocusFirstElement();
        }
    }

    const calculateAge = (birthDate) => {
        const today = new Date();
        let age = today?.getFullYear() - birthDate?.getFullYear();
        const monthDifference = today?.getMonth() - birthDate?.getMonth();
        const dayDifference = today?.getDate() - birthDate?.getDate();    
        if (monthDifference < 0 || (monthDifference === 0 && dayDifference < 0)) {
            age--;
        }    
        return age;
    };

    const validateWithTABCheckbox = (evt) => {
        if (evt.type === 'keydown' && evt.keyCode === 9 && evt.target.id === 'catPARTNERSHIP') {
            validateCategory({ target: { value: data[`categories`] } })
        }
    }

    const handleMonth = (e) => {
        if(e.target.value > 12 || e.target.value == 0) {
            setMonthAlert(true);
            return true;
        } else {
            setMonthAlert(false);
            return false;
        }
    }

    const handleDay = (e) => {
        if(e.target.value > 31 || e.target.value == 0) {
            setDayAlert(true);
            return true;
        } else {
            setDayAlert(false);
            return false;
        }
    }
    
    const handleYear = (e) => {
        setYearOfBirth(e.target.value);
        setShowDateOfBirthAlert('')
        setDateOfBirthError(false)    
        if(e.target.value && (e.target.value > (new Date()?.getFullYear() - 18) || (new Date()?.getFullYear() - e.target.value) > 120)) {
            setYearAlert(true);
            if((new Date()?.getFullYear() - e.target.value) < 18 || (new Date()?.getFullYear() - e.target.value) > 120) {
                setShowDateOfBirthAlert(t('member.preregistration.labels.dateOfBirthOver18'))
            }
            return true;
        } else {
            if(e.target.value) {
                setYearAlert(false);
                return false;
            } else {
                setYearAlert(true);
                return true;
            }
        }
    }

    const componentMonthOfBirthDay = <>
        <Form.Group className="mt-3 birthdate" as={Col}>
            <Form.Label htmlFor="month">{t('member.preregistration.month')} <small>{currentLang === 'de' ? "(z.B. 1,11)" : "(e.g. 1,11)"}</small> *</Form.Label>
            <Form.Control 
                autoFocus={currentLang === 'de' ? false : true}
                autoComplete='off'
                type="text"
                id="month"
                value={monthOfBirth}
                className={monthAlert && "is-invalid"}
                aria-label={monthAlert && monthOfBirth ? t('member.preregistration.labels.invalidMonthOfBrith') : t('member.preregistration.labels.monthOfBrith')}
                onChange={(e) => setMonthOfBirth(e.target.value)}
                onBlur={(e) => handleMonth(e)}
                onKeyUp={(e) => numberOnlyWithDigit(e, 2)}
                onKeyDown={(e) => {
                    validateWithTAB(e);
                }}
            />
        </Form.Group>
    </>;

    const componentDayOfBirthDay = <>
        <Form.Group className="mt-3 birthdate" as={Col}>
            <Form.Label htmlFor="day">{t('member.preregistration.day')} <small>{currentLang === 'de' ? "(z.B. 2,20)" : "(e.g. 2,20)"}</small> *</Form.Label>
            <Form.Control 
                autoFocus={currentLang === 'de' ? true : false}
                autoComplete='off'
                type="text"
                id="day"
                value={dayOfBirth}
                className={dayAlert && "is-invalid"}
                aria-label={dayAlert && dayOfBirth ? t('member.preregistration.labels.invalidDayOfBrith') : t('member.preregistration.labels.dayOfBrith')}
                onChange={(e) => setDayOfBirth(e.target.value)}
                onBlur={(e) => handleDay(e)}
                onKeyUp={(e) => numberOnlyWithDigit(e, 2)}
                onKeyDown={(e) => {
                    validateWithTAB(e)
                }}
            />
        </Form.Group>
    </>;

    return (
        <>
            {isgotoNext ?
                <div className="form_row">
                    <div className="pre-register-new">
                        <div className="col-12">
                        <img src={promise} alt={t('member.community.imageFor')+ " "+t('member.preregistration.promiseHeading')} />
                        <h3>{t('member.preregistration.promiseHeading')}</h3>
                        </div>
                        <div className="promiseInfo">
                        <div className="col-12">
                        <p><strong>{t('member.preregistration.promiseSubheading')}</strong></p>
                            <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.registerSubText')}</span></p>
                        </div>
                        <div className="col-12">
                            <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.profileSubText')}</span></p>
                        </div>
                        <div className="col-12">
                            <p><FontAwesomeIcon icon={faCheck} /> <span>{t('member.preregistration.finalizeSubText')}</span></p>
                        </div>
                        </div>
                    </div>
                    <div className='membership_account'>
                        <div className="reg_stepone">
                            <h3 className="lineH3 text-center"><strong>{t('member.registration.registration')}</strong> {t('member.preregistration.step2of3')}</h3>
                            <h4>{t('member.preregistration.profileHeading')}</h4>
                        </div>
                        <Form className='step_one_form'> 
                            <p className='lable birthdateLabel'>{t('member.preregistration.dob')}*</p>
                            <Row className="mb-3 birthdate">
                                {currentLang === 'de' ? 
                                    <>
                                        {componentDayOfBirthDay}
                                        {componentMonthOfBirthDay}
                                    </>
                                : 
                                    <>
                                        {componentMonthOfBirthDay}
                                        {componentDayOfBirthDay}
                                    </>
                                }
                                <Form.Group className="mt-3 birthdate" as={Col}>
                                    <Form.Label htmlFor="year">{t('member.preregistration.year')} <small>{currentLang === 'de' ? "(z.B. 2001)" : "(e.g. 2001)"}</small> *</Form.Label>
                                    <Form.Control 
                                        autoComplete='off'
                                        type="text"
                                        id="year"
                                        className={yearAlert && "is-invalid"}
                                        aria-label={yearAlert && yearOfBirth ? t('member.preregistration.labels.invalidYearOfBrith') : t('member.preregistration.labels.yearOfBrith')}
                                        onBlur={(e) => handleYear(e)}
                                        onKeyUp={(e) => numberOnlyWithDigit(e, 4)}
                                        onKeyDown={(e) => {
                                            validateWithTAB(e)
                                        }}
                                    />
                                </Form.Group>
                            </Row>
                            {(monthAlert) ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {monthAlert && <>{monthOfBirth ? t('member.preregistration.labels.invalidMonthOfBrith') : t('member.preregistration.labels.monthOfBrith')} <br /></>}
                                    </p>
                                </Alert>
                            : null}
                            {(dayAlert) ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {dayAlert && <>{dayOfBirth ? t('member.preregistration.labels.invalidDayOfBrith') : t('member.preregistration.labels.dayOfBrith')} <br /></>}
                                    </p>
                                </Alert>
                            : null}
                            {(yearAlert) ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {yearAlert && <>{yearOfBirth ? t('member.preregistration.labels.invalidYearOfBrith') : t('member.preregistration.labels.yearOfBrith')} <br /></>}
                                    </p>
                                </Alert>
                            : null}
                            {dateOfBirthError ?
                                <Alert className='member_address' variant="danger">
                                    <p>
                                        {showDateOfBirthAlert}
                                    </p>
                                </Alert> 
                            : null}

                            <Form.Group className="mt-3 matching_categories" as={Col} controlId="formGridSexAnswer">
                                
                                <Form.Label>{t('member.preregistration.yourSex')}*</Form.Label>
                                <Form.Select 
                                    className={sexAlert && "is-invalid"} 
                                    defaultValue={null} 
                                    onChange={(e) => {
                                        data[`sexAnswer`] = e.target.value;
                                        setData({ ...data })
                                        handleGenderError(e)
                                    }}
                                    onKeyDown={(e) => {                                    
                                        validateWithTAB(e);
                                    }}
                                    aria-label={(showAlert && !data[`sexAnswer`]) ? t('member.preregistration.errors.sex'): t('member.preregistration.labels.gender')}
                                >
                                    <option value="" aria-label={t('member.preregistration.labels.gender')}>{t('member.preregistration.pleaseSelectOption')}</option>
                                    {sexQuestion?.choices.map((choice, index) => (
                                        <option key={choice.choiceId} id={`sexOption-${index}`} value={choice.choiceId}>{choice.value}</option>
                                    ))}
                                </Form.Select>
                                <AlertModal className={'mt-3'} show={sexAlert} setShow={() => setShowAlert(false)} heading={'Error'} body={showSexAlert}></AlertModal>
                            </Form.Group>
                            
                            <p className='lable mt-3 matching_categories'>{t('member.preregistration.categories')}*</p>
                            <div className='active_categories'>
                                <Form.Group className="my-1 category_f" id="formGridCheckbox">
                                    <Form.Check 
                                        id='catFRIENDSHIP' 
                                        className={isCategoryError ? 'is-invalid msg_pre_checkbox' : 'msg_pre_checkbox' }
                                        type="checkbox" 
                                        label={t('member.preregistration.categoryFriendship')}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                data[`categories`] = data[`categories`] ? [...data[`categories`], "FRIENDSHIP"] : ["FRIENDSHIP"];
                                                setIsCategoryError(false);
                                            } else if (data[`categories`] && data[`categories`].indexOf("FRIENDSHIP") >= 0) {
                                                data[`categories`].splice(data[`categories`].indexOf("FRIENDSHIP"), 1);
                                            }
                                            setData({ ...data })
                                        }}
                                        onKeyDown={(e) => {
                                            validateWithTABCheckbox(e);
                                        }}
                                        aria-label={(showAlert && data[`categories`] && (data[`categories`].indexOf("PARTNERSHIP") < 0 && data[`categories`].indexOf("FRIENDSHIP") < 0)) ? t('member.preregistration.errors.categories'): t('member.preregistration.labels.friendship')}
                                    />
                                </Form.Group>
                                <Form.Group className="my-1 category_f" id="formGridCheckbox">
                                    <Form.Check 
                                        id='catPARTNERSHIP' 
                                        className={isCategoryError ? 'is-invalid msg_pre_checkbox' : 'msg_pre_checkbox' }
                                        type="checkbox" 
                                        label={t('member.preregistration.categoryPartnership')}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                data[`categories`] = data[`categories`] ? [...data[`categories`], "PARTNERSHIP"] : ["PARTNERSHIP"];
                                                setIsCategoryError(false);
                                            } else if (data[`categories`] && data[`categories`].indexOf("PARTNERSHIP") >= 0) {
                                                data[`categories`].splice(data[`categories`].indexOf("PARTNERSHIP"), 1);
                                            }
                                            setData({ ...data })
                                        }}
                                        onKeyDown={(e) => {
                                            validateWithTABCheckbox(e);
                                        }}
                                        aria-label={(showAlert && data[`categories`] && (data[`categories`].indexOf("PARTNERSHIP") < 0 && data[`categories`].indexOf("FRIENDSHIP") < 0)) ? t('member.preregistration.errors.categories'): t('member.preregistration.labels.partnership')}
                                    />
                                </Form.Group>
                            </div>
                            <AlertModal className={'mt-2'} show={isCategoryError} setShow={setShowAlert} heading={'Error'} body={categoryErrorMeg}></AlertModal>

                            <Form.Label className="promo_label" htmlFor="promotionCode">{t('member.preregistration.promo')}</Form.Label>
                            <p className="mt-3 promo">{t('member.preregistration.promoCodeDesc')}</p>
                            <Form.Control
                                type="input"
                                id="promotionCode"
                                onChange={(e) => {
                                    data[`actionCode`] = e.target.value
                                    setData({ ...data })
                                }}
                                aria-label={t('member.preregistration.labels.promotionCode')}
                            />
                            {/* <AlertModal show={showAlert} setShow={setShowAlert}
                                heading={'Error'} body={alertBody}>
                            </AlertModal> */}
                            <div className="mt-3 step_one_next">
                                <Button className='mx-5' variant="primary" onClick={() => onGoToNext()}>
                                    {t('member.preregistration.nextButton')}
                                </Button>
                            </div>
                        </Form>
                    </div>
                </div>
                :
                <AGBdataprotection data={data} />
            }
        </>
    )
}

export default Aboutyou