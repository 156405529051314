import './login.css';
import LoginDescription from './loginDescription';
import LoginBox from './loginBox';
import GlkHeader from '../../components/Header/GlkHeader';
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function Login() {
	const location = useLocation();

    const [registrationClass, setRegistrationClass] = useState('');
    useEffect(() => {
        if (location.state !== null) {            
            setRegistrationClass("registered");
        } else {
            setRegistrationClass("");
        }
    }, [])  
		
		const css = `
    .app_footer {
        display: none;
    }
		`

    return (
        <div className={"login_container "+registrationClass}>
            <GlkHeader/>
						<div className="main_wrapper">
							<LoginDescription />
							<LoginBox/>
						</div>
						<div className="login_bg" id="login1"></div>
						<div className="login_bg" id="login2"></div>
						<div className="login_bg" id="login3"></div>
						<style>{css}</style>
        </div>
    )
}
