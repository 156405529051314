import React, { useContext, useState } from 'react';
import GlkHeader from '../../components/Header/GlkHeader';
import './personalData.css';
import './subscription.css';
import membershipIcon from '../../assets/images/membership.png';
import { useTranslation } from 'react-i18next';
import Banner from '../../components/banner/banner';
import { Accordion, Alert, Form, Button, Col, Spinner } from 'react-bootstrap';
import { getServiceOffer, getUpgradeOffer } from './membershipServices';
import { LoginContext } from '../../context/loginContext';

function Subscription() {
  const [serviceOffer, setServiceOffer] = useState(null);
  const [upgradeOffers, setUpgradeOffers] = useState(null);
  const [isAccordionOpen, setIsAccordionOpen] = useState({upgrade: false, serviceOffer: false});
  const [upgradeOffersErrors, setUpgradeOffersError] = useState({error: [], message: ''});
  const [serviceOfferErrors, setServiceOfferError] = useState({error: [], message: ''});
  const [isError, setIsError] = useState(false);
  const [loader, setloader] = useState(false);

  const { t,i18n } = useTranslation();

  let {
    loginData: {
      accessToken
    },
  } = useContext(LoginContext);

  const data = {
    image: membershipIcon,
    menuName: t('mymembership.mymembership'),
    header: t('mymembership.subscription.subscription'),
    description: t('mymembership.subscription.description'),
    color: '#063978'
  };

  const ViewUpgrades = () => {
    setloader(true);
    const fetchUpgrade = async () => {
      const offersPayload = {
        accessToken: accessToken,
        language: i18n.language
      };
      try {
        const upgradeResponse = await getUpgradeOffer(offersPayload);
        setloader(false);
        if (upgradeResponse.status === 200) {
          setUpgradeOffers(upgradeResponse.data);
        } else if (upgradeResponse.status === 400) {
          setIsError(true);
          setUpgradeOffers(null);
          setUpgradeOffersError({ error: upgradeResponse?.data?.error, message: upgradeResponse?.data?.message })
        } else {
          setIsError(true);
          setUpgradeOffers(null);
          setUpgradeOffersError({ error: '', message: t('mymembership.subscription.no_upgrade_offer') })
        }
      } catch (error) {
        console.error('Error fetching upgrade offer:', error);
        setUpgradeOffers(null);
      }
    };
    fetchUpgrade();
  }

  const viewServiceOffer = () => {
    setloader(true);
    const fetchServiceOffers = async () => {
      const offersPayload = {
        accessToken: accessToken,
        language: i18n.language
      };
      try {
        const serviceResponse = await getServiceOffer(offersPayload);
        setloader(false);
        if (serviceResponse.status === 200) {
          setServiceOffer(serviceResponse.data);
        } else if (serviceResponse.status === 400) {
          setIsError(true);
          setServiceOffer(null);
          setServiceOfferError({ error: serviceResponse?.data?.error, message: serviceResponse?.data?.message })
        } else {
          setIsError(true);
          setServiceOffer(null);
          setServiceOfferError({ error: '', message: t('mymembership.subscription.no_service_offer') })
        }
      } catch (error) {
        console.error('Error fetching service offer:', error);
        setServiceOffer(null);
      }
    };
    fetchServiceOffers();
  }

  const toggleAccordianView = (isOpen) => {

    if (upgradeOffers == null && isOpen.upgrade) {
      ViewUpgrades();
    }else{
      setIsError(false);
    }
    
    if (serviceOffer == null && isOpen.serviceOffer) {
      viewServiceOffer();
    }
    else{
      setIsError(false);
    }
    setIsAccordionOpen(isOpen);
  };
  
  
  return (
    <div>
    <GlkHeader/>
    <main className="main_wrapper membership_wrapper">
      <Banner data={data} />
      <div className='questionaire_in_menubar'>
        <div className="membership_container">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.subscription')}</Accordion.Header>
              <Accordion.Body>
                <Form>
                  <div className="form_head_part">
                    <h4>{t('mymembership.subscription.serviceoffer')}</h4>
                    <span></span>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.state')}</p>
                    <p>{t('mymembership.subscription.active')}</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.membershipName')}</p>
                    <p>201811-NORMAL-FE-12</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.start')}</p>
                    <p>2023-10-11</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.state')}</p>
                    <p>{t('mymembership.subscription.active')}</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.end')}</p>
                    <p>2024-10-11</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.extended')}</p>
                  </div>
                  <div className="sub_desc">
                    <p>{t('mymembership.subscription.paymentMethod')}</p>
                  </div>
                  <div className="active_categories">
                    <Form.Group className="category_f" id="formGridCheckbox">
                      <Form.Check name="payment" className="msg_pre_checkbox" type="radio" label={t('mymembership.subscription.directDebit')} />
                    </Form.Group>
                    <Form.Group className="category_f" id="formGridCheckbox">
                      <Form.Check name="payment" className="msg_pre_checkbox" type="radio" label={t('mymembership.subscription.prepayment')} />
                    </Form.Group>
                  </div>
                  <div className="subscription_btn">
                    <Button className="mx-5" as={Col} variant="primary" type="submit">
                      {t('mymembership.subscription.save')}
                    </Button>
                    <Button className="mx-5" as={Col} variant="primary" type="submit">
                      {t('mymembership.subscription.changeBank')}
                    </Button>
                  </div>
                </Form>
              </Accordion.Body>
            </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header
                  onClick={() => toggleAccordianView({ upgrade: !isAccordionOpen.upgrade, serviceOffer: false })}
                  className="accordian_header">{t('mymembership.subscription.upgrade')}</Accordion.Header>
                <Accordion.Body>
                  <div className="upgrade-offers">
                    {!loader ? <div className="products-list">
                      {upgradeOffers?.products?.map((product) => (
                        <div key={product.id} className="product">
                          <Form.Group className="offer_btn">
                            <Form.Check
                              className='msg_pre_checkbox'
                              type="radio"
                              name="offer"
                            />
                          </Form.Group>
                          <span
                            dangerouslySetInnerHTML={{ __html: product.value }}
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      ))}
                    </div> : <div className='subscription_loader'> <Spinner /></div>}
                    {!loader && isError ? <Alert
                      className='member_address'
                      variant="danger"
                      id='main_alert'
                      dismissible
                      onClose={() => setIsError(false)}
                    >
                      <p>{upgradeOffersErrors.message}</p>
                      {upgradeOffersErrors.error?.length ? upgradeOffersErrors.error?.map((error) => (<p>{error}</p>)) : null}
                    </Alert> : null}
                    {upgradeOffers && <div className="changePass_btn">
                      <Button style={{ marginTop: '0px' }} className="mx-5" as={Col} variant="primary" type="submit">
                        Purchase
                      </Button>
                    </div>}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header
                  onClick={() => toggleAccordianView({ upgrade: false, serviceOffer: !isAccordionOpen.serviceOffer })}
                  className="accordian_header">{t('mymembership.subscription.serviceoffer')}</Accordion.Header>
                <Accordion.Body>
                  <div className="upgrade-offers">
                    {!loader ? <div className="products-list">
                      {serviceOffer?.products?.map((product) => (
                        <div key={product.id} className="product">
                          <Form.Group className="offer_btn">
                            <Form.Check
                              className='msg_pre_checkbox'
                              type="radio"
                              name="offer"
                            />
                          </Form.Group>
                          <span
                            dangerouslySetInnerHTML={{ __html: product.value }}
                            style={{ marginLeft: "10px" }}
                          />
                        </div>
                      ))}
                    </div> : <div className='subscription_loader'> <Spinner /></div>}
                    {!loader && isError ? <Alert
                      className='member_address'
                      variant="danger"
                      id='main_alert'
                      dismissible
                      onClose={() => setIsError(false)}
                    >
                      <p>{serviceOfferErrors.message}</p>
                      {serviceOfferErrors.error?.length ? serviceOfferErrors.error?.map((error) => (<p>{error}</p>)) : null}
                    </Alert> : null}
                    <div className="changePass_btn">
                      {serviceOffer && <Button style={{ marginTop: '0px' }} className="mx-5" as={Col} variant="primary" type="submit">
                        Purchase
                      </Button>}
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            <Accordion.Item eventKey="3">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.activeCategories')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.deselectCategory')}</p>
                <p className="m-0"><strong>{t('mymembership.subscription.yourActiveCategories')}</strong></p>
                <div className="active_categories">
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.friendship')} />
                  </Form.Group>
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.partnership')} />
                  </Form.Group>
                </div>
                <p>{t('mymembership.subscription.usingAllOptions')}</p>
                <div className="changePass_btn">
                  <Button style={{ marginTop: '0px' }} className="mx-5" as={Col} variant="primary" type="submit">
                    {t('mymembership.subscription.cancel')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4">
              <Accordion.Header className="accordian_header">{t('mymembership.subscription.suspendService')}</Accordion.Header>
              <Accordion.Body>
                <p className="upgrads">{t('mymembership.subscription.deselectCategory')}</p>
                <div className="">
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.friendship')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control type="password" placeholder={t('mymembership.subscription.suspendUntil')} />
                  </Form.Group>
                  <Form.Group className="my-3 category_f" id="formGridCheckbox">
                    <Form.Check className="msg_pre_checkbox" type="checkbox" label={t('mymembership.subscription.partnership')} />
                  </Form.Group>
                  <Form.Group as={Col} controlId="formGridPassword">
                    <Form.Control type="password" placeholder={t('mymembership.subscription.suspendUntil')} />
                  </Form.Group>
                </div>
                <div className="changePass_btn">
                  <Button className="mx-5 mtop" as={Col} variant="primary" type="submit">
                    {t('mymembership.subscription.save')}
                  </Button>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </div>
      </div>
		</main>
    </div>
  );
}

export default Subscription;
