import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { savePreconfiguration } from './registrationProcessService';
import { LoginContext } from '../../context/loginContext';
import { useTranslation } from "react-i18next";
import Alert from 'react-bootstrap/Alert';
import usePreventBack from './preventBack/preventBack'

function MemberPreconfigNotification(props) {
  const [systemNotificationsEnabled, setSystemNotificationsEnabled] = useState(null);
  const [marketingNotificationsEnabled, setMarketingNotificationsEnabled] = useState(null);
  const [systemNotificationsError, setSystemNotificationsError] = useState(null);
  const [marketingNotificationsError, setMarketingNotificationsError] = useState(null);
  const [notificationsError, setNotificationsError] = useState(null);
  let {
    loginData: {
      accessToken
    },
    dispatch
  } = useContext(LoginContext);
  const { t } = useTranslation();
  usePreventBack();

  const onSavePreconfiguration =() => {
    const data = {
      systemNotificationsEnabled,
      marketingNotificationsEnabled
  }
  if(systemNotificationsEnabled===null){
    setSystemNotificationsError(t('registration.m_notification.notificationsError'))
  }
  else if(marketingNotificationsEnabled===null){
    setMarketingNotificationsError(t('registration.m_notification.notificationsError'))
  }
  else{
    savePreconfiguration(data, accessToken).then((result)=>{
      if(result?.status === 200) {
        dispatch({ type: 'CURRENT_URL', data: result.data });
        props.setQuestionaireId(result.data?.nextQuestionnaire)
      }else{
        setNotificationsError(result.data.message)
      }
    })
  }
  }

  const autofillAnswers = () => {
    systemNotifications(true);
    MarketingNotifications(true);
  };
const systemNotifications=(systemValue)=>{
  setSystemNotificationsEnabled(systemValue)
  if(systemValue !== null){
    setSystemNotificationsError(null)
  }
}
const MarketingNotifications=(marketingValue)=>{
  setMarketingNotificationsEnabled(marketingValue)
  if(marketingValue !== null){
    setMarketingNotificationsError(null)
  }
}
  return (
    <div>
      <div className="looking_for">
        <div className="questionnaireHeader">
          <h3 className="lineH3 text-center"><strong>{t('registration.m_notification.header')}</strong></h3>
          <h4>{t('registration.m_notification.subHeader')}</h4>
          <p>{t('registration.m_notification.description')}</p>
        </div>
      </div>
      {process.env.REACT_APP_ENV != 'PROD' ? <div className="mt-3 mb-3 auto_fill_wrapper">
        <Button
          className='mx-5'
          variant="success"
          onClick={autofillAnswers}
        >
          {t('registration.buttons.autofill')}
        </Button>
        <Button
          className='mx-5'
          variant="primary"
          onClick={() => onSavePreconfiguration()}
        >
          {t('registration.buttons.next')}
        </Button>
      </div> : null}
        <div className='from_control questionair_container radio_section'>
          {notificationsError ? (
            <Alert  className='alert_questionaire' variant='danger'>{notificationsError}</Alert>
          ):null}
          <div className='questionair_header'>
            <h3 className='questionair_heading'>{t('registration.m_notification.heading')}</h3>
          </div>          
          <div className="mt-3 questions_wrapper">
            <p>{t('registration.m_notification.descNotification')}</p>
            <fieldset>
              <legend className="inlineLegend">{t('registration.m_notification.notifications')} <span className='required_asterisks'>*</span></legend>
              <div className='active_categories'>
                <Form.Group className="my-2 category_f" controlId="formGridCheckbox1">
                  <Form.Check 
                  onChange={() => systemNotifications(true)}
                  className='msg_pre_checkbox' 
                  type="radio" 
                  id="formGridCheckbox1" 
                  checked={systemNotificationsEnabled === true}
                  label={t('registration.buttons.yes')} />
                </Form.Group>
                <Form.Group className="my-2 category_f" controlId="formGridCheckbox2">
                  <Form.Check
                  onChange={() => systemNotifications(false)}
                   className='msg_pre_checkbox' 
                   type="radio" 
                   id="formGridCheckbox2" 
                   checked={systemNotificationsEnabled === false}
                   label={t('registration.buttons.no')} />
                </Form.Group>
              </div>
              {systemNotificationsError ? (
                <Alert  className='alert_questionaire' variant='danger'>{systemNotificationsError}</Alert>
              ):null}
            </fieldset>
            <fieldset className="mt10">
            <legend className="inlineLegend">{t('registration.m_notification.marketing')} <span className='required_asterisks'>*</span></legend>
              <div className='active_categories'>
                <Form.Group className="my-2 category_f" controlId="formGridCheckbox3">
                  <Form.Check 
                  onChange={() => MarketingNotifications(true)}
                  className='msg_pre_checkbox' 
                  type="radio" 
                  id="formGridCheckbox3" 
                  checked={marketingNotificationsEnabled === true}
                  label={t('registration.buttons.yes')} />
                </Form.Group>
                <Form.Group className="my-2 category_f" controlId="formGridCheckbox4">
                  <Form.Check 
                  onChange={() => MarketingNotifications(false)}
                  className='msg_pre_checkbox' 
                  type="radio" 
                  id="formGridCheckbox4" 
                  checked={marketingNotificationsEnabled === false}
                  label={t('registration.buttons.no')} 
                  />
                </Form.Group>
              </div>
              {marketingNotificationsError ? (
                <Alert  className='alert_questionaire' variant='danger'>{marketingNotificationsError}</Alert>
              ):null}
            </fieldset>
          </div>
          <div className="mt-3 step_one_next">            
            <Button
              className='mx-5'
              variant="primary"
              onClick={() => onSavePreconfiguration()}
            >
             {t('registration.buttons.next')}
            </Button>
          </div>
        </div>
    </div>
  )
}

export default MemberPreconfigNotification;